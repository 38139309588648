import React, { useContext } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import activeLocation from "../../../assets/images/icons/activeLocation.svg";
import { ThemeContext } from "../../../App";

const containerStyle = {
  width: "100%",
  height: "100%",
};

export default function SensorMapView(props: any) {
  const isLoaded = useContext(ThemeContext);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={14}
      center={{ lat: props?.lat, lng: props?.lng }}
    >
      <>
        {props?.lat !== 0 && props?.lng !== 0 && (
          <MarkerF
            title={"location"}
            position={{ lat: props?.lat, lng: props?.lng }}
            icon={{
              url: activeLocation,
              anchor: new google.maps.Point(17, 46),
              scaledSize: new google.maps.Size(30, 30),
            }}
          ></MarkerF>
        )}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
}
