import React, { createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/globalTheme.css";
import "./assets/css/style.css";
import Navigation from "./routes/Navigation";
import { useLoadScript } from "@react-google-maps/api";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
export const ThemeContext = createContext(null);

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCDZyV7pcOZONcSbgQYA8UTe7AY_Zid2po",
  });

  const queryClient = new QueryClient()

  return (
    <ThemeContext.Provider value={isLoaded}>
      <QueryClientProvider client={queryClient}>
        <div className="screen-width">
          <BrowserRouter>
            <Navigation />
          </BrowserRouter>
        </div>
      </QueryClientProvider>
    </ThemeContext.Provider>
  );
}

export default App;
