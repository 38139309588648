import "./footer.css";

export default function Footer() {
  return (
    <>
      <div className="footerContainer">
        <div>
          <p className="copyright-text">
            &copy; Copyright {new Date().getFullYear()} &#64; Tracer Technology Systems, Inc. All
            Rights Reserved{" "}
          </p>
        </div>
        <div className="flex gap-5 items-center privacy-policy-text">
          <p>Terms and Conditions</p>
          <p>Privacy Policy</p>
        </div>
      </div>
    </>
  );
}
