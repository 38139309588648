import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ResetNewUserManagementErrorMessage,
  ResetNewUserManagementFormError,
  ResetNewUserManagementInfo,
  ResetNewUserManagementSucessMessage,
  ResetUpdatedUserManagement,
  UpdateEditNewUserManagementInfo,
  UpdateNewUserManagementErrorMessage,
  UpdateNewUserManagementSuccessMessage,
  UpdateUserList,
} from "../../redux/reducer/userManagement";
import {
  DeleteUserService,
  GetAllUserListService,
  UpdateUserService,
  userManagementService,
} from "../../service/userManagement";
import component from "../../constants/urls";
import swal from "sweetalert";
import { UserInfoService } from "../../service/Auth";
import { UpdateUserCreatorInfo } from "../../redux/reducer/user";
import handleInvalidToken from "../../service/InvalidTokenHandler";

const useUserManagementServiceHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { newUser } = useSelector((state: any) => state.userManagement);
  const { userInfo } = useSelector((state: any) => state.user);

  const CreateUserServiceHandler = async () => {
    try {
      const {
        name,
        email,
        password,
        role,
        phone,
        sendNotification,
        sendEmail,
        sendSMS,
        assignedDevices,
      } = newUser;
      const res = await userManagementService({
        name,
        email,
        password,
        role,
        phone,
        createdBy: userInfo.id,
        sendNotification,
        sendEmail,
        sendSMS,
        assignedDevices,
      });
      const { result, msg } = res?.data;
      if (msg) {
        dispatch(UpdateNewUserManagementSuccessMessage(msg));
        setTimeout(() => {
          navigate(component.userListing.url);
          dispatch(ResetNewUserManagementSucessMessage());
          dispatch(ResetNewUserManagementErrorMessage());
          dispatch(ResetNewUserManagementInfo());
        }, 1000);
      } else {
        const { name, email, password, role, phone } = result;
        dispatch(ResetNewUserManagementFormError());
        navigate(component.userListing.url);
        dispatch(ResetNewUserManagementInfo());
        dispatch(ResetNewUserManagementErrorMessage());
        dispatch(ResetNewUserManagementSucessMessage());
      }
    } catch (error: any) {
      dispatch(
        UpdateNewUserManagementErrorMessage(
          error?.response?.data?.error?.message
        )
      );
      setTimeout(() => {
        dispatch(ResetNewUserManagementErrorMessage());
      }, 2000);
    }
  };

  const GetUserListServiceHandler = async (id: string) => {
    try {
      const res = await GetAllUserListService(id);
      const { rows } = res.data.result;
      if (rows?.length) {
        dispatch(UpdateUserList([...rows]));
      } else {
        dispatch(UpdateUserList([]));
      }
    } catch (error) {
      handleInvalidToken(error, dispatch);
    }
  };

  const DeleteUserServiceHandler = async (userId: string) => {
    try {
      await DeleteUserService(userId, userInfo?.id);
      await GetUserListServiceHandler(userInfo.id);
    } catch (error: any) {
      handleInvalidToken(error, dispatch);
    }
  };

  const UpdateUserManagementServiceHandler = async () => {
    try {
      const res = await UpdateUserService(newUser);
      const result = res.data      
      if(result) {
        dispatch(
          UpdateNewUserManagementSuccessMessage(result?.msg)
        );
      }
      
     
      setTimeout(() => {
        navigate(component.userListing.url);
        dispatch(ResetNewUserManagementSucessMessage());
        dispatch(ResetNewUserManagementErrorMessage());
        dispatch(ResetNewUserManagementInfo());
        dispatch(ResetUpdatedUserManagement());
      }, 1000);
    } catch (error) {
      dispatch(
        UpdateNewUserManagementErrorMessage(
          error?.response?.data?.error?.message
        )
      );
      setTimeout(() => {
        dispatch(ResetNewUserManagementErrorMessage());
      }, 2000);
    }
  };
  const GetUserInfoByIdServiceHandler = async (id: string) => {
    try {
      const res = await UserInfoService(id);
      const { result } = res.data;
      if (result) {
        const { id, name, email, role, phone, customerId } = result;
        const newUserInfo = {
          id,
          name,
          email,
          role,
          phone,
          customerId,
        };
        dispatch(UpdateUserCreatorInfo({ ...newUserInfo }));
      }
    } catch (error: any) {
      handleInvalidToken(error, dispatch);
    }
  };

  return {
    CreateUserServiceHandler,
    GetUserListServiceHandler,
    DeleteUserServiceHandler,
    UpdateUserManagementServiceHandler,
    GetUserInfoByIdServiceHandler
  };
};

export default useUserManagementServiceHandler;
