import { ResetUserInfo, UpdateIsLoginState } from "../../redux/reducer/user";
import { Dispatch, AnyAction } from "redux";

export function runLogoutTimer(dispatch: Dispatch<AnyAction>, timer: number) {
  setTimeout(() => { 
    alert("Token Expired, Login again !");
    dispatch(UpdateIsLoginState(false));
    dispatch(ResetUserInfo());
  }, timer);
}
