import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import { useDispatch, useSelector } from "react-redux";
import { device } from "../../interface/reducer/device";
import noRecordFound from "../../assets/images/no-record-found.png";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import { UpdateSelectedDevice } from "../../redux/reducer/devices";
import { ShimmerButton } from "react-shimmer-effects";

interface Column {
  id: "Device Name" | "Status";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "Device Name", label: "Device Name" },
  { id: "Status", label: "Status", align: "right" },
];

export default function DeviceTable({ deviceList }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { userInfo } = useSelector((state: any) => state.user);
  const { isShimmer } = useSelector((state: any) => state.common);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeviceSensorList = (item: device) => {
    dispatch(UpdateSelectedDevice(item));
    if (item.purchasedStatus) {
      navigate(component.sensorListing.url);
    } else {
      if (userInfo?.role === "Admin") {
        navigate(component.registerDevice.url);
      } else {
        navigate(component.sensorListing.url);
      }
    }
  };

  return (
    <>
      {deviceList?.length ? (
        <>
          {isShimmer ? (
            <ShimmerButton size="md" />
          ) : (
            <Paper
              sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}
            >
              <TableContainer sx={{ minHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          // align={column.align}
                          style={{
                            backgroundColor: "#EDF4FC",
                            borderBottom: "none",
                          }}
                        >
                          <p className="tableText">{column.label}</p>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deviceList
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row: device, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              align="left"
                              style={{ borderBottom: "none" }}
                              onClick={() => handleDeviceSensorList(row)}
                            >
                              <p className="tableData mb-1">
                                {row?.name ? row?.name : "Unnamed Device"}
                              </p>
                              <p className="tableDataId">{row.id}</p>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ borderBottom: "none" }}
                            >
                              {row.alerting ? (
                                <p className="statusPills alertPills">Alert</p>
                              ) : (
                                <>
                                  {" "}
                                  {row.active ? (
                                    <p className="statusPills activePills">
                                      Active
                                    </p>
                                  ) : (
                                    <p className="statusPills inactivePills">
                                      Inactive
                                    </p>
                                  )}{" "}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                labelRowsPerPage={"Data Per Page"}
                rowsPerPageOptions={[5]}
                component="div"
                count={deviceList?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              minHeight: "440px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="no_record_found_image">
              <img src={noRecordFound} alt="no-record" />
            </div>
          </div>
        </>
      )}
    </>
  );
}
