import axios from "axios";
import { outputLine, sensor } from "../interface/reducer/sensor";
import { getAuthHeader } from "../constants/constant";

export const GetDeviceSensorListService = async (
  deviceId: string,
  page: number
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/device/${deviceId}/sensors?page=${page}`;
  return axios.get(url, getAuthHeader());
};

export const GetDeviceSensorDataByIdService = (
  deviceId: string,
  type: string,
  esn: string
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/device/${deviceId}/sensors/${type}/${esn}`;
  return axios.get(url, getAuthHeader());
};

export const UpdateSensorDetailsSerivce = (
  deviceId: string,
  type: string,
  esn: string,
  data: sensor
) => {
  const url = `${process.env.REACT_APP_URL}v1/device/${deviceId}/sensors/${type}/${esn}`;
  return axios.patch(url, data, getAuthHeader());
};

export const sensorIconListService = () => {
  const url = `${process.env.REACT_APP_URL}v1/sensorIcon`;
  return axios.get(url);
};

export const GetSensorHistoryData = (
  deviceId: string,
  type: string,
  esn: string
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/device/${deviceId}/sensors/${type}/${esn}/timeseries`;
  return axios.get(url, getAuthHeader());
};

export const GetOutputLineService = async (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}device/outputLines/${deviceId}`;
  return axios.patch(url, "", getAuthHeader());
};

export const UpdateOutputLineService = async (
  outputLine: outputLine,
  deviceId: string
) => {
  const url = `${process.env.REACT_APP_URL}device/outputLines/${deviceId}`;
  return axios.patch(url, outputLine, getAuthHeader());
};

export const ExportSensorCSVDataService = (
  sensorName: string,
  deviceId: string
) => {
  const url = `${process.env.REACT_APP_URL}v1/export/file?sensor=${sensorName}&device=${deviceId}`;
  return axios.get(url);
};

export const DeleteSensorService = (
  deviceId: string,
  type: string,
  esn: string
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/device/${deviceId}/sensors/${type}/${esn}`;
  return axios.delete(url, getAuthHeader());
};

export const GetSensorGraphData = (
  deviceId: string,
  type: string,
  esn: string
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/graphData/${deviceId}/sensors/${type}/${esn}`;
  return axios.get(url);
};
export const ClearSensorDataService = (
  deviceId: string,
  type: string,
  esn: string
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/device/${deviceId}/sensors/${type}/${esn}/data`;
  return axios.delete(url, getAuthHeader());
};
export const UpdateSensorStatusStateService = (
  deviceId: string,
  type: string,
  esn: string,
  status: any
) => {
  const url = `${process.env.REACT_APP_URL}v1/updateHide/${deviceId}/sensor/${type}/${esn}`;
  return axios.patch(url, status);
};
