import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CreateCustomerIdService,
  CreateDeviceSubscriptionService,
  GetAllPriceListService,
  GetPaidStatusService,
} from "../../service/Stripe";
import {
  UpdatePaymentErrorMessage,
  UpdatePaymentSuccessMessage,
  UpdatePriceListData,
} from "../../redux/reducer/stripe";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import component from "../../constants/urls";
import swal from "sweetalert";
import handleInvalidToken from "../../service/InvalidTokenHandler";

const useStripeServiceHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((store: any) => store.user);

  const CreateCustomerIdServiceHandler = async (tokenId: string) => {
    try {
      await CreateCustomerIdService({
        email: userInfo?.email,
        source: tokenId,
      });
    } catch (error: any) {
      handleInvalidToken(error, dispatch);
    }
  };

  const GetPriceDataListServiceHandler = async (deviceId: string) => {
    try {
      const res = await GetAllPriceListService(deviceId);
      const { prices } = res?.data?.result?.priceId;
      if (prices?.length) {
        dispatch(UpdatePriceListData([...prices]));
      } else {
        dispatch(UpdatePriceListData([]));
      }
    } catch (error) {
      handleInvalidToken(error, dispatch);
    }
  };

  const CreateDeviceSubscriptionServiceHandler = async ({
    deviceId,
    priceId,
  }: {
    deviceId: string;
    priceId: string;
  }) => {
    try {
      const data = {
        deviceId: deviceId,
        email: userInfo?.email,
        priceId: priceId,
      };
      const res = await CreateDeviceSubscriptionService(data);
      const { result } = res?.data;
      return result;
    } catch (error: any) {
      dispatch(
        UpdatePaymentErrorMessage(error?.response?.data?.error?.message)
      );
      setTimeout(() => {
        dispatch(UpdatePaymentErrorMessage(""));
      }, 1000);
    }
  };

  const UpdatePaymentStatusServiceHandler = async (data: any) => {
    try {
      await GetPaidStatusService(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  return {
    CreateCustomerIdServiceHandler,
    CreateDeviceSubscriptionServiceHandler,
    GetPriceDataListServiceHandler,
    UpdatePaymentStatusServiceHandler,
  };
};

export default useStripeServiceHandler;
