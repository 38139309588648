import React from "react";
import component from "../constants/urls";
import { useNavigate } from "react-router-dom";
import Folder from "../components/firmware/Folder";
import { useSelector } from "react-redux";

export default function PictureFolder() {
  const navigate = useNavigate();
  const { selectedFolder } = useSelector((store: any) => store.firmware);

  const handleRoute = (url: string) => {
    navigate(url);
  };

  return (
    <div className="screenContainerPadding">
      <p className="breadcrum">
        <span onClick={() => handleRoute(component.dashboard.url)}>
          Dashboard
        </span>{" "}
        / <span onClick={() => handleRoute(component.firmwareFolder.url)}>Pictures</span>
      </p>
      <div className="grid grid-cols-1 gap-5">
        <Folder />
      </div>
    </div>
  );
}
