import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import component from "../constants/urls";
import useAuthServiceHandler from "../hooks/serviceHandler/Auth";
import useLoginValidation from "../hooks/validations/Login";
import { UpdateLoginInputs } from "../redux/reducer/auth";
import Label from "../components/common/formInput/Label";
import SuccessMessage from "../components/common/alert/SuccessMessage";
import ErrorMessage from "../components/common/alert/ErrorMessage";
import "../assets/css/auth.css";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import logo from "../assets/images/cabin-tech-logo.svg";
import { UpdateIsLoginState } from "../redux/reducer/user";

export default function LoginPage() {
  const dispatch = useDispatch();
  const { LoginServiceHandler } = useAuthServiceHandler();
  const { OnChangeLoginFormValueValidation, LoginFormValidation } =
    useLoginValidation();

  const { login, loginFormError } = useSelector((store: any) => store.auth);
  const { loginErrorMessage, loginSuccessMessage, isLoader } = useSelector(
    (store: any) => store.common
  );
  const [showPassword, setShowPassword] = React.useState(false);

  const handleChange = (key: "email" | "password", value: string) => {
    dispatch(UpdateLoginInputs({ key, value }));
    OnChangeLoginFormValueValidation({ id: key, value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isValid = LoginFormValidation();
    if (isValid) {
      LoginServiceHandler();
    }
  };


  return (
    <div className="authContainer">
      <div className="logo mb-4">
        <img src={logo} alt="logo" />
      </div>
      <div className="loginContainer">
        <p className="login-heading">Sign in to your account</p>
        {(loginSuccessMessage || loginErrorMessage) && (
          <div className="mb-3">
            {loginSuccessMessage ? (
              <SuccessMessage msg={loginSuccessMessage} />
            ) : loginErrorMessage ? (
              <ErrorMessage msg={loginErrorMessage} />
            ) : (
              ""
            )}
          </div>
        )}
        <form action="" className="w-full flex flex-col gap-5">
          <div className="">
            <Label label="Email" required={true} />
            <input
              className="registerFormInput"
              type="text"
              name="email"
              id="email"
              value={login?.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
            <p className="errorMessage">{loginFormError.email}</p>
          </div>
          <div className="">
            <Label label="Password" required={true} />{" "}
            <div className="relative">
              <input
                className="registerFormInput"
                type={showPassword ? "type" : "password"}
                name="password"
                id="password"
                value={login?.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <div
                className="absolute top-0 right-0 flex justify-center items-center cursor-pointer"
                style={{
                  height: "40px",
                  width: "40px",
                  border: "1px solid #ced4da",
                }}
              >
                {!showPassword ? (
                  <BiSolidHide
                    fontSize={20}
                    color="#495057"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <BiSolidShow
                    fontSize={20}
                    color="#495057"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <p className="errorMessage mb-2">{loginFormError.password}</p>
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="cursor-pointer"
                />
                <label htmlFor="rememberMe" className="rememberMe">
                  Remember Me
                </label>
              </div>
              <p className="forget-password-text">
                <Link to={component.forgetPassword.url}>Forgot Password?</Link>
              </p>
            </div>
          </div>
          <div>
            {isLoader ? (
              <div className="mt-4 submitButton">
                <ClipLoader
                  color={"#ffffff"}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <input
                type="submit"
                value="Login"
                className="mt-4 submitButton"
                onClick={handleSubmit}
              />
            )}
            <p className="login-text-msg">
              Don’t have an account?{" "}
              <span>
               
                <Link to={component.register.url}>Sign Up</Link>
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
