import React, { useState } from "react";
import Label from "../common/formInput/Label";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetNewDeviceInfo,
  ResetUpdateNewDeviceFormError,
  UpdateNewDeviceInfo,
} from "../../redux/reducer/devices";
import useNewDeviceRegisterFormValidation from "../../hooks/validations/RegisterDevice";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";

const model_defination_data = [
  {
    key: "1",
    value: "Bag Tech",
  },
  {
    key: "2",
    value: "Cabin Tech",
  },
  {
    key: "3",
    value: "Cargo Tech",
  },
  {
    key: "4",
    value: "Panic Tech",
  },
  {
    key: "5",
    value: "Tower Tech",
  },
  {
    key: "6",
    value: "Trek Tech",
  },
  {
    key: "7",
    value: "Travel Tech",
  },
];

const tabHeading = [
  {
    title: "add Single data",
    activeTab: 0,
  },
  {
    title: "Bulk upload data",
    activeTab: 1,
  },
];

export default function AddDeviceInventory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { UploaDeviceCsvFileServiceHandler, AddDeviceServiceHandler } =
    useDevicesServiceHandler();

  const { OnChangeTextValidation, Validation } =
    useNewDeviceRegisterFormValidation();

  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [fileName, setFileName] = useState("");
  const [fileTypeError, setFileTypeError] = useState("");
  const [uploadFile, setUploadFile] = useState<any>(null);

  const {
    newDevice,
    newDeviceError,
    addDeviceSuccessMessage,
    addDeviceErrorMessage,
  } = useSelector((store: any) => store.device);

  const onChangeHandler = (e: any) => {
    const { name: key, value } = e.target;
    dispatch(UpdateNewDeviceInfo({ key, value }));
    OnChangeTextValidation({ key, value });
  };

  const registerBtnPressHandler = async (e: any) => {
    e.preventDefault();
    const isValid = Validation();
    if (isValid) {
      AddDeviceServiceHandler();
    }
  };
  const cancelDevice = () => {
    navigate(component.inventoryDeviceList.url);
    dispatch(ResetNewDeviceInfo());
    dispatch(ResetUpdateNewDeviceFormError());
  };

  const handleDeviceUpload = async (e: any) => {
    setError("");
    if (e?.target?.files[0]) {
      setUploadFile(e?.target?.files[0]);
      setFileName(e?.target?.files[0]?.name);
      if (e?.target?.files[0].type !== "text/csv") {
        setError("");
        setFileTypeError("Only .csv file type accepted");
      } else {
        setFileTypeError("");
      }
    }
  };
  const submitCsv = async (e: any) => {
    e.preventDefault();
    if (!uploadFile) {
      setFileTypeError("Required !");
    } else if (uploadFile.type !== "text/csv") {
      setFileTypeError("Only .csv file type accepted");
    } else {
      const formData = new FormData();
      formData.append("file", uploadFile);
      await UploaDeviceCsvFileServiceHandler(formData);
      setUploadFile(null);
      setFileName("");
      setFileTypeError("");
    }
  };

  return (
    <>
      <div className="screenContainerPadding">
        <p className="breadcrum">
          <span onClick={() => navigate(component.inventoryDeviceList.url)}>
            Device Inventory
          </span>{" "}
          / <span>Add Device</span>
        </p>
        <div className="formContainerBorder">
          <div className="device_tab">
            {tabHeading?.map((item, index) => {
              return (
                <div
                  className={
                    activeTab === item.activeTab
                      ? "device_tab_item active"
                      : "device_tab_item"
                  }
                  key={index}
                  onClick={() => setActiveTab(item.activeTab)}
                >
                  <p>{item?.title}</p>
                </div>
              );
            })}
          </div>
          {activeTab === 0 && (
            <form action="mt-4">
              <p className="formHeading">Add Single Device</p>
              <div className="mb-3">
                {addDeviceSuccessMessage ? (
                  <SuccessMessage msg={addDeviceSuccessMessage} />
                ) : addDeviceErrorMessage ? (
                  <ErrorMessage msg={addDeviceErrorMessage} />
                ) : (
                  ""
                )}
              </div>
              <div className="grid gap-5">
                <div className="flex flex-col">
                  <Label label="Device ID" required={true} />
                  <input
                    name="deviceId"
                    id="deviceId"
                    className="registerFormInput"
                    type="text"
                    placeholder="Enter the ID of the device you are registering."
                    value={newDevice?.deviceId}
                    onChange={onChangeHandler}
                  />
                  <p className="errorMessage">{newDeviceError?.deviceId}</p>
                </div>
                <div className="flex flex-col">
                  <Label label="Device Model" required={true} />
                  <select
                    name="model_definition"
                    id="model_definition"
                    className="registerFormSelectInput"
                    value={newDevice?.role}
                    onChange={onChangeHandler}
                  >
                    <option value="">Select the Device Model</option>
                    {model_defination_data?.map((item: any) => {
                      return (
                        <option key={item.key} value={item.key}>
                          {item?.value}
                        </option>
                      );
                    })}
                  </select>
                  <p className="errorMessage">
                    {newDeviceError?.model_definition}
                  </p>
                </div>
                <div className="flex flex-col">
                  <Label label="Set Subscription Plan" required={false} />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div>
                      <Label label="Monthly" required={true} />
                      <input
                        className="registerFormInput"
                        type="text"
                        name="subscriptionMonthly"
                        id="subscriptionMonthly"
                        value={newDevice?.subscriptionMonthly}
                        onChange={onChangeHandler}
                        placeholder="Enter monthly Charges"
                      />
                      {newDeviceError?.subscriptionMonthly && (
                        <p className="errorMessage">
                          {newDeviceError?.subscriptionMonthly}
                        </p>
                      )}
                    </div>
                    <div>
                      <Label label="Yearly" required={true} />
                      <input
                        className="registerFormInput"
                        type="text"
                        name="subscriptionYearly"
                        id="subscriptionYearly"
                        value={newDevice?.subscriptionYearly}
                        onChange={onChangeHandler}
                        placeholder="Enter yearly Charges"
                      />
                      {newDeviceError?.subscriptionYearly && (
                        <p className="errorMessage">
                          {newDeviceError?.subscriptionYearly}
                        </p>
                      )}
                    </div>
                  </div>
                  {newDeviceError?.subscriptionId && (
                    <p className="errorMessage">
                      {newDeviceError?.subscriptionId}
                    </p>
                  )}
                </div>
                <div className="flex gap-5 justify-end items-center">
                  <button
                    className="primaryButtonFilled"
                    onClick={registerBtnPressHandler}
                  >
                    Add
                  </button>
                  <button className="borderedButton" onClick={cancelDevice}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
          {activeTab === 1 && (
            <form action="mt-4">
              <p className="formHeading">Upload Bulk Device</p>
              {(addDeviceSuccessMessage || addDeviceErrorMessage) && (
                <div className="mb-3">
                  {addDeviceSuccessMessage ? (
                    <SuccessMessage msg={addDeviceSuccessMessage} />
                  ) : addDeviceErrorMessage ? (
                    <ErrorMessage msg={addDeviceErrorMessage} />
                  ) : (
                    ""
                  )}
                </div>
              )}

              <div className="grid gap-5">
                <div className="flex flex-col">
                  <input
                    type="file"
                    hidden
                    id="uploadCsvFile"
                    name="file"
                    // accept=".csv"
                    onChange={handleDeviceUpload}
                  />
                  <div className="relative">
                    <input
                      type="text"
                      className="registerFormInput"
                      placeholder="Upload CSV File"
                      value={fileName}
                      disabled
                    />
                    <label
                      htmlFor="uploadCsvFile"
                      className="cursor-pointer uploadCsvButton"
                    >
                      Choose CSV File
                    </label>
                  </div>

                  {fileTypeError && (
                    <p className="errorMessage">{fileTypeError}</p>
                  )}
                </div>
                <div className="flex gap-5 justify-end items-center">
                  <button className="primaryButtonFilled" onClick={submitCsv}>
                    Upload
                  </button>
                  <button
                    className="borderedButton"
                    onClick={() => navigate(component.inventoryDeviceList.url)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
