import React, { useState } from "react";
import Label from "../common/formInput/Label";
import TimezoneSelect, { type ITimezone, ITimezoneOption } from "react-timezone-select";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";

import { GetTimezone, UpdateDeviceTimezone } from "../../service/deviceTimezone";

export default function DeviceInfoSettingCard({
  deviceId,
  audible,
  flashingButtonsEnabled,
  statusIndicatorEnabled,
  cameraEnabled,
  motionEnabled,
  name,
  armed,
  onNameChange,
  onArmedChange = (arg: any) => {},
  onAudibleChange,
  onFlashingButtonsEnabledChange,
  onStatusIndicatorEnabledChange,
  onCameraEnabledChange,
  onMotionEnabledChange,
  updatedAt,
  lastCheckIn,
}) {

  const queryClient = useQueryClient();

  const timezoneQuery = useQuery({
	  queryKey: ['timezone'],
      queryFn: async () => {
        const data = await GetTimezone(deviceId);
        return data;
      }
    }
  );

  const mutation = useMutation({
		mutationFn: (mutation: {timezone: ITimezoneOption, deviceId: string}) => {
		  return UpdateDeviceTimezone(deviceId, mutation.timezone.value)
		},
        onSuccess: () => {
          queryClient.invalidateQueries({queryKey: ['timezone'] });
          queryClient.refetchQueries({queryKey: ['timezone'] });
        }
    });

  return (
    <>
      <div className="allDeviceCardContainer cardBoxShadow">
        <div className="flex justify-between items-center mb-4">
          <p className="deviceCardHeading mb-3">Settings</p>
          <button
            onClick={() => onArmedChange(!armed)}
            className={armed ? "dangerButton" : "secondaryButton"}
          >
            {armed ? "Disarm Device" : "Arm Device"}
          </button>
        </div>
        <div className="flex flex-row ">
          <div className="mb-3 flex-auto">
            <Label label="Device Name" required={false} />
            <form action="">
              <TextField
                placeholder="Enter Device Name"
                fullWidth
                value={name}
                onChange={(e) => onNameChange(e.target.value)}
                // className="inputFieldMsg"
              />
            </form>
          </div>
          <div className="my-7 mx-3 border-solid border-rose-600 select-wrapper flex-auto">
            <TimezoneSelect
              value={timezoneQuery.data?.timezone}
              labelStyle='altName'
              onChange={
                (timezone) => mutation.mutate({
                  timezone: timezone,
                  deviceId: deviceId,
            })}/>
          </div>
        </div>
        <FormControl component="fieldset">
          <Label label="Device Flags" required={false} />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={audible}
                  onChange={onAudibleChange}
                  name="audible"
                  color="primary"
                />
              }
              label="1"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={flashingButtonsEnabled}
                  onChange={onFlashingButtonsEnabledChange}
                  name="flashing_buttons_enabled"
                  color="primary"
                />
              }
              label="2"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={statusIndicatorEnabled}
                  onChange={onStatusIndicatorEnabledChange}
                  name="status_indicator_enabled"
                  color="primary"
                />
              }
              label="3"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cameraEnabled}
                  onChange={onCameraEnabledChange}
                  name="camera_enabled"
                  color="primary"
                />
              }
              label="4"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={motionEnabled}
                  onChange={onMotionEnabledChange}
                  name="motion_enabled"
                  color="primary"
                />
              }
              label="5"
            />
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
}
