import { celsiusToFahrenheit, fahrenheitToCelsius } from "./temprature";

export const Temperature = {
  fromSetPoint: (setPoint) => parseInt(setPoint) * ((125 + 55) / 9999) - 55,
  toSetPoint: (t) => (t + 55) / ((125 + 55) / 9999),
};

export const TemperatureFahrenheit = {
  fromSetPoint: (setPoint) =>
    celsiusToFahrenheit(Temperature.fromSetPoint(setPoint)),
  toSetPoint: (t) => Temperature.toSetPoint(fahrenheitToCelsius(t)),
};

export const Voltage = {
  fromSetPoint: (setPoint) => (36 * parseInt(setPoint)) / 9999,
  toSetPoint: (volts) => (9999 * volts) / 36,
};

export const OtherGas = {
  fromSetPoint: (setPoint) => (100 / 9999) * parseInt(setPoint),
  toSetPoint: (humidity) => (9999 * humidity) / 100,
};

export const Humidity = {
  fromSetPoint: (setPoint) => (100 / 9999) * parseInt(setPoint),
  toSetPoint: (humidity) => (9999 * humidity) / 100,
};

export const AnalogLine = {
  fromSetPoint: (setPoint) => (100 / 9999) * parseInt(setPoint),
  toSetPoint: (humidity) => (9999 * humidity) / 100,
};

export const Battery = {
  fromSetPoint: (setPoint) => (100 / 9999) * parseInt(setPoint),
  toSetPoint: (humidity) => (9999 * humidity) / 100,
};

// export const AirTemprature = {
//     fromSetPoint: (setPoint) =>
// }

export const CarbonMonoxide = {
  fromSetPoint: (ppm) => (9999 * ppm) / 1000,
  toSetPoint: (setPoint) => (1000 / 9999) * parseInt(setPoint),
};

export const Current = {
  fromSetPoint: (setPoint) => (100 * parseInt(setPoint)) / 9999,
  toSetPoint: (amps) => (9999 * amps) / 100,
};

export const Analog = (sensor) => ({
  fromSetPoint: (x) => {
    const x0 = sensor.analog_high_point ?? 0;
    const y0 = sensor.analog_high_value;
    const x1 = sensor.analog_low_point ?? 0;
    const y1 = sensor.analog_low_value;

    if (x1 - x0 === 0) {
      return () => 0;
    }

    return (y0 * (x1 - x) + y1 * (x - x0)) / (x1 - x0);
  },
  toSetPoint: (x) => {
    const x0 = sensor.analog_high_value ?? 0;
    const y0 = sensor.analog_high_point;
    const x1 = sensor.analog_low_value ?? 0;
    const y1 = sensor.analog_low_point;

    if (x1 - x0 === 0) {
      return () => 0;
    }

    return (y0 * (x1 - x) + y1 * (x - x0)) / (x1 - x0);
  },
});

export function fromSetPoint(sensor, setPoint, temperaturePreference) {
  switch (sensor.type) {
    case "01":
      if (temperaturePreference === "F") {
        return TemperatureFahrenheit.fromSetPoint(setPoint);
      }
      return Temperature.fromSetPoint(setPoint);
    case "02":
      return Humidity.fromSetPoint(setPoint);
    case "08":
      return Voltage.fromSetPoint(setPoint);
    case "09":
      return Current.fromSetPoint(setPoint);
    case "16":
      return Battery.fromSetPoint(setPoint);
    case "07":
    case "10":
    case "11":
    case "12":
    case "13":
    case "14":
    case "15":
    case "17":
    case "18":
    case "19":
    case "41":
    case "42":
    case "43":
    case "44":
    case "45":
    case "46":
    case "47":
    case "48":
      return Analog(sensor).fromSetPoint(setPoint);
    default:
      return null;
  }
}

export function toSetPoint(sensorType, setPoint) {
  switch (sensorType) {
    case "01":
      return Temperature.toSetPoint(setPoint);
    case "02":
      return Humidity.toSetPoint(setPoint);
    case "07":
      return Temperature.toSetPoint(setPoint);
    case "08":
      return Voltage.toSetPoint(setPoint);
    case "09":
      return Current.toSetPoint(setPoint);
    case "10":
    case "11":
    case "12":
    case "13":
    case "14":
    case "15":
      return OtherGas.toSetPoint(setPoint);
    case "16":
      return Battery.toSetPoint(setPoint);
    default:
      return null;
  }
}

export function adjustTimeseriesData(
  sensorType,
  temperaturePreference,
  data,
  offset
) {
  if (offset === 0) {
    return data;
  }

  return data.map(([t, v]) => [t, v + offset]);
}

export function translateLastValue(
  offset: number,
  lastValue: any,
  units: string,
  temperaturePreference: string
) {
  if (typeof lastValue === "boolean") {
    return `${lastValue ? "CLOSED" : "OPEN"}`;
  } else if (typeof lastValue === "object" && lastValue != null) {
    return `${lastValue[0]?.lat?.toFixed(4)}° ${lastValue[0]?.lng?.toFixed(
      4
    )}°`;
  } else if (units === "C" && temperaturePreference === "F") {
    return celsiusToFahrenheit(lastValue + offset)?.toFixed(2);
  } else if (lastValue) {
    return (lastValue + offset)?.toFixed(2);
  } else {
    return "No Reading";
  }
}
