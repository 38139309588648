import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import CheckoutPaymentForm from "../../device/CheckoutPaymentForm";

const stripePromise = loadStripe(
  "pk_test_51NtRV5SFXTYvwKhVyOoSkAFtq4cHbxoYARyUvxFsXIFuIk2TyHS5EAIScWDV1LqbKVTPhZxmiaYGKQryTleIreGM00tceksMSz"
);

export default function PaymentModal({ intentId, deviceId, subscriptionId }) {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    setClientSecret(intentId);
  }, [intentId]);

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutPaymentForm
            deviceId={deviceId}
            subscriptionId={subscriptionId}
          />
        </Elements>
      )}
    </>
  );
}
