import React from "react";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import UserTable from "./UserTable";
import searchIcon from "../../assets/images/icons/searchIcon.svg";
import filterIcon from "../../assets/images/icons/filterIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { ResetUpdatedUserManagement } from "../../redux/reducer/userManagement";

export default function List() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRoute = (url: string) => {
    dispatch(ResetUpdatedUserManagement());
    navigate(url);
  };

  const { newUser } = useSelector((store: any) => store.userManagement);

  return (
    <div className="screenContainerPadding">
      <div className="flex justify-between">
        <p className="breadcrum">
          <span>User Listing</span>
        </p>
        <button
          onClick={() => handleRoute(component.createUser.url)}
          className="borderedButton"
        >
          Add New User
        </button>
      </div>
      <div className="registerFormContainer">
        <div className="flex flex-col md:flex-row items-stretch justify-between md:items-center mb-5">
          <p className="formHeading">User List</p>
          <div className="flex justify-end gap-5"></div>
        </div>
        <UserTable />
      </div>
    </div>
  );
}
