import { useCallback, useRef } from "react";

export default function useDebouncedCallback(fn: any, timeout: any, deps: any) {
  const timer = useRef(0);
  return useCallback(
    (...args: any) => {
      clearTimeout(timer?.current);
      timer.current = window.setTimeout(() => fn(...args), timeout);
    },
    [...deps, fn, timeout]
  );
}
