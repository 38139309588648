import { Link } from "react-router-dom";
import component from "../../../constants/urls";
import "./page404.css";

export default function Page404() {
  return (
    <div className="pageNotFoundContainer">
      <div className="flex flex-col md:flex-row gap-10">
        <div className="w-full flex flex-col justify-center gap-5 items-center">
          <p className="errorText1"> Page Not Found</p>
          <p className="errorText1">This page does not exists :(</p>
          {/* <p className="errorText2">You can go to homepage</p> */}
          <Link to={component.home.url}>
            {" "}
            <button className="homepageButton">Back to Homepage</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
