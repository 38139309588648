import { UserData, changePassowrdData } from "../../interface/reducer/user";

const initialState = {
  isLogin: false,
  userInfo: {
    access_token: "",
    name: "",
    email: "",
    phone: "",
    permissions: [],
  },
  userCreaterInfo: {},
  userInfoFormError: {
    name: "",
    email: "",
    phone: "",
  },
  changePassword: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  changePasswordFromError: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  updateUserSuccessMessage: "",
  updateUserErrorMessage: "",
};

export interface changePasswordInput {
  key: "currentPassword" | "newPassword" | "confirmPassword";
  value: string;
}
export interface updateUserInfoForm {
  key: "name" | "email" | "phone";
  value: string;
}

interface updateIsLoginState {
  type: "UPDATE_IS_LOGIN_STATE";
  payload: boolean;
}

interface updateUserInfo {
  type: "UPDATE_USER_INFO";
  payload: UserData;
}
interface updateUserCreaterInfo {
  type: "UPDATE_USER_CREATOR_INFO";
  payload: any;
}
interface resetUserInfo {
  type: "RESET_USER_INFO";
}

interface updateProfileDataInput {
  type: "UPDATE_PROFILE_DATA_INPUT";
  payload: updateUserInfoForm;
}

// interface updateUserInfoFormError {
//   type: "UPDATE_USER_INFO_FORM_ERROR";
//   payload: UserData;
// }
interface updatePassword {
  type: "UPDATE_PASSWORD";
  payload: changePasswordInput;
}
interface resetPassword {
  type: "RESET_CHANGE_PASSWORD";
}
interface updateChangePasswordFormError {
  type: "UPDATE_CHANGE_PASSWORD_FORM_ERROR";
  payload: changePasswordInput;
}
interface resetChangePasswordFormError {
  type: "RESET_CHANGE_PASSWORD_FORM_ERROR";
}
interface updateUserSuccessMessage {
  type: "UPDATE_USER_SUCCESS_MESSAGE";
  payload: string;
}
interface updateUserErrorMessage {
  type: "UPDATE_USER_ERROR_MESSAGE";
  payload: string;
}
interface updateProfileFormError {
  type: "UPDATE_PROFILE_FORM_ERROR";
  payload: updateUserInfoForm;
}
interface resetProfileFromError {
  type: "RESET_PROFILE_FORM_ERROR";
}

type action =
  | updateIsLoginState
  | updateUserInfo
  | updatePassword
  | resetPassword
  | updateChangePasswordFormError
  | resetChangePasswordFormError
  | resetUserInfo
  | updateUserSuccessMessage
  | updateUserErrorMessage
  | updateProfileFormError
  | resetProfileFromError
  | updateProfileDataInput | updateUserCreaterInfo;

const UserReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case "UPDATE_IS_LOGIN_STATE": {
      return {
        ...state,
        isLogin: action.payload,
      };
    }
    case "UPDATE_PROFILE_DATA_INPUT": {
      return {
        ...state,
        userInfo: {
          ...state,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "UPDATE_USER_INFO": {
      return {
        ...state,
        userInfo: {
          ...action.payload,
        },
      };
    }
    case 'UPDATE_USER_CREATOR_INFO': {
      return {
        ...state,
        userCreaterInfo: {
          ...action.payload,
        }
      }
    }
    case "RESET_USER_INFO": {
      return {
        ...state,
        userInfo: {
          access_token: "",
          name: "",
          email: "",
          phone: "",
          permissions: [],
        },
      };
    }
    case "UPDATE_PASSWORD": {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "RESET_CHANGE_PASSWORD": {
      return {
        ...state,
        changePassword: {
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        },
      };
    }
    case "UPDATE_CHANGE_PASSWORD_FORM_ERROR": {
      return {
        ...state,
        changePasswordFromError: {
          ...state.changePasswordFromError,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "RESET_CHANGE_PASSWORD_FORM_ERROR": {
      return {
        ...state,
        changePasswordFromError: {
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        },
      };
    }
    case "UPDATE_USER_SUCCESS_MESSAGE": {
      return {
        ...state,
        updateUserSuccessMessage: action.payload,
      };
    }
    case "UPDATE_USER_ERROR_MESSAGE": {
      return {
        ...state,
        updateUserErrorMessage: action.payload,
      };
    }
    case "UPDATE_PROFILE_FORM_ERROR": {
      return {
        ...state,
        userInfoFormError: {
          ...state.userInfoFormError,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "RESET_PROFILE_FORM_ERROR": {
      return {
        ...state,
        userInfoFormError: {
          name: "",
          email: "",
          phone: "",
        },
      };
    }

    default:
      return state;
  }
};

export default UserReducer;

export const UpdateIsLoginState = (state: boolean): updateIsLoginState => {
  return {
    type: "UPDATE_IS_LOGIN_STATE",
    payload: state,
  };
};

export const UpdateUserInfo = (data: UserData): updateUserInfo => {
  return {
    type: "UPDATE_USER_INFO",
    payload: data,
  };
};

export const UpdateUserCreatorInfo = (data: any): any => {
  return {
    type: 'UPDATE_USER_CREATOR_INFO',
    payload: data
  }
}
export const ResetUserInfo = (): resetUserInfo => {
  return {
    type: "RESET_USER_INFO",
  };
};

export const UpdateChangePassword = (
  data: changePasswordInput
): updatePassword => {
  return {
    type: "UPDATE_PASSWORD",
    payload: data,
  };
};

export const ResetChangePassword = (): resetPassword => {
  return {
    type: "RESET_CHANGE_PASSWORD",
  };
};

export const UpdatePasswordFormError = (
  data: changePasswordInput
): updateChangePasswordFormError => {
  return {
    type: "UPDATE_CHANGE_PASSWORD_FORM_ERROR",
    payload: data,
  };
};

export const ResetPasswordFormError = (): resetChangePasswordFormError => {
  return {
    type: "RESET_CHANGE_PASSWORD_FORM_ERROR",
  };
};
export const UpdateUserSuccessMessage = (
  updateUserSuccessMessage: string
): updateUserSuccessMessage => {
  return {
    type: "UPDATE_USER_SUCCESS_MESSAGE",
    payload: updateUserSuccessMessage,
  };
};
export const UpdateUserErrorMessage = (
  updateUserErrorMessage: string
): updateUserErrorMessage => {
  return {
    type: "UPDATE_USER_ERROR_MESSAGE",
    payload: updateUserErrorMessage,
  };
};
export const UpdateUserInfoFormError = (
  userInfoFormError: updateUserInfoForm
): updateProfileFormError => {
  return {
    type: "UPDATE_PROFILE_FORM_ERROR",
    payload: userInfoFormError,
  };
};
export const ResetProfileFormError = (): resetProfileFromError => {
  return {
    type: "RESET_PROFILE_FORM_ERROR",
  };
};
export const UpdateUserProfileFormInput = (
  data: updateUserInfoForm
): updateProfileDataInput => {
  return {
    type: "UPDATE_PROFILE_DATA_INPUT",
    payload: data,
  };
};
