import { useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX } from "../../constants/constant";
import {
  ResetLoginFormError,
  UpdateLoginFormError,
} from "../../redux/reducer/auth";

const useLoginValidation = () => {
  const { login } = useSelector((store: any) => store.auth);
  const dispatch = useDispatch();

  const LoginFormValidation = () => {
    if (login?.email?.trim() === "") {
      dispatch(ResetLoginFormError());
      dispatch(UpdateLoginFormError({ key: "email", value: "Required !" }));
      return false;
    } else if (!EMAIL_REGEX.test(login?.email)) {
      dispatch(ResetLoginFormError());
      dispatch(
        UpdateLoginFormError({
          key: "email",
          value: "Please enter a valid email.",
        })
      );
      return false;
    } else if (login?.password?.trim() === "") {
      dispatch(ResetLoginFormError());
      dispatch(UpdateLoginFormError({ key: "password", value: "Required !" }));
      return false;
    } else {
      dispatch(ResetLoginFormError());
      return true;
    }
  };

  const OnChangeLoginFormValueValidation = ({
    id,
    value,
  }: {
    id: "email" | "password";
    value: string;
  }) => {
    if (id === "email" && value?.trim() === "") {
      dispatch(ResetLoginFormError());
      dispatch(UpdateLoginFormError({ key: "email", value: "Required !" }));
      return false;
    } else if (id === "email" && !EMAIL_REGEX.test(value)) {
      dispatch(ResetLoginFormError());
      dispatch(
        UpdateLoginFormError({
          key: "email",
          value: "Please enter a valid email.",
        })
      );
      return false;
    } else if (id === "password" && value?.trim() === "") {
      dispatch(ResetLoginFormError());
      dispatch(UpdateLoginFormError({ key: "password", value: "Required !" }));
      return false;
    } else {
      dispatch(ResetLoginFormError());
      return true;
    }
  };

  return {
    LoginFormValidation,
    OnChangeLoginFormValueValidation,
  };
};

export default useLoginValidation;
