import { priceData } from "../../interface/reducer/payment";

const initalstate = {
  priceList: [],
  selectedPriceData: {},
  paymentSuccessMessage: "",
  paymentErrorMessage: "",
};

interface updatePriceList {
  type: "UPDATE_PRICE_LIST";
  payload: Array<any>;
}

interface updateSelectecPriceData {
  type: "UPDATE_SELECTED_PRICE_DATA";
  payload: priceData;
}
interface updatePaymentSuccessMessage {
  type: "UPDATE_PAYMENT_SUCCESS_MESSAGE";
  payload: string;
}
interface updatePaymentErrorMessage {
  type: "UPDATE_PAYMENT_ERROR_MESSAGE";
  payload: string;
}

type action =
  | updatePriceList
  | updateSelectecPriceData
  | updatePaymentSuccessMessage
  | updatePaymentErrorMessage;

const StripeReducer = (state = initalstate, action: action) => {
  switch (action.type) {
    case "UPDATE_PRICE_LIST": {
      return {
        ...state,
        priceList: [...action.payload],
      };
    }
    case "UPDATE_SELECTED_PRICE_DATA": {
      return {
        ...state,
        selectedPriceData: {
          ...action.payload,
        },
      };
    }
    case "UPDATE_PAYMENT_SUCCESS_MESSAGE": {
      return {
        ...state,
        paymentSuccessMessage: action.payload,
      };
    }
    case "UPDATE_PAYMENT_ERROR_MESSAGE": {
      return {
        ...state,
        paymentErrorMessage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default StripeReducer;

export const UpdatePriceListData = (priceList: Array<any>): updatePriceList => {
  return {
    type: "UPDATE_PRICE_LIST",
    payload: priceList,
  };
};
export const UpdateSelectedPriceData = (
  priceData: priceData
): updateSelectecPriceData => {
  return {
    type: "UPDATE_SELECTED_PRICE_DATA",
    payload: priceData,
  };
};
export const UpdatePaymentSuccessMessage = (
  paymentSuccessMessage: string
): updatePaymentSuccessMessage => {
  return {
    type: "UPDATE_PAYMENT_SUCCESS_MESSAGE",
    payload: paymentSuccessMessage,
  };
};

export const UpdatePaymentErrorMessage = (
  paymentErrorMessage: string
): updatePaymentErrorMessage => {
  return {
    type: "UPDATE_PAYMENT_ERROR_MESSAGE",
    payload: paymentErrorMessage,
  };
};
