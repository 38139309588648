import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

interface Column {
  id: "Time" | "Value" | "Alert" | "Voltage Input";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "Time", label: "Time", align: "right" },
  { id: "Value", label: "Value", align: "right" },
  { id: "Alert", label: " Alert", align: "right" },
  { id: "Voltage Input", label: "Voltage Input", align: "right" },
];

export function translateRawData(data: any) {
  if (typeof data === "object" && data != null) {
    return `${data?.lat?.toFixed(4)}° ${data?.lng?.toFixed(4)}°`;
  } else if (typeof data === "number") {
    return data.toFixed(2);
  } else if (typeof data === "boolean") {
    return data ? "Closed" : "Open";
  } else {
    return data;
  }
}

export default function SensorData({ data }) {
  // const { GetDevicesListServiceHandler } = useDevicesServiceHandler();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {data?.length ? (
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer sx={{ minHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        backgroundColor: "#2047AB",
                        borderBottom: "none",
                      }}
                    >
                      <p className="tableText" style={{ color: "#ffffff" }}>
                        {column.label}
                      </p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <p className="userTableData mb-2">
                            {row?.time}
                            {/* {moment(row?.time).format("YYYY-MM-DD hh:mm:ss")} */}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {translateRawData(row?.data)}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">{mapLevel(row)}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {row.battery == null
                              ? "-"
                              : `${((row?.battery / 100) * 36)?.toFixed(1)}V`}
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <p className="sensorName text-center mt-6">No Data Found</p>
      )}
    </>
  );
}
export function mapLevel(row: any) {
  switch (row?.level) {
    case "H":
      return "High";
    case "L":
      return "Low";
    case "N":
    default:
      return "Normal";
  }
}
