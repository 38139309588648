import { useDispatch, useSelector } from "react-redux";
import {
  ResetPasswordFormError,
  ResetProfileFormError,
  UpdatePasswordFormError,
  UpdateUserInfoFormError,
} from "../../redux/reducer/user";
import { PASSWORD_REGEX } from "../../constants/constant";

const useUserValidation = () => {
  const dispatch = useDispatch();

  const { changePassword, userInfo } = useSelector((store: any) => store.user);

  const UserChangePasswordFormValidation = () => {
    if (changePassword?.currentPassword?.trim() === "") {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({ key: "currentPassword", value: "Required !" })
      );
      return false;
    } else if (changePassword?.newPassword?.trim() === "") {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({ key: "newPassword", value: "Required !" })
      );
      return false;
    } else if (!PASSWORD_REGEX.test(changePassword?.newPassword)) {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({
          key: "newPassword",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else if (changePassword?.confirmPassword?.trim() === "") {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({ key: "confirmPassword", value: "Required !" })
      );
      return false;
    } else if (!PASSWORD_REGEX.test(changePassword?.confirmPassword)) {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({
          key: "confirmPassword",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else {
      dispatch(ResetPasswordFormError());
      return true;
    }
  };

  const OnChangeUserChangePasswordFormValueValidation = ({
    id,
    value,
  }: {
    id: "currentPassword" | "newPassword" | "confirmPassword";
    value: string;
  }) => {
    if (id === "currentPassword" && value?.trim() === "") {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({ key: "currentPassword", value: "Required !" })
      );
      return false;
    } else if (id === "newPassword" && value?.trim() === "") {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({ key: "newPassword", value: "Required !" })
      );
      return false;
    } else if (id === "newPassword" && !PASSWORD_REGEX.test(value)) {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({
          key: "newPassword",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else if (id === "confirmPassword" && value?.trim() === "") {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({ key: "confirmPassword", value: "Required !" })
      );
      return false;
    } else if (id === "confirmPassword" && !PASSWORD_REGEX.test(value)) {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({
          key: "confirmPassword",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else if (
      id === "confirmPassword" &&
      changePassword?.newPassword !== value
    ) {
      dispatch(ResetPasswordFormError());
      dispatch(
        UpdatePasswordFormError({
          key: "confirmPassword",
          value: "Password not match !",
        })
      );
      return false;
    } else {
      dispatch(ResetPasswordFormError());
      return true;
    }
  };

  const UserProfileFormValidation = () => {
    if (userInfo?.name?.trim() === "") {
      dispatch(ResetProfileFormError());
      dispatch(UpdateUserInfoFormError({ key: "name", value: "Required !" }));
      return false;
    } else {
      dispatch(ResetProfileFormError());
      return true;
    }
  };
  const OnChangeUserProfileFormValidation = ({
    id,
    value,
  }: {
    id: "name";
    value: string;
  }) => {
    if (id === "name" && value?.trim() === "") {
      dispatch(ResetProfileFormError());
      dispatch(UpdateUserInfoFormError({ key: "name", value: "Required !" }));
    } else {
      dispatch(ResetProfileFormError());
      return true;
    }
  };
  return {
    UserChangePasswordFormValidation,
    OnChangeUserChangePasswordFormValueValidation,
    UserProfileFormValidation,
    OnChangeUserProfileFormValidation,
  };
};

export default useUserValidation;
