import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { UpdateDrawerExpand } from "../../../redux/reducer/commonReducer";
import DrawerComponent from "../drawer/DrawerComponent";
import { BsInfoCircleFill } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";
import LogsDetail from "../drawer/LogDetail";
import { logs } from "../../../interface/reducer/logs";
import useDeviceLogs from "../../../hooks/serviceHandler/logs";
import { ClipLoader } from "react-spinners";
import Label from "../formInput/Label";
import { device } from "../../../interface/reducer/device";
import Pagination from "react-js-pagination";

interface Column {
  id: "Device Id" | "Device Name" | "Model" | "Time" | "Log Type" | "View Logs";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "Device Id", label: "Device Id", align: "right" },
  { id: "Device Name", label: "Device Name", align: "right" },
  { id: "Model", label: "Model", align: "right" },
  { id: "Time", label: "Time", align: "right" },
  { id: "Log Type", label: "Log Type", align: "right" },
  { id: "View Logs", label: "View Logs", align: "right" },
];

export default function LogsTable() {
  const dispatch = useDispatch();

  const { GetLogsDetailDataServiceHandler } = useDeviceLogs();
  const { GetAllLogsDataServiceHandler } = useDeviceLogs();
  const { allLogsList, logIsLoder, logCount } = useSelector(
    (store: any) => store.log
  );
  const { devicesList } = useSelector((store: any) => store.device);
  const [logType, setLogType] = React.useState("");
  const [deviceNameState, setDeviceNameState] = React.useState("");
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const { drawerExpand } = useSelector((store: any) => store.common);

  const onChangeTypeFilter = async (value: string) => {
    setLogType(value);
    await GetAllLogsDataServiceHandler(currentPage, value, deviceNameState);
  };

  const handlelogDetail = async (logData: logs) => {
    await GetLogsDetailDataServiceHandler(logData.id, logData.logType);
    // await GetLogsDetailDataServiceHandler(logData.filename, logData.logType);
    dispatch(UpdateDrawerExpand(true));
  };
  const onChangeFilterByDevice = async (device: string) => {
    setDeviceNameState(device);
    await GetAllLogsDataServiceHandler(currentPage, logType, device);
  };

  const handlePageChange = async (pageNumber: number) => {
    setCurrentPage(pageNumber);
    await GetAllLogsDataServiceHandler(pageNumber, logType, deviceNameState);
  };

  const handleFromDateChange = async (date: any) => {
    setFromDate(date);
    await GetAllLogsDataServiceHandler(currentPage, logType, deviceNameState);
  };
  const handleToDateChange = async (value: any) => {
    setToDate(value);
    await GetAllLogsDataServiceHandler(currentPage, logType, deviceNameState);
  };

  React.useEffect(() => {
    GetAllLogsDataServiceHandler(currentPage, logType, deviceNameState);
  }, []);

  return (
    <>
      <div className="registerFormContainer">
        <div className="flex flex-col md:flex-row justify-between md:items-center mb-5">
          <p className="formHeading">
            {logType === "status"
              ? "Status Logs"
              : logType === "alert"
              ? "Alert Logs"
              : "Device Logs"}
          </p>
          <div className="flex justify-end gap-5">
            <div>
              <Label label="Select Device Name" required={false} />
              <select
                value={deviceNameState}
                className="registerFormSelectInput"
                onChange={(e: any) => onChangeFilterByDevice(e.target.value)}
              >
                <option value="all">All</option>
                {devicesList.map((item: device) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item?.name ? item.name : "Unnamed Device"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <Label label="Select Log Type" required={false} />
              <select
                value={logType}
                className="registerFormSelectInput"
                onChange={(e) => onChangeTypeFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="postStatus">Status</option>
                <option value="alert">Alert</option>
              </select>
            </div>
            {/* <div className="flex gap-3">
              <div>
                <Label label="Start Date" required={false} />
                <input
                  type="date"
                  value={fromDate}
                  className="registerFormSelectInput"
                  onChange={(e) => handleFromDateChange(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label label="From Date" required={false} />
                <input
                  type="date"
                  value={toDate}
                  className="registerFormSelectInput"
                  onChange={(e) => handleToDateChange(e.target.value)}
                />
              </div>
            </div> */}
          </div>
        </div>

        {logIsLoder ? (
          <div className="w-full flex items-center justify-center">
            <ClipLoader
              color={"#2047ab"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : allLogsList?.length ? (
          <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer sx={{ minHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        // align={column.align}
                        style={{
                          backgroundColor: "#2047AB",
                          borderBottom: "none",
                        }}
                      >
                        <p className="tableText" style={{ color: "#ffffff" }}>
                          {column.label}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allLogsList.map((row: logs, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <p className="userTableData">
                            {" "}
                            {row?.deviceId ? row?.deviceId : "-"}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData mb-2">
                            {row?.deviceName
                              ? row?.deviceName
                              : "Unnamed Device"}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {row?.model ? row.model : "NA"}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {row?.time ? row?.time : "-"}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">{row?.logType}</p>
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="View Logs">
                            <IconButton onClick={() => handlelogDetail(row)}>
                              <BsInfoCircleFill
                                fontSize={18}
                                color="#1E90FF"
                                className="cursor-pointer"
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="mt-5">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={100}
                totalItemsCount={logCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </Paper>
        ) : (
          <p className="sensorName text-center mt-6">No Data Found</p>
        )}
      </div>
      {drawerExpand && (
        <DrawerComponent direction={"right"}>
          <LogsDetail />
        </DrawerComponent>
      )}
    </>
  );
}
