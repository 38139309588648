import React, { useEffect, useState } from "react";
import firmware from "../../assets/images/locationSensorNew.svg";
import useFirmware from "../../hooks/serviceHandler/firmware";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import CopyToClipboardUrl from "./CopyClipBoard";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { UpdateModalOpenStatus } from "../../redux/reducer/commonReducer";
import image from "../../assets/images/949980.png";

export default function FirmwarePreviewImage(props: {
  imageData: any;
  getAllImages: any;
}) {
  const { selectedFolder } = useSelector((store: any) => store.firmware);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const {
    DeleteFirmwareImagesServiceHandler,
    GetAllFirmwareImagesServiceHandler,
  } = useFirmware();

  const deleteImage = async () => {
    await DeleteFirmwareImagesServiceHandler(props?.imageData?.id);
    props.getAllImages();
    setTimeout(() => {
      dispatch(UpdateModalOpenStatus(false));
    }, 1000);
  };

  const downloadImage = () => {
    saveAs(
      `${process.env.REACT_APP_URL}v1/getFirmware/${props?.imageData?.imageName}`,
      `${props.imageData?.imageName}`
    ); // Put your image URL here.
    // dispatch(UpdateModalOpenStatus(false));
  };

  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <div className="w-40 h-full">
        {props?.imageData?.imageName?.split(".")[1] === "bin" ? (
          <img src={image} className="w-full h-full" />
        ) : (
          <img
            src={`${process.env.REACT_APP_URL}v1/getFirmware/${props?.imageData?.imageName}`}
            alt={props?.imageData?.image}
            className="w-full h-full"
          />
        )}
        
      </div>
      <div style={{ width: "100%" }}>
        <CopyToClipboardUrl
          imgUrl={`${process.env.REACT_APP_URL}v1/getFirmware/${props?.imageData?.imageName}`}
        />
      </div>
      <div className="flex justify-between w-full">
        <Button
          onClick={deleteImage}
          variant="outlined"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>

        <button onClick={downloadImage} className="primaryButtonFilled">
          Download
        </button>
      </div>
    </div>
  );
}
