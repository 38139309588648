import React from "react";
import SensorMapView from "../common/map/SensorMap";
import { sensor } from "../../interface/reducer/sensor";
import SensorGraphLine from "./SensorGraphLine";
import { useSelector } from "react-redux";
import { celsiusToFahrenheit } from "../../constants/temprature";
import DeviceByIdMapView from "../common/map/DeviceByIdMapView";
import CordinatedMap from "../common/map/CordinatedMap";
import { log } from "console";

export default function SensorCardGraphData({
  deviceId,
  sensor,
  sensorType,
  sensorEsn,
  offset,
  timeSeries,
  graphOnCardHeading,
  gpsLocation,
}) {
  const { temperaturePreference } = useSelector((store: any) => store.sensor);
  
  return (
    <>
      {sensorType !== "00" && (
        <div>
          <div className="flex justify-between items-center">
            <p
              className={
                graphOnCardHeading ? "sensorHeadingCard" : "sensorHeading"
              }
            >
              {getPageSectionHeaderTitle(sensorType)}
            </p>
          </div>
          <div className="w-full">
            {timeSeries && (
              <SensorGraphLine
                yAxisLabel={translateYAxisLabel(
                  timeSeries?.y_axis_label,
                  sensorType,
                  temperaturePreference
                )}
                data={translateTimeseriesData(
                  timeSeries?.data,
                  sensorType,
                  temperaturePreference,
                  offset
                )}
                sensor={sensor}
                alerts={timeSeries?.alerts}
                strokeWidth={graphOnCardHeading}
                deviceId={deviceId}
              />
            )}
          </div>
        </div>
      )}

      {sensorType === "00" && (
        <div className={!graphOnCardHeading ? "h-96 w-full" : "h-52 w-full"}>
          <CordinatedMap
            gpsLocation={gpsLocation}
            manualCtr={graphOnCardHeading}
          />
        </div>
      )}

      {/* {sensorType === "00" && (
        <div
          className={
            !graphOnCardHeading ? "h-96 w-full mt-7" : "h-44 w-full mt-7"
          }
        >
          <DeviceByIdMapView
            latitude={getLatLng(sensor)?.lat}
            longitude={getLatLng(sensor)?.lng}
          />
        </div>
      )} */}
    </>
  );
}
function getLatLng(sensor: sensor) {
  if (
    !sensor ||
    !sensor?.last_value ||
    !Array?.isArray(sensor?.last_value) ||
    sensor?.last_value?.length === 0
  ) {
    return { lat: 0, lng: 0 };
  } else {
    return { ...sensor?.last_value[0] };
  }
}

function translateTimeseriesData(
  data: any,
  sensorType: string,
  temperaturePreference: string,
  offset: any
) {
  if (sensorType === "01") {
    if (temperaturePreference === "F") {
      data = data?.map(([t, v]) => [t, celsiusToFahrenheit(v + offset)]);
    } else {
      data = data?.map(([t, v]) => [t, v + offset]);
    }
    return data;
  }
  return data?.map(([t, v]) => [t, v + offset]);
}

function translateYAxisLabel(
  label: string,
  sensorType: string,
  temperaturePreference: string
) {
  if (sensorType === "01" && temperaturePreference === "F") {
    return "Temperature (℉)";
  }
  return label;
}
function getPageSectionHeaderTitle(sensorType: string) {
  switch (sensorType) {
    case "00":
      return "Map";
    default:
      return "Graphs";
  }
}
