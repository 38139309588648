import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../../redux/reducer/commonReducer";
import "./modal.css";

interface modalInterface {
  children: ReactNode;
  heading: string;
}

export default function ModalComponent(props: modalInterface) {
  const dispatch = useDispatch();

  const { modalIsOpen } = useSelector((store: any) => store.common);
  const onCloseModal = () => {
    dispatch(UpdateModalOpenStatus(false));
    dispatch(UpdateModalName(""));
  };

  return (
    <>
      {modalIsOpen ? (
        <Modal open={modalIsOpen} onClose={onCloseModal} center>
          {props.heading && (
            <div className="modal-header">
              <h2 className="heading">{props.heading}</h2>
            </div>
          )}
          <div className="modal-body">{props.children}</div>
        </Modal>
      ) : (
        props.children
      )}
    </>
  );
}
