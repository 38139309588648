import React, { useEffect, useState } from "react";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";
import Label from "../common/formInput/Label";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFirmware from "../../hooks/serviceHandler/firmware";
import {
  UpdateFirmwareErrorMessage,
  UpdateFirmwareSuccessMessage,
} from "../../redux/reducer/firmware";

export default function AddFirmware() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { GetFirmwareServiceHandler, UpdateFirmwareDataServiceHandler } =
    useFirmware();

  const { firmwareList, firmwareSuccessMessage, firmwareErrorMessage } =
    useSelector((store: any) => store.firmware);
  const [firmData, setFirmData] = useState({
    firmware: "",
  });
  const [error, setError] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFirmData({
      ...firmData,
      [name]: value,
    });
  };

  const submitBtn = async (e: any) => {
    e.preventDefault();
    if (firmData?.firmware.length === 0) {
      setError(true);
    } else {
      setError(false);
      await UpdateFirmwareDataServiceHandler(
        firmwareList?.id,
        firmData?.firmware
      );
    }
  };

  const cancelBtn = (e: any) => {
    e.preventDefault();
    setFirmData({
      firmware: firmwareList?.firmware_version,
    });
    dispatch(UpdateFirmwareSuccessMessage(""));
    dispatch(UpdateFirmwareErrorMessage(""));
  };

  useEffect(() => {
    if (firmwareList) {
      setFirmData({
        firmware: firmwareList?.firmware_version,
      });
    }
  }, [firmwareList]);

  useEffect(() => {
    GetFirmwareServiceHandler();
  }, []);

  return (
    <>
      <div className="registerFormContainer">
        <div className="mb-2">
          {firmwareSuccessMessage ? (
            <SuccessMessage msg={firmwareSuccessMessage} />
          ) : firmwareErrorMessage ? (
            <ErrorMessage msg={firmwareErrorMessage} />
          ) : (
            ""
          )}
        </div>
        <form action="">
          <p className="formHeading">Firmware</p>
          <div className="grid gap-5">
            <div className="flex flex-col">
              <Label label="Firmware version" required={true} />
              <input
                className="registerFormInput"
                type="text"
                name="firmware"
                id="firmware"
                placeholder="Enter Firmware version"
                value={firmData?.firmware}
                onChange={handleChange}
              />
              {error && !firmData?.firmware ? (
                <p className="errorMessage">Required !</p>
              ) : firmData?.firmware?.length !== 4 ? (
                <p className="errorMessage">
                  Firmware version must be 4 characters.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="flex gap-5 justify-end items-center">
              <button className="primaryButtonFilled" onClick={submitBtn}>
                Save
              </button>
              <button className="borderedButton" onClick={cancelBtn}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
