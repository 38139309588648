import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Data } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import useFirmware from "../hooks/serviceHandler/firmware";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../redux/reducer/commonReducer";
import { env } from "process";
import { UploadAndDisplayImage } from "../components/firmware/UploadImage";
import ModalComponent from "../components/common/modal/Modal";
import FirmwarePreview from "../components/firmware/FirmwarePreview";
import FirmwarePreviewImage from "../components/firmware/FirmwarePreviewImage";
import "../assets/css/firmware.css";
import image from "../assets/images/949980.png";

export default function FirmwareImages() {
  const dispatch = useDispatch();
  const { GetAllFirmwareImagesServiceHandler } = useFirmware();
  const { modalName, modalIsOpen } = useSelector((store: any) => store.common);
  const [imageList, setImageList] = useState([]);
  const [imageData, setImageData] = useState("");

  const [loader, setLoader] = useState(false);

  const openModal = (modalName: string, data: any, e: any) => {
    e.preventDefault();
    setImageData(data);
    dispatch(UpdateModalName(modalName));
    dispatch(UpdateModalOpenStatus(true));
  };

  const getAllImages = async () => {
    setLoader(true);
    const data = await GetAllFirmwareImagesServiceHandler();
    setLoader(false);
    setImageList(data.imageList);
  };
  const getAllImage = () => {
    getAllImages();
  };
  useEffect(() => {
    getAllImages();
  }, []);
  console.log("imageList", imageList[0]);

  return (
    <>
      <div className="uploadImageContainer">
        <UploadAndDisplayImage getAllImages={getAllImage} />
      </div>
      <div className="registerFormContainer">
        <p className="formHeading">Firmware Images</p>
        {loader ? (
          <div className="w-full flex items-center justify-center">
            <ClipLoader
              color={"#2047ab"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : imageList?.length !== 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5 mb-5">
            {imageList?.map((item: any, index: any) => {
              return (
                <>
                  {item.status === "ACTIVE" ? (
                    <div
                      key={index}
                      className="firmware_image_container"
                      onClick={(e: any) => openModal("firmwareModal", item, e)}
                    >
                      {item?.imageName?.split(".")[1] === "bin" ? (
                        <img src={image} className="w-full h-full" />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_URL}v1/getFirmware/${item?.imageName}`}
                          alt={item?.image}
                          className="w-full h-full"
                        />
                      ) }
                      <p className="imageName">{item?.imageName?.split(".")[0]}</p>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        ) : (
          <p className="sensorName text-center mt-6">No Data Found</p>
        )}
      </div>
      {modalIsOpen && modalName === "firmwareModal" ? (
        <ModalComponent heading="Firmware Image">
          <FirmwarePreviewImage
            getAllImages={getAllImage}
            imageData={imageData}
          />
        </ModalComponent>
      ) : (
        ""
      )}
    </>
  );
}
