import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import useRoleServiceHandler from "../../hooks/serviceHandler/roles";
import useRoleValidation from "../../hooks/validations/Role";
import { UpdateNewRoleInfo } from "../../redux/reducer/role";
import Label from "../common/formInput/Label";
import PermissionAccordian from "./PermissionAccordian";
import AddRoleCheckBox from "../common/formInput/AddRoleCheckBox";

export default function AddRole() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const { OnChangeRoleFormValueValidation, RoleFormValidation } =
    useRoleValidation();
  const {
    RoleServiceHandler,
    GetAllPermissionsServiceHandler,
    UpdateRoleServiceHandler,
  } = useRoleServiceHandler();

  const { newRole, RoleFormError } = useSelector((store: any) => store.role);
  const { permissionList } = useSelector((store: any) => store.permission);

  const handleRoute = (url: string) => {
    navigate(url);
  };

  const handleChange = (key: "name" | "description", value: string) => {
    dispatch(UpdateNewRoleInfo({ key, value }));
    OnChangeRoleFormValueValidation({ id: key, value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isValid = RoleFormValidation();
    if (isValid) {
      if (isEdit) {
        UpdateRoleServiceHandler();
      } else {
        RoleServiceHandler();
      }
    }
  };

  useEffect(() => {
    GetAllPermissionsServiceHandler();
    if (newRole?.id) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, []);

  return (
    <div className="screenContainerPadding">
      <p className="breadcrum">
        <span onClick={() => handleRoute(component.userListing.url)}>
          Role Listing
        </span>{" "}
        / <span>Add Role</span>
      </p>
      <div className="formContainerBorder">
        <form action="">
          <p className="formHeading">Add Role</p>
          <div className="grid gap-5">
            <div className="flex flex-col">
              <Label label="Role Name" required={true} />
              <input
                className="registerFormInput"
                type="text"
                name="name"
                id="name"
                value={newRole.name}
                placeholder="Enter Role"
                onChange={(e) => handleChange("name", e.target.value)}
              />
              <p className="errorMessage">{RoleFormError.name}</p>
            </div>

            <div className="flex flex-col">
              <Label label="Role Description" required={true} />
              <input
                className="registerFormInput"
                type="text"
                name="description"
                id="description"
                value={newRole.description}
                placeholder="Enter Role Description"
                onChange={(e) => handleChange("description", e.target.value)}
              />
              <p className="errorMessage">{RoleFormError.description}</p>
            </div>
            <div className="flex flex-col">
              <Label label="Role Permission" required={true} />
              <PermissionAccordian />
              <p className="errorMessage">{RoleFormError?.permissions}</p>
            </div>

            <div>
              <Label label="Notification Preference Setting" required={false} />
              <div className="flex flex-col md:flex-row gap-2">
                <AddRoleCheckBox
                  label={"Push Notification"}
                  value={newRole?.sendNotification}
                  id="sendNotification"
                />
                <AddRoleCheckBox
                  label={"Email Notification"}
                  value={newRole?.sendEmail}
                  id="sendEmail"
                />
                <AddRoleCheckBox
                  label={"Text Notification"}
                  value={newRole?.sendSMS}
                  id="sendSMS"
                />
              </div>
            </div>

            <div className="flex gap-5 justify-end items-center">
              <button className="primaryButtonFilled" onClick={handleSubmit}>
                {isEdit ? "Save Changes" : "Add Role"}
              </button>
              <button
                className="borderedButton"
                onClick={() => handleRoute(component.roleList.url)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
