import React, { useCallback, useEffect, useState } from "react";

import styled from "styled-components";
import { translateLastValue } from "../../constants/conversion";
import useDebouncedCallback from "../../hooks/sensorSetting/debouncedCallback";
import {
  FormControl,
  InputLabel,
  NativeSelect,
  Slider,
  TextField,
} from "@mui/material";
import Label from "../common/formInput/Label";

// const Wrapper = styled.div`
//   margin-top: 2rem;
// `;

const Heading = styled.h4`
  font-weight: normal;
  margin-bottom: 3rem;
  font-size: 12px;
  color: rgba(0 0 0 / 54%);
`;

const SliderWrapper = styled.div`
  padding: 0 0.5rem;
`;

const createOptions = ({ min, max, step }) => {
  const result = [];
  for (let i = min; i * step <= max; i++) {
    const value = i * step;
    result.push(
      <option key={value} value={value}>
        {value}
      </option>
    );
  }
  return result;
};

export default function SetPointSensorSettings({
  convertFromSetPoint: defaultConvertFromSetPoint,
  convertToSetPoint: defaultConvertToSetPoint,
  marks,
  max: defaultMax,
  min: defaultMin,
  step: defaultStep,
  title = "Alert Range",
  precision: defaultPrecision = 0,
  isTemperatureSensor = false,
}) {
  return function CarbonMonoxideSensorSettings({
    convertFromSetPoint: convertFromSetPointOverride,
    convertToSetPoint: convertToSetPointOverride,
    lowTripPoint = "0000",
    highTripPoint = "9999",
    onChange,
    onChangeCommitted,
    offset = 0,
    onOffsetChange,
    marks: marksOverride,
    max: maxOverride,
    min: minOverride,
    step: stepOverride,
    precision: precisionOverride = null,
    lastValue,
    units,
    temperaturePreference,
  }) {
    const precision = precisionOverride ?? defaultPrecision;
    const convertFromSetPoint =
      convertFromSetPointOverride || defaultConvertFromSetPoint;
    const convertToSetPoint =
      convertToSetPointOverride || defaultConvertToSetPoint;
    const min = minOverride || defaultMin;
    const max = maxOverride || defaultMax;
    const step = stepOverride || defaultStep;

    const [innerOffset, setInnerOffset] = useState<any>(`${offset}`);
    const [innerLowSetPoint, setInnerLowSetPoint] = useState(
      convertFromSetPoint(lowTripPoint).toFixed(precision)
    );
    const [innerHighSetPoint, setInnerHighSetPoint] = useState(
      convertFromSetPoint(highTripPoint).toFixed(precision)
    );

    useEffect(() => {
      setInnerOffset(offset);
    }, [offset]);
    useEffect(() => {
      setInnerLowSetPoint(convertFromSetPoint(lowTripPoint).toFixed(precision));
    }, [lowTripPoint, precision, convertFromSetPoint]);
    useEffect(() => {
      setInnerHighSetPoint(
        convertFromSetPoint(highTripPoint).toFixed(precision)
      );
    }, [highTripPoint, precision, convertFromSetPoint]);

    const buildChangeObject = useCallback(
      (v: any) => {
        return {
          lowTripPoint: convertToSetPoint(Math.min(...v)),
          highTripPoint: convertToSetPoint(Math.max(...v)),
        };
      },
      [convertToSetPoint]
    );

    const handleSliderChange = useCallback(
      (e: any, v: any) => {
        if (onChange) {
          onChange(buildChangeObject(v));
        }
      },
      [onChange, buildChangeObject]
    );

    const commitChange = useDebouncedCallback(
      (v: any) => {
        if (onChangeCommitted) {
          onChangeCommitted(buildChangeObject(v));
        }
      },
      500,
      [onChangeCommitted]
    );

    const handleInputChange = useCallback(
      (v: any) => {
        if (onChange) {
          onChange(buildChangeObject(v));
        }
        commitChange(v);
      },
      [onChange, commitChange, buildChangeObject, convertFromSetPoint]
    );

    const handleChangeCommitted = useCallback(
      (e: any, v: any) => {
        if (onChangeCommitted) {
          onChangeCommitted(buildChangeObject(v));
        }
      },
      [onChangeCommitted, buildChangeObject]
    );

    const handleLowChange = (e: any) => {
      const value = e.target.value;

      setInnerLowSetPoint(value);
      if (/[-+]?\d+/.test(value)) {
        handleInputChange([
          e.target.value,
          +convertFromSetPoint(highTripPoint).toFixed(precision),
        ]);
      }
    };

    const handleHighChange = (e: any) => {
      const value = e.target.value;
      setInnerHighSetPoint(value);
      if (/[-+]?\d+/.test(value)) {
        handleInputChange([
          e.target.value,
          +convertFromSetPoint(lowTripPoint).toFixed(precision),
        ]);
      }
    };

    const handleOffsetChange = useDebouncedCallback(
      (offset: any) => {
        onOffsetChange(offset);
      },
      500,
      [onOffsetChange]
    );

    return (
      <div>
        <Heading>{title}</Heading>
        <SliderWrapper>
          <Slider
            value={[
              +convertFromSetPoint(lowTripPoint).toFixed(precision),
              +convertFromSetPoint(highTripPoint).toFixed(precision),
            ]}
            onChange={handleSliderChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            marks={marksOverride || marks}
            step={step}
            min={min}
            max={max}
          />
        </SliderWrapper>
        <div className="grid grid-cols-2 gap-5">
          <div>
            <Label label="Lower Set Point" required={false} />
            <TextField
              className="sensorSettingInput"
              value={innerLowSetPoint}
              onChange={handleLowChange}
              type="number"
              InputProps={{ inputProps: { max, min, step } }}
            />
          </div>
          <div>
            <Label label="Upper Set Point" required={false} />
            <TextField
              className="sensorSettingInput"
              value={innerHighSetPoint}
              onChange={handleHighChange}
              type="number"
              InputProps={{ inputProps: { max, min, step } }}
            />
          </div>
          <div>
            <Label
              label={
                isTemperatureSensor
                  ? "Offset Adjustment (Celsius)"
                  : "Offset Adjustment"
              }
              required={false}
            />
            <TextField
              className="sensorSettingInput"
              value={innerOffset}
              onChange={(e) => {
                const value = e.target.value;
                setInnerOffset(value);
                if (/[-+]?\d+/.test(value)) {
                  handleOffsetChange(+value);
                }
              }}
              type="number"
              InputProps={{ inputProps: { max: 9999, min: -9999, step: 0.01 } }}
            />
          </div>
          <div>
            <Label label="Value w/ Offset Applied" required={false} />
            <TextField
              className="sensorSettingInput"
              disabled
              value={translateLastValue(
                offset,
                lastValue,
                units,
                temperaturePreference
              )}
              type="number"
            />
          </div>
        </div>
      </div>
    );
  };
}
