import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import useDevicesServiceHandler from "../../../hooks/serviceHandler/devices";
import { device } from "../../../interface/reducer/device";
import noRecord from "../../../assets/images/no-record-found.png";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../../redux/reducer/commonReducer";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { BiSolidArchiveIn } from "react-icons/bi";
import { MdUnarchive } from "react-icons/md";
import unarchiveIcon from "../../../assets/images/icons/unarchiveIcon.svg";
import archiveIcon from "../../../assets/images/icons/archiveIcon.svg";
import component from "../../../constants/urls";
import { useNavigate } from "react-router-dom";
import { UpdateSelectedDevice } from "../../../redux/reducer/devices";
import { Icon, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";

interface Column {
  id:
    | "Device Id"
    | "Model"
    | "Name"
    | "Subscription Plan"
    | "Assigned"
    | "Status"
    | "Action";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "Device Id", label: "Device Id", align: "right" },
  { id: "Model", label: "Model", align: "right" },
  { id: "Name", label: "Name", align: "right" },
  { id: "Subscription Plan", label: "Subscription Plan", align: "right" },
  { id: "Assigned", label: "Assigned", align: "right" },
  { id: "Status", label: "Status", align: "right" },
  { id: "Action", label: "Action", align: "right" },
];

export default function InventoryDeviceTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    GetDevicesListServiceHandler,
    GetAssignedDeviceInfoServiceHandler,
    ChangeDeviceActiveStatusSeriveHandler,
    ChangeDeviceArchiveStatusServiceHandler,
  } = useDevicesServiceHandler();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { devicesList } = useSelector((state: any) => state.device);
  const { userInfo } = useSelector((store: any) => store.user);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const openDetailModal = async (modalName: string, item: device) => {
    await GetAssignedDeviceInfoServiceHandler(item?.id);
    dispatch(UpdateModalName(modalName));
    dispatch(UpdateModalOpenStatus(true));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeDeviceStatus = async (item: device) => {
    await ChangeDeviceActiveStatusSeriveHandler(item.id);
    // await GetDevicesListServiceHandler();
  };

  const handleChangeDeviceArchiveStatus = async (item: device) => {
    await ChangeDeviceArchiveStatusServiceHandler(item.id);
    // await GetDevicesListServiceHandler();
  };

  const handleDeviceSensorList = (item: device) => {
    // dispatch(UpdateSelectedDevice(item));
    if (item.purchasedStatus) {
      navigate(component.sensorListing.url);
    } else {
      if (userInfo?.role === "Admin") {
        navigate(component.registerDevice.url);
      } else {
        navigate(component.sensorListing.url);
      }
    }
  };

  // React.useEffect(() => {
  //   GetDevicesListServiceHandler();
  // }, []);

  return (
    <>
      {devicesList?.length ? (
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer sx={{ minHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      // align={column.align}
                      style={{
                        backgroundColor: "#2047AB",
                        borderBottom: "none",
                      }}
                    >
                      <p className="tableText" style={{ color: "#ffffff" }}>
                        {column.label}
                      </p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {devicesList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: device, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="left"
                          onClick={() => openDetailModal("deviceDetail", row)}
                        >
                          <p
                            className="userTableData cursor-pointer"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.id}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {row?.model_name ? row.model_name : "NA"}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {row?.name ? row.name : "Unnamed Device"}
                          </p>
                        </TableCell>

                        <TableCell align="left">
                          <p className="userTableData">
                            Monthly -
                            {row.subscriptionMonthly
                              ? ` ${row.subscriptionMonthly}`
                              : "-"}
                          </p>
                          <p className="userTableData">
                            Yearly -
                            {row.subscriptionYearly
                              ? ` ${row.subscriptionYearly}`
                              : "-"}
                          </p>
                        </TableCell>

                        <TableCell align="left">
                          <p
                            className="userTableData"
                            style={{
                              color:
                                row?.assignAdmin === null
                                  ? "#3eeb2f"
                                  : "#d0342c",
                            }}
                          >
                            {row?.assignAdmin === null
                              ? "Available"
                              : "Assigned"}
                          </p>
                        </TableCell>

                        <TableCell align="left">
                          <p
                            className="userTableData"
                            style={{
                              color: row.active ? "#3eeb2f" : "#d0342c",
                            }}
                          >
                            {row.active ? "Active" : "Inactive"}
                          </p>
                        </TableCell>
                        {(userInfo.role === "Super Admin" ||
                          userInfo.role === "Admin") && (
                          <TableCell align="left" style={{ width: "100px" }}>
                            <div className="flex justify-between items-center">
                              <Tooltip
                                title={row.active ? "Active" : "Inactive"}
                              >
                                {row.active ? (
                                  <IconButton
                                    onClick={() =>
                                      handleChangeDeviceStatus(row)
                                    }
                                  >
                                    <BsFillExclamationTriangleFill
                                      fontSize={20}
                                      color="#3eeb2f"
                                      className="cursor-pointer"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      handleChangeDeviceStatus(row)
                                    }
                                  >
                                    <BsFillExclamationTriangleFill
                                      fontSize={20}
                                      color="#ed9418"
                                      className="cursor-pointer"
                                    />
                                  </IconButton>
                                )}
                              </Tooltip>
                              <Tooltip
                                title={
                                  row.status === "ARCHIVED"
                                    ? "Archive"
                                    : "Unarchive"
                                }
                              >
                                {row.status === "ARCHIVED" ? (
                                  <IconButton
                                    onClick={() =>
                                      handleChangeDeviceArchiveStatus(row)
                                    }
                                  >
                                    <BiSolidArchiveIn
                                      fontSize={20}
                                      color="#767676"
                                      className="cursor-pointer"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      handleChangeDeviceArchiveStatus(row)
                                    }
                                  >
                                    <MdUnarchive
                                      fontSize={20}
                                      color="#2047ab"
                                      className="cursor-pointer"
                                    />
                                  </IconButton>
                                )}
                              </Tooltip>
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={devicesList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <div className="no_record_found_image">
          <img src={noRecord} alt="no-record" />
        </div>
      )}
    </>
  );
}
