
export interface GetTimezoneResponse {
    timezone: string
}

export const GetTimezone = async (deviceId: string) : Promise<GetTimezoneResponse> => {
    const url = `${process.env.REACT_APP_URL}v1/timezone/${deviceId}`;
    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`Error Fetching timezone: ${response.statusText}`)
        }

        const data = await response.json();
        return Promise.resolve(data);
    } catch (error: any) {
        console.error(error.message);
        return Promise.reject(error);
    }
};

export const UpdateDeviceTimezone = async (deviceId: string, timezone: string): Promise<void> => {
    const url = `${process.env.REACT_APP_URL}v1/timezone/${deviceId}`;

    try {
        const response = await fetch(url, {
            method: "PATCH",
			headers: {
			  'Content-Type': 'application/json'
			},
            body: JSON.stringify({
              timezone: timezone
            })
        });

        if (!response.ok) {
            throw new Error(`An error occurred updating the timezone: ${response.statusText}`)
        }
    } catch (error: any) {
        console.error(error.message);
        return Promise.reject(error);
    }
};
