import React, { useEffect, useState } from "react";
import filterIcon from "../../assets/images/icons/filterIcon.svg";
import searchIcon from "../../assets/images/icons/searchIcon.svg";
import InventoryDeviceTable from "../common/table/InventoryDeviceTable";
import { useDispatch, useSelector } from "react-redux";
import component from "../../constants/urls";
import { useNavigate } from "react-router-dom";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import ModalComponent from "../common/modal/Modal";
import RegisterDeviceInputModal from "../common/modal/RegisterDeviceInputModal";
import AssignedDevice from "../common/modal/AssignedDevice";
import { device } from "../../interface/reducer/device";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";

const deviceState = [
  {
    value: "All Devices",
    query: "",
  },
  {
    value: "Active Devices",
    query: "active=1",
  },
  {
    value: "Inactive Devices",
    query: "active=0",
  },
  {
    value: "Alert Devices",
    query: "alerting=true",
  },
  {
    value: "Archive Devices",
    query: "status=ARCHIVED",
  },
];

export default function InventoryDeviceList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { GetFilteredDeviceListServiceHandler } = useDevicesServiceHandler();

  const { userInfo } = useSelector((store: any) => store.user);
  const { devicesList } = useSelector((store: any) => store.device);
  const { modalIsOpen, modalName } = useSelector((store: any) => store.common);
  const [filteredDevice, setFilteredDevice] = useState<Array<device>>([]);

  const handleRoute = (url: string) => {
    navigate(url);
  };

  const handleDeviceListFilter = async (query: string) => {
    await GetFilteredDeviceListServiceHandler(query);
  };
  useEffect(() => {
    handleDeviceListFilter("");
  }, []);

  return (
    <>
      {modalIsOpen && modalName === "registerDevice" ? (
        <ModalComponent heading="Register New Device">
          <RegisterDeviceInputModal />
        </ModalComponent>
      ) : modalIsOpen && modalName === "deviceDetail" ? (
        <ModalComponent heading="Assigned Device Details">
          <AssignedDevice />
        </ModalComponent>
      ) : (
        ""
      )}
      <div className="screenContainerPadding">
        <div className="flex justify-between">
          <p className="breadcrum">
            <span onClick={() => handleRoute(component.dashboard.url)}>
              Dashboard
            </span>{" "}
            /
            <span>
              {" "}
              {userInfo?.role === "Super Admin"
                ? "Device Inventory"
                : "Total Devices"}
            </span>
          </p>
          {userInfo?.role === "Super Admin" && (
            <button
              className="borderedButton"
              onClick={() => navigate(component.addDeviceInventory.url)}
            >
              Add Device
            </button>
          )}
        </div>
        <div className="registerFormContainer">
          <div className="flex justify-between items-center mb-5">
            <p className="formHeading">
              {userInfo?.role === "Super Admin"
                ? "Device Inventory"
                : "Total Devices"}
            </p>
            <div className="flex justify-end gap-5">
              {/* <div className="searchBox">
                <img src={searchIcon} alt="filter" className="icon" />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Search"
                  className="searchInput"
                />
              </div> */}
              <div className="searchBox">
                <img src={filterIcon} alt="filter" className="icon" />
                <select
                  onChange={(e: any) => handleDeviceListFilter(e.target.value)}
                  className="searchInput"
                >
                  {deviceState?.map((state, index) => (
                    <option key={index} value={state.query}>
                      {state?.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <InventoryDeviceTable />
        </div>
      </div>
    </>
  );
}
