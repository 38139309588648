import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import component from "../constants/urls";
import Images from "../components/firmware/Images";
import { useSelector } from "react-redux";
import ModalComponent from "../components/common/modal/Modal";
import FirmwarePreview from "../components/firmware/FirmwarePreview";

export default function PicturesImage() {
  const navigate = useNavigate();
  const { modalName, modalIsOpen } = useSelector((store: any) => store.common);
  const [imageData, setImageData] = useState({});
  const { selectedFolder } = useSelector((store: any) => store.firmware);
  // console.log('imageData',imageData);
  

  const handleRoute = (url: string) => {
    navigate(url);
  };

  return (
    <>
      <div className="screenContainerPadding">
        <p className="breadcrum">
          <span onClick={() => handleRoute(component.dashboard.url)}>
            Dashboard
          </span>{" "}
          / <span onClick={() => handleRoute(component.firmwareFolder.url)}>Picture</span>{" "}/ <span onClick={() => handleRoute(component.firmwareFolder.url + `/${selectedFolder?.folder}`)}>{selectedFolder?.folder}</span>
        </p>
        <div className="grid grid-cols-1 gap-5">
          <Images setImageData={setImageData} />
        </div>
        {modalIsOpen && modalName === "firmwareModal" ? (
          <ModalComponent heading="Firmware Image">
            <FirmwarePreview imageData={imageData} />
          </ModalComponent>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
