import React, { useEffect } from "react";
import { celsiusToFahrenheit } from "../../constants/temprature";
import SensorData from "../common/table/SensorData";
import { useSelector } from "react-redux";
import useSensorServiceHandler from "../../hooks/serviceHandler/sensor";
import { ClipLoader } from "react-spinners";

export default function SensorRawDataSection({
  deviceId,
  sensor,
  sensorType,
  sensorEsn,
  offset,
}) {
  const { temperaturePreference, sensorHistory, exportFileUrl } = useSelector(
    (store: any) => store.sensor
  );
  const { isLoader, rawSensorLoader } = useSelector(
    (store: any) => store.common
  );
  const { SensorHistoryServiceHandler, ExportSensorCSVDataServiceHandler } =
    useSensorServiceHandler();

  const downloadSensorCsv = async (e: any) => {
    e.preventDefault();
    const result = await ExportSensorCSVDataServiceHandler(sensor?.name, deviceId);
    // console.log('exportFileUrl',exportFileUrl);
    
    window.location.href = result;
  };

  useEffect(() => {
    SensorHistoryServiceHandler(deviceId, sensorType, sensorEsn);
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <p className="sensorHeading mb-3">Sensor Data</p>

        <button className="borderedButton" onClick={downloadSensorCsv}>
          {isLoader ? (
            <ClipLoader
              color={"2047ab"}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            "Download CSV"
          )}
        </button>
      </div>

      {rawSensorLoader ? (
        <div className="w-full flex items-center justify-center">
          <ClipLoader
            color={"#2047ab"}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <SensorData
          data={translateSensorData(
            sensorHistory || [],
            sensorType,
            temperaturePreference,
            offset
          )}
        />
      )}
    </div>
  );
}
function translateSensorData(
  data: any,
  sensorType: string,
  temperaturePreference: string,
  offset: any
) {
  if (sensorType === "01") {
    if (temperaturePreference === "F") {
      data = data.map((d: any) => ({
        ...d,
        data: celsiusToFahrenheit(d.data + offset),
      }));
    } else {
      data = data.map((d: any) => ({ ...d, data: d.data + offset }));
    }
    return data;
  } else if (sensorType === "00") {
    return data.map((d: any) => ({ ...d, data: d.data }));
  }
  return data.map((d: any) => ({ ...d, data: d.data + offset }));
}
