import axios from "axios";
import { Role } from "../interface/services/Role";
import { role } from "../interface/reducer/role";

export const GetAllRoleListService = async (id: string) => {
  const url = `${process.env.REACT_APP_URL}v1/roles/${id}`;
  return axios.get(url);
};
export const RoleService = async (role: Role) => {
  const url = `${process.env.REACT_APP_URL}v1/role`;
  return axios.post(url, role);
};
export const GetAllPermissionsListService = () => {
  const url = `${process.env.REACT_APP_URL}v1/permissions`;
  return axios.get(url);
};

export const UpdateRoleService = async (role: role) => {
  const url = `${process.env.REACT_APP_URL}v1/role/${role.id}`;
  return axios.patch(url, role);
};
export const DeleteRoleService = async (roleId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/role/${roleId}`;
  return axios.delete(url);
};
