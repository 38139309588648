import { useDispatch, useSelector } from "react-redux";
import { GetHistoryAlertListService } from "../../service/history";
import swal from "sweetalert";
import handleInvalidToken from "../../service/InvalidTokenHandler";

const useHistory = () => {
  const { userInfo } = useSelector((store: any) => store.user);

  const dispatch = useDispatch();

  const getAllHistory = async (currentPage: number, search: string) => {
    try {
     
      const userId = userInfo?.id
      const res = await GetHistoryAlertListService({userId, currentPage, search});
      return res?.data?.result;
    } catch (error) {
      handleInvalidToken(error, dispatch);
    }
  };

  return {
    getAllHistory,
  };
};
export default useHistory;
