const component = {
  home: { url: "/" },
  login: { url: "/login" },
  register: { url: "/register" },
  forgetPassword: { url: "/forget-password" },
  dashboard: { url: "/dashboard" },
  deviceState: {
    all: "/inventory-device",
    total: "/total-devices",
    active: "/devices",
    alert: "/",
    inactive: "/",
  },
  devices: { url: "/devices" },
  history: { url: "/history" },
  historySensor: { url: "/history/sensor" },
  createUser: { url: "/add-user" },
  userListing: { url: "/user-listing" },
  firmware: { url: "/firmware" },
  firmwareFolder: { url: "/picture-folders" },
  folder: {url: "/picture-folders/:folderName"},
  firmwareImage: { url: "/firmware-images" },
  account: { url: "/account" },
  faq: { url: "/faq" },
  sensorListing: { url: "/sensors" },
  logout: { url: "/logout" },
  registerDevice: { url: "/register-device" },
  manageDevices: { url: "/manage-devices" },
  archiveDevice: { url: "/archive-device" },
  roleList: { url: "/role" },
  createRole: { url: "/add-role" },
  resetPassword: { url: "/reset-password" },
  inventoryDeviceList: { url: "/inventory-device" },
  addDeviceInventory: { url: "/add-device" },
  totalDevices: { url: "/total-devices" },
  notification: { url: "/notification" },
  sensorDetailPage: {
    url: "/devices/:deviceId/sensors/:sensorType/:sensorEsn",
  },
  logs: { url: "/logs" },
};

export default component;
