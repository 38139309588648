
import { Dispatch, AnyAction } from "redux"
import swal from "sweetalert"
import { ResetUserInfo, UpdateIsLoginState } from "../redux/reducer/user";

function handleInvalidToken(error: any, dispatch: Dispatch<AnyAction>) {
    swal({      
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      }).then((value) => {
        dispatch(UpdateIsLoginState(false));
        dispatch(ResetUserInfo());
      });
}

export default handleInvalidToken;