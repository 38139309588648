import React from "react";
import { useNavigate } from "react-router-dom";
import component from "../constants/urls";
import AllHistoryData from "../components/common/table/AllHistoryData";

export default function HistoryAlert() {
  const navigate = useNavigate();

  return (
    <div className="screenContainerPadding">
      <p className="breadcrum">
        <span onClick={() => navigate(component.dashboard.url)}>Dashboard</span>{" "}
        / <span>History</span>
      </p>
      <AllHistoryData />
    </div>
  );
}
