import * as React from "react";
import Label from "../common/formInput/Label";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateDrawerExpand,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import {
  UpdateSensorSettingErrorMessage,
  UpdateSensorSettingSuccessMessage,
} from "../../redux/reducer/sensors";
import useSensorServiceHandler from "../../hooks/serviceHandler/sensor";
import { useEffect, useState, useCallback, useMemo } from "react";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import "react-input-range/lib/css/index.css";
import SliderComponent from "./SliderComponent";
import { SliderEndPoint, sliderValue } from "../../constants/sliderRange";
import { Analog, toSetPoint } from "../../constants/conversion";
import useSnackbarState from "../../constants/snackBarState";
import { GENERIC_ANALOG_SENSORS } from "../../constants/constant";
import AnalogLineSensorSettings from "../sensorSetting/AnalogLineSensorSetting";
import TemperatureSensorSettingsFahrenheit from "../sensorSetting/TemperatureSensorSettingsFahrenheit";
import TemperatureSensorSettings from "../sensorSetting/TemperatureSensorSettings";
import HumiditySensorSettings from "../sensorSetting/HumiditySensorSettings";
import VoltageSensorSettings from "../sensorSetting/VoltageSensorSettings";
import CurrentSensorSettings from "../sensorSetting/CurrentSensorSettings";
import OtherGasSensorSettings from "../sensorSetting/OtherGasSensorSettings";
import BatterySensorSettings from "../sensorSetting/BatterySensorSetting";
import SensorEnabledSetting from "../sensorSetting/SensorEnabledSetting";
import SensorNameSetting from "../sensorSetting/SensorNameSetting";
import SensorOutputLineTriggerSettings from "../sensorSetting/SensorOutputLineTriggerSettings";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import SaveOrDiscardSensorSettings from "../sensorSetting/SaveOrDiscardSensorSettings";
import deepEqual from "deep-equal";
import { ToastContainer } from "react-toastify";

const getSensorSettingsComponent = (
  sensorType: string,
  temperaturePreference: string
) => {
  if (GENERIC_ANALOG_SENSORS.includes(sensorType)) {
    return AnalogLineSensorSettings;
  }

  switch (sensorType) {
    case "01":
      if (temperaturePreference === "F") {
        return TemperatureSensorSettingsFahrenheit;
      } else {
        return TemperatureSensorSettings;
      }
    case "02":
      return HumiditySensorSettings;
    case "08":
      return VoltageSensorSettings;
    case "09":
      return CurrentSensorSettings;
    case "10":
    case "11":
    case "12":
    case "13":
    case "14":
    case "15":
      return OtherGasSensorSettings;
    case "16":
      return BatterySensorSettings;
    default:
      return null;
  }
};

const isAnalogSensor = (type: string) => {
  if (GENERIC_ANALOG_SENSORS.includes(type)) {
    return true;
  } else {
    return false;
  }
};

const clone = (value: any) => JSON.parse(JSON.stringify(value));

export default function SensorSetting({
  sensor: originalSensor,
  onSensorChange,
  offset,
  onOffsetChange,
  supportsOutputLines,
  outputLineNames,
}) {
  const dispatch = useDispatch();

  const {
    UpdateSensorSettingServiceHandler,
    sensorIconListServiceHandler,
    GetDeviceSensorListServiceHandler,
  } = useSensorServiceHandler();

  const { sensorIconList, temperaturePreference } = useSelector(
    (state: any) => state.sensor
  );

  const { sensorCurrentPage } = useSelector((store: any) => store.sensor);

  const [sensor, setSensor] = useState(clone(originalSensor));
  const [saving, setSaving] = useState(false);
  const [innerLowValue, setInnerLowValue] = useState(
    sensor.analog_low_value ?? "0"
  );
  const [innerHighValue, setInnerHighValue] = useState(
    sensor.analog_high_value ?? "9999"
  );

  useEffect(
    () => setInnerLowValue(sensor.analog_low_value ?? "0"),
    [sensor.analog_low_value]
  );
  useEffect(
    () => setInnerHighValue(sensor.analog_high_value ?? "9999"),
    [sensor.analog_high_value]
  );

  const [
    successMessageOpen,
    openSuccessMessageSnackbar,
    closeSuccessMessageSnackbar,
  ] = useSnackbarState();

  const handleTripPointChange = useCallback(
    (tripPoints: any) => {
      setSensor({
        ...sensor,
        low_trip_point: tripPoints.lowTripPoint,
        high_trip_point: tripPoints.highTripPoint,
      });
    },
    [setSensor, sensor]
  );

  const handleNameChange = useCallback(
    (name: any) => setSensor({ ...sensor, name }),
    [sensor, setSensor]
  );

  const handleIconSelect = useCallback(
    (icon: any) => setSensor({ ...sensor, image_url: icon }),
    [sensor, setSensor]
  );

  const handleEnabledChange = useCallback(
    (_: any, enabled: any) => setSensor({ ...sensor, enabled }),
    [sensor, setSensor]
  );

  const handleArmedChange = useCallback(
    (_: any, armed: any) => setSensor({ ...sensor, armed }),
    [sensor, setSensor]
  );

  const handleOffsetChange = useCallback(
    (offset: any) => {
      setSensor({ ...sensor, manual_offset: offset });
      onOffsetChange(offset);
    },
    [onOffsetChange, setSensor, sensor]
  );

  const handleAnalogLowPointChange = useCallback(
    (e: any) => setSensor({ ...sensor, analog_low_point: +e.target.value }),
    [sensor, setSensor]
  );

  const handleAnalogHighPointChange = useCallback(
    (e: any) => setSensor({ ...sensor, analog_high_point: +e.target.value }),
    [sensor, setSensor]
  );

  const handleAnalogLowValueChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      setInnerLowValue(value);
      if (/[-+]?\d+/.test(value)) {
        setSensor({ ...sensor, analog_low_value: +value });
      }
    },
    [sensor, setSensor]
  );

  const handleAnalogHighValueChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      setInnerHighValue(value);
      if (/[-+]?\d+/.test(value)) {
        setSensor({ ...sensor, analog_high_value: +value });
      }
    },
    [sensor, setSensor]
  );

  const handleAnalogUnitsChange = useCallback(
    (e: any) => setSensor({ ...sensor, analog_unit: e.target.value }),
    [sensor, setSensor]
  );

  const handleTriggerOutputLineChange = useCallback(
    (e: any) => setSensor({ ...sensor, trigger_output_line: +e.target.value }),
    [sensor, setSensor]
  );

  const SensorSettingsComponent: any = getSensorSettingsComponent(
    sensor.type,
    temperaturePreference
  );

  const handleSaveSensorSettingsClick = useCallback(async () => {
    setSaving(true);
    await UpdateSensorSettingServiceHandler(
      sensor?.device_id,
      sensor?.type,
      sensor?.esn,
      sensor
    );
    setSaving(false);
    onSensorChange(sensor);
    GetDeviceSensorListServiceHandler(sensor?.device_id, sensorCurrentPage);
  }, [sensor, setSaving, onSensorChange, openSuccessMessageSnackbar]);

  const handleDiscardSensorSettingsClick = useCallback(() => {
    setSensor(clone(originalSensor));
    dispatch(UpdateModalOpenStatus(false));
    dispatch(UpdateDrawerExpand(false));
  }, [originalSensor, setSensor]);

  const convertToSetPoint = useMemo(
    () => Analog(sensor).toSetPoint,
    [
      sensor.analog_low_value,
      sensor.analog_low_point,
      sensor.analog_high_value,
      sensor.analog_high_point,
    ]
  );

  const convertFromSetPoint = useMemo(
    () => Analog(sensor).fromSetPoint,
    [
      sensor.analog_low_value,
      sensor.analog_low_point,
      sensor.analog_high_value,
      sensor.analog_high_point,
    ]
  );

  useEffect(() => {
    sensorIconListServiceHandler();
  }, []);

  return (
    <>
      <div className="registerFormContainer mb-5">
        <form action="">
          <p className="formHeading">Edit Sensor Setting</p>

          <div className="grid gap-5">
            <SensorEnabledSetting
              armed={sensor?.armed}
              enabled={sensor?.enabled}
              onEnabledChange={handleEnabledChange}
              onArmedChange={handleArmedChange}
            />
            <SensorNameSetting
              name={sensor?.name}
              onNameChange={handleNameChange}
            />
            {SensorSettingsComponent && (
              <SensorSettingsComponent
                lowTripPoint={sensor?.low_trip_point ?? 0}
                highTripPoint={sensor?.high_trip_point ?? 9999}
                onChange={handleTripPointChange}
                offset={offset}
                onOffsetChange={handleOffsetChange}
                marks={computeAnalogSensorMarks(sensor)}
                min={computeAnalogSensorMin(sensor)}
                max={computeAnalogSensorMax(sensor)}
                step={computeAnalogSensorStep(sensor)}
                convertToSetPoint={
                  isAnalogSensor(sensor?.type) ? convertToSetPoint : undefined
                }
                convertFromSetPoint={
                  isAnalogSensor(sensor?.type) ? convertFromSetPoint : undefined
                }
                precision={computePrecision(sensor)}
                lastValue={sensor?.last_value}
                units={sensor?.units}
                temperaturePreference={temperaturePreference}
              />
            )}
            {supportsOutputLines && sensor?.type !== "31" && (
              <SensorOutputLineTriggerSettings
                onChange={handleTriggerOutputLineChange}
                value={sensor?.trigger_output_line ?? -1}
                outputLineNames={outputLineNames}
              />
            )}
            {isAnalogSensor(sensor?.type) && (
              <>
                <h2 className="formHeading">Sensor Calibration</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div>
                    <Label
                      label="Low Analog Calibration Point"
                      required={false}
                    />
                    <TextField
                      className="sensorSettingInput"
                      value={sensor?.analog_low_point ?? "0"}
                      onChange={handleAnalogLowPointChange}
                      type="number"
                      InputProps={{ inputProps: { max: 9999, min: 0 } }}
                    />
                  </div>
                  <div>
                    <Label
                      label="High Analog Calibration Point"
                      required={false}
                    />
                    <TextField
                      className="sensorSettingInput"
                      value={sensor?.analog_high_point ?? "9999"}
                      onChange={handleAnalogHighPointChange}
                      type="number"
                      InputProps={{ inputProps: { max: 9999, min: 0 } }}
                    />
                  </div>
                  <div>
                    <Label label="Low Analog Value" required={false} />
                    <TextField
                      className="sensorSettingInput"
                      value={innerLowValue}
                      onChange={handleAnalogLowValueChange}
                      type="number"
                      InputProps={{ inputProps: { step: 0.01 } }}
                    />
                  </div>
                  <div>
                    <Label label="High Analog Value" required={false} />
                    <TextField
                      className="sensorSettingInput"
                      value={innerHighValue}
                      onChange={handleAnalogHighValueChange}
                      type="number"
                      InputProps={{ inputProps: { step: 0.01 } }}
                    />
                  </div>
                  <div>
                    <Label label="Units" required={false} />
                    <TextField
                      className="sensorSettingInput"
                      value={sensor?.analog_unit ?? ""}
                      onChange={handleAnalogUnitsChange}
                      type="text"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="mt-4">
              <p className="formHeading">Sensor Icon</p>
              <div className="flex items-center flex-wrap gap-4">
                {sensorIconList?.map((icon: any, index: any) => (
                  <div className="deviceSensorIcon" key={index}>
                    <img
                      src={icon?.image_url}
                      alt="location"
                      className={
                        sensor?.image_url === icon?.image_url
                          ? "sensorIcons selected"
                          : "sensorIcons"
                      }
                      onClick={() => handleIconSelect(icon?.image_url)}
                    />
                  </div>
                ))}
              </div>
            </div>
            {!deepEqual(sensor, originalSensor) && (
              <SaveOrDiscardSensorSettings
                saving={saving}
                onSaveClick={handleSaveSensorSettingsClick}
                onDiscardClick={handleDiscardSensorSettingsClick}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
}

function computeAnalogSensorMarks(sensor: any) {
  if (isAnalogSensor(sensor.type) && analogSensorHasValuesSet(sensor)) {
    return [
      {
        value: sensor.analog_low_value,
        label: `${sensor.analog_low_value}${sensor.analog_unit}`,
      },
      {
        value: sensor.analog_high_value,
        label: `${sensor.analog_high_value}${sensor.analog_unit}`,
      },
    ];
  }
  return undefined;
}

function computeAnalogSensorMin(sensor: any) {
  if (isAnalogSensor(sensor.type) && analogSensorHasValuesSet(sensor)) {
    return sensor.analog_low_value;
  }
  return undefined;
}

function computeAnalogSensorMax(sensor: any) {
  if (isAnalogSensor(sensor.type) && analogSensorHasValuesSet(sensor)) {
    return sensor.analog_high_value;
  }
  return undefined;
}

function computeAnalogSensorStep(sensor: any) {
  if (isAnalogSensor(sensor.type) && analogSensorHasValuesSet(sensor)) {
    const diff = Math.abs(sensor.analog_high_value - sensor.analog_low_value);
    if (diff > 1000) {
      return (Math.ceil(Math.log10(diff)) - 3) * 10;
    } else if (diff > 100) {
      return 1;
    } else if (diff > 50) {
      return 0.5;
    } else if (diff > 10) {
      return 0.1;
    } else {
      return 0.01;
    }
  }
  return undefined;
}

function analogSensorHasValuesSet(sensor: any) {
  return (
    sensor.analog_low_value != null &&
    sensor.analog_high_value != null &&
    sensor.analog_low_point != null &&
    sensor.analog_high_point != null &&
    sensor.analog_unit != null
  );
}

function computePrecision(sensor: any) {
  if (isAnalogSensor(sensor.type) && analogSensorHasValuesSet(sensor)) {
    const diff = Math.abs(sensor.analog_high_value - sensor.analog_low_value);
    if (diff > 100) {
      return 0;
    } else if (diff > 10) {
      return 1;
    } else {
      return 2;
    }
  }
  return undefined;
}
