import { useSelector } from "react-redux";
import Footer from "../components/common/footer/Footer";
import AuthRoute from "./AuthRoute";
import UnAuthRoute from "./UnAuthRoute";
import HeaderNew from "../components/common/header/HeaderNew";
import SidebarOld from "../components/common/sidebar/SidebarOld";

export default function Navigation() {
  const { isLogin } = useSelector((state: any) => state.user);

  return (
    <>
      {isLogin ? (
        <>
          <div className="flex">
            <div className="sidebarContainer">
              <SidebarOld />
            </div>
            <div className="screensContainer">
              <HeaderNew />
              <AuthRoute />
              <Footer />
            </div>
          </div>
        </>
      ) : (
        <>
          <UnAuthRoute />
        </>
      )}
    </>
  );
}
