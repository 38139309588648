import * as React from "react";
import "./drawer.css";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateDrawerExpand,
  UpdateModalOpenStatus,
} from "../../../redux/reducer/commonReducer";
import { RiCloseCircleFill } from "react-icons/ri";
import { DialogTitle, IconButton, Tooltip } from "@mui/material";

type Anchor = "top" | "left" | "bottom" | "right";
interface drawerInterface {
  children: React.ReactNode;
  direction: Anchor;
}

export default function DrawerComponent(props: drawerInterface) {
  const dispatch = useDispatch();

  const { drawerExpand } = useSelector((state: any) => state.common);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      dispatch(UpdateDrawerExpand(false));
      // dispatch(UpdateTemperaturePreference("C"));
      dispatch(UpdateModalOpenStatus(false));
    };

  return (
    <>
      <Drawer
        anchor={props.direction}
        open={drawerExpand}
        onClose={toggleDrawer(props.direction, false)}
      >
        <Tooltip className="drawerTitle" title="Close">
          <IconButton onClick={toggleDrawer(props.direction, false)}>
            <RiCloseCircleFill fontSize={30} color="#767676" />
          </IconButton>
        </Tooltip>
        {props.children}
      </Drawer>
    </>
  );
}
