import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ForgetPassword from "../components/auth/ForgetPassword";
import Page404 from "../components/common/pageNotFound/page404";
import component from "../constants/urls";
import Login from "../pages/LoginPage";
import Registration from "../pages/Registration";
import ResetPassword from "../pages/ResetPassword";
import SensorDetailPage from "../components/sensor/SensorDetailsPage";

export default function UnAuthRoute() {
  return (
    <Routes>
      <Route path={component.home.url} element={<Navigate to="login" />} />
      <Route path={component.login.url} element={<Login />} />
      <Route path={component.register.url} element={<Registration />} />
      <Route path={component.forgetPassword.url} element={<ForgetPassword />} />
      <Route path={component.resetPassword.url} element={<ResetPassword />} />
      <Route
        path={component.sensorDetailPage.url}
        element={<SensorDetailPage />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
