import axios from "axios";
import {
  AddDeviceParams,
  RegisterDeviceParams,
} from "../interface/services/Device";
import { DeviceSetting } from "../interface/reducer/device";
import { getAuthHeader } from "../constants/constant";

export const GetDevicesListService = async (userId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/device/${userId}`;
  return axios.get(url);
};

export const GetDelegatedDevicesListService = async () => {
  const url = `${process.env.REACT_APP_URL}delegate/devices`;
  return axios.get(url);
};

export const DeleteDeviceService = async (userId: string, deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/device/${userId}/${deviceId}`;
  return axios.delete(url, getAuthHeader());
};

export const GetArchiveDevicesListService = async () => {
  const url = `${process.env.REACT_APP_URL}v1/archived/devices`;
  return axios.get(url);
};

export const RegisterNewDeviceService = (data: RegisterDeviceParams) => {
  const url = `${process.env.REACT_APP_URL}v1/registerNewDevice`;
  return axios.post(url, data);
};

export const AddDeviceInventoryService = (
  token: string,
  data: AddDeviceParams
) => {
  const url = `${process.env.REACT_APP_URL}v1/superAdmin/addDevice`;
  return axios.post(url, data, getAuthHeader());
};

// export const GetConnectedDeviceListService = async (userId: string) => {
//   const url = `${process.env.REACT_APP_URL}v1/getDevicesByUserId/${userId}`;
//   return axios.get(url);
// };

export const GetDeviceByIdService = async (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/deviceById/${deviceId}`;
  return axios.get(url, getAuthHeader());
};

export const ChangeDeviceActiveStateSerice = (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}web/v1/active/devices/${deviceId}`;
  return axios.patch(url, "", getAuthHeader());
};

export const ToggleArchiveStatusService = (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}web/v1/changetoggleArchiveStatus/${deviceId}`;
  return axios.patch(url);
};
export const UpdateDeviceSettingService = (
  deviceId: string,
  deviceSetting: any
) => {
  const url = `${process.env.REACT_APP_URL}v1/device/${deviceId}`;
  return axios.patch(url, deviceSetting);
};

export const UploadDeviceCsvFieService = (file: any) => {
  const url = `${process.env.REACT_APP_URL}v1/upload`;
  return axios.post(url, file);
};

export const CheckValidDeviceService = (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/AddAdminDevice/${deviceId}`;
  return axios.post(url);
};
export const GetDeviceInfoByIdService = (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}/web/v1/device/${deviceId}`;
  return axios.get(url);
};
export const GetAssignedDeviceInfoService = (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/deviceAssign/${deviceId}`;
  return axios.get(url, getAuthHeader());
};
export const SendDeviceMessageInfoService = (deviceId: string, data: any) => {
  const url = `${process.env.REACT_APP_URL}v1/device/send/${deviceId}`;
  return axios.post(url, data);
};
export const UpdateDeviceGPSService = (
  deviceId: string,
  deviceSetting: DeviceSetting
) => {
  const url = `${process.env.REACT_APP_URL}v1/updateGps/${deviceId}`;
  return axios.patch(url, deviceSetting, getAuthHeader());
};
export const FilterDeviceListService = (query: any) => {
  const url = `${process.env.REACT_APP_URL}v1/searchDevice?${query}`;
  return axios.get(url);
};
