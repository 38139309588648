import { useDispatch, useSelector } from "react-redux";
import { NAME_REGEX } from "../../constants/constant";
import {
  ResetNewRoleError,
  UpdateNewRoleFormError,
} from "../../redux/reducer/role";

const useRoleValidation = () => {
  const dispatch = useDispatch();

  const { newRole } = useSelector((store: any) => store.role);

  const RoleFormValidation = () => {
    if (newRole?.name?.trim() === "") {
      dispatch(ResetNewRoleError());
      dispatch(UpdateNewRoleFormError({ key: "name", value: "Required !" }));
      return false;
    } else if (newRole?.description?.trim() === "") {
      dispatch(ResetNewRoleError());
      dispatch(
        UpdateNewRoleFormError({ key: "description", value: "Required !" })
      );
      return false;
    } else if (!newRole?.permissions?.length) {
      dispatch(ResetNewRoleError());
      dispatch(
        UpdateNewRoleFormError({ key: "permissions", value: "Required !" })
      );
      return false;
    } else {
      dispatch(ResetNewRoleError());
      return true;
    }
  };

  const OnChangeRoleFormValueValidation = ({
    id,
    value,
  }: {
    id: "name" | "description" | "permissions";
    value: string;
  }) => {
    if (id === "name" && value?.trim() === "") {
      dispatch(ResetNewRoleError());
      dispatch(UpdateNewRoleFormError({ key: "name", value: "Required !" }));
      return false;
    } else if (id === "description" && value?.trim() === "") {
      dispatch(ResetNewRoleError());
      dispatch(
        UpdateNewRoleFormError({ key: "description", value: "Required !" })
      );
      return false;
    } else {
      dispatch(ResetNewRoleError());
      return true;
    }
  };
  return {
    RoleFormValidation,
    OnChangeRoleFormValueValidation,
  };
};

export default useRoleValidation;
