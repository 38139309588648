import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateModalOpenStatus } from "../../../redux/reducer/commonReducer";
import useDevicesServiceHandler from "../../../hooks/serviceHandler/devices";
import component from "../../../constants/urls";

export default function DeleteDeviceModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { DeleteDeviceServiceHandler } = useDevicesServiceHandler();

  const deleteDeviceBtnClick = async () => {
    navigate(component.devices.url);
    dispatch(UpdateModalOpenStatus(false));
    await DeleteDeviceServiceHandler();
  };

  const handleCancelButton = () => {
    dispatch(UpdateModalOpenStatus(false));
  };

  return (
    <>
      <div className="">
        <p className="deleteMessage">Warning: Permanent Delete! Confirm?</p>
        <div className="flex items-center justify-center gap-5">
          <button
            className="primaryButtonFilled"
            onClick={deleteDeviceBtnClick}
          >
            Sure
          </button>
          <button className="borderedButton" onClick={handleCancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
