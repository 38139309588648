import { useDispatch, useSelector } from "react-redux";
import {
  ResetUpdateNewDeviceFormError,
  UpdateNewDeviceFormError,
  newDeviceData,
} from "../../redux/reducer/devices";
import { NUMBER_ONLY_REGEX, NUMBER_REGEX } from "../../constants/constant";

const useNewDeviceRegisterFormValidation = () => {
  const { newDevice } = useSelector((store: any) => store.device);
  const dispatch = useDispatch();

  const Validation = () => {
    if (!newDevice?.deviceId?.trim()) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({ key: "deviceId", value: "Required !" })
      );
      return false;
    } else if (newDevice?.deviceId?.length > 20) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "deviceId",
          value: "Device Id can not be more than 20 digits",
        })
      );
      return false;
    } else if (!NUMBER_ONLY_REGEX.test(newDevice?.deviceId)) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "deviceId",
          value: "Device id should be integer only !",
        })
      );
    } else if (!newDevice?.model_definition?.trim()) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "model_definition",
          value: "Required !",
        })
      );
      return false;
    } else if (!newDevice?.subscriptionMonthly?.trim()) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "subscriptionMonthly",
          value: "Required !",
        })
      );
      return false;
    } else if (!newDevice?.subscriptionYearly?.trim()) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "subscriptionYearly",
          value: "Required !",
        })
      );
      return false;
    } else {
      dispatch(ResetUpdateNewDeviceFormError());
      return true;
    }
  };

  const OnChangeTextValidation = (data: newDeviceData) => {
    if (data.key === "deviceId" && !data?.value?.trim()) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({ key: "deviceId", value: "Required !" })
      );
      return false;
    } else if (data.key === "deviceId" && !NUMBER_ONLY_REGEX.test(data.value)) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "deviceId",
          value: "Device Id should be integer only !",
        })
      );
    } else if (data.key === "deviceId" && data?.value?.length > 20) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "deviceId",
          value: "Device Id can not be more than 20 digits",
        })
      );
      return false;
    } else if (data.key === "model_definition" && !data?.value?.trim()) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "model_definition",
          value: "Required !",
        })
      );
      return false;
    } else if (
      data.key === "subscriptionMonthly" &&
      !NUMBER_REGEX.test(data.value)
    ) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "subscriptionMonthly",
          value: "Enter valid subscription amount ",
        })
      );
      return false;
    } else if (
      data.key === "subscriptionYearly" &&
      !NUMBER_REGEX.test(data.value)
    ) {
      dispatch(ResetUpdateNewDeviceFormError());
      dispatch(
        UpdateNewDeviceFormError({
          key: "subscriptionYearly",
          value: "Enter valid subscription amount ",
        })
      );
      return false;
    } else {
      dispatch(ResetUpdateNewDeviceFormError());
      return true;
    }
  };

  return {
    Validation,
    OnChangeTextValidation,
  };
};

export default useNewDeviceRegisterFormValidation;
