import * as React from "react";
import "../assets/css/dashboard.css";
import DeviceTable from "../components/dashboard/DevicesTable";
import DashboardCountCards from "../components/dashboard/DashboardCountCards";
import MapView from "../components/dashboard/MapView";
import searchIcon from "../assets/images/icons/searchIcon.svg";
import filterIcon from "../assets/images/icons/filterIcon.svg";
import { useNavigate } from "react-router-dom";
import component from "../constants/urls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDevicesServiceHandler from "../hooks/serviceHandler/devices";
import { device } from "../interface/reducer/device";
import { RxCross2 } from "react-icons/rx";
import useNotificationServiceHandler from "../hooks/serviceHandler/notification";
import { UpdateSelectedDevice } from "../redux/reducer/devices";

const deviceState = [
  {
    key: "all",
    value: "All Devices",
  },
  {
    key: "active",
    value: "Active Devices",
  },
  {
    key: "inactive",
    value: "Inactive Devices",
  },
  {
    key: "alert",
    value: "Alert Devices",
  },
];
export default function Landing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { devicesList } = useSelector((store: any) => store.device);

  const { GetDevicesListServiceHandler } = useDevicesServiceHandler();
  const { GetAllNotificationDataServiceHandler } =
    useNotificationServiceHandler();
  const [deviceList, setDeviceList] = useState<Array<device>>([]);
  const [selectedState, setSelectedState] = useState("all");

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [mapKey, setMapKey] = useState(1);

  const { userInfo } = useSelector((store: any) => store.user);

  const dyaminHeading = (selectedState: string) => {
    if (selectedState === "all") {
      return "All Devices";
    } else if (selectedState === "active") {
      return "Active Devices";
    } else if (selectedState === "inactive") {
      return "Inactive Devices";
    } else if (selectedState === "alert") {
      return "Alert Devices";
    } else {
      return "Devices";
    }
  };
  const GetFilteredDeviceList = () => {
    let newList: any = [];
    if (userInfo?.role === "Super Admin") {
      if (selectedState === "active") {
        newList = devicesList?.filter((device: device) => device?.active);
      } else if (selectedState === "inactive") {
        newList = devicesList?.filter((device: device) => !device?.active);
      } else if (selectedState === "alert") {
        newList = devicesList?.filter((device: device) => device?.alerting);
      } else {
        newList = [...devicesList];
      }
      setDeviceList([...newList]);
    } else {
      if (selectedState === "active") {
        newList = devicesList?.filter(
          (device: device) => device?.active && device?.status !== "UNARCHIVED"
        );
      } else if (selectedState === "inactive") {
        newList = devicesList?.filter(
          (device: device) => !device?.active && device?.status !== "UNARCHIVED"
        );
      } else if (selectedState === "alert") {
        newList = devicesList?.filter(
          (device: device) => device?.alerting && device?.status !== "UNARCHIVED"
        );
      } else {
        newList = devicesList?.filter(
          (device: device) => device?.active && device?.status !== "UNARCHIVED"
        );
      }
      setDeviceList([...newList]);
    }
  };

  const searchFilterFunction = (text: string) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource and update FilteredDataSource
      const newData = devicesList.filter((data: device) =>
        data?.name?.toLowerCase().includes(text.toLowerCase())
      );
      setData(newData);
      setDeviceList([...newData]);
      setSearch(text);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      //   setFilteredDataSource(masterDataSource);
      setData([]);
      // handleFilterClick(selectedDeviceState);
      setSearch(text);
    }
  };
  const handleDeviceSensorList = (item: device) => {
    dispatch(UpdateSelectedDevice(item));
    if (item.purchasedStatus) {
      navigate(component.sensorListing.url);
    } else {
      if (userInfo?.role === "Admin") {
        navigate(component.registerDevice.url);
      } else {
        navigate(component.sensorListing.url);
      }
    }
  };
  const getItem = (item: any) => {
    handleDeviceSensorList(item);
    setSearch(item.name);
    setDeviceList([item]);
    setData([]);
  };

  const clearSearchInput = () => {
    setSearch("");
    setData([]);
    GetFilteredDeviceList();
    // handleFilterClick(selectedDeviceState);
  };

  useEffect(() => {
    GetDevicesListServiceHandler();
    GetAllNotificationDataServiceHandler();
  }, []);

  useEffect(() => {
    GetFilteredDeviceList();
  }, [selectedState, devicesList]);

  return (
    <>
      <div className="screenContainerPadding dashboardContainer">
        <p className="welcomeTextDashboard">
          {`Welcome ${userInfo?.name}!`} <span>({userInfo?.role})</span>
        </p>
        <DashboardCountCards
          setSelectedState={setSelectedState}
          selectedState={selectedState}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="suggestionBoxContainer">
            <div className="searchBox">
              <img src={searchIcon} alt="filter" className="icon" />
              <input
                type="text"
                name="search"
                id=""
                placeholder="Search by device name"
                className="searchInput"
                value={search}
                onChange={(e: any) => searchFilterFunction(e.target.value)}
                autoComplete="off"
              />
              {search && (
                <RxCross2
                  color="#767676"
                  fontSize={20}
                  onClick={clearSearchInput}
                  className="cursor-pointer"
                />
              )}
            </div>
            {data?.length
              ? search && (
                  <ul className="suggestionInput">
                    {data?.map((item: any) => {
                      return (
                        <li
                          className="suggestionText"
                          key={item?.id}
                          onClick={() => getItem(item)}
                        >
                          {item?.name}
                        </li>
                      );
                    })}
                  </ul>
                )
              : ""}
          </div>
          <div className="searchBox">
            <img src={filterIcon} alt="filter" className="icon" />
            <select
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
              className="searchInput"
            >
              {deviceState?.map((state) => (
                <option
                  key={state.key}
                  value={state.key}
                  style={{
                    color:
                      selectedState === state.key ? "black" : "rgba(0,0,0,0.4)",
                  }}
                >
                  {state.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-10">
          <div className="cardBoxShadow cardContainer flex-col col-span-1 lg:col-span-2">
            <div className="flex justify-between items-center">
              <p className="dashboardCardHeading">Device Locations</p>
              <button
                className="borderedButton"
                onClick={() => setMapKey(mapKey + 1)}
              >
                Reset
              </button>
            </div>

            <div className="map_height h-96 lg:h-full">
              <MapView deviceList={deviceList} key={mapKey} />
            </div>
          </div>
          <div className="cardBoxShadow cardContainer flex-col col-span-1 lg:col-span-1">
            <div className="flex justify-between items-center">
              <p className="dashboardCardHeading">
                {dyaminHeading(selectedState)}
              </p>
            </div>
            <div style={{ minHeight: "300px" }}>
              <DeviceTable deviceList={deviceList} />
              {/* {deviceList?.length && <DeviceTable deviceList={deviceList} />} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
