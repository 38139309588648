import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ResetNewUserManagementFormError,
  UpdateNewUserManagementFormError,
} from "../../redux/reducer/userManagement";
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
} from "../../constants/constant";

const useUserManagementValidation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { newUser } = useSelector((state: any) => state.userManagement);
  const { userInfo } = useSelector((store: any) => store.user);

  const UserManagementFormValidation = () => {
    if (newUser?.name?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({ key: "name", value: "Required !" })
      );
      return false;
    } else if (newUser?.email?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({ key: "email", value: "Required !" })
      );
      return false;
    } else if (!EMAIL_REGEX.test(newUser?.email) && !newUser.id) {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "email",
          value: "Email not valid !",
        })
      );
      return false;
    } else if (newUser?.password?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "password",
          value: "Required !",
        })
      );
      return false;
    } else if (!PASSWORD_REGEX.test(newUser?.password) && !newUser.id) {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "password",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else if (newUser?.phone?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "phone",
          value: "Required !",
        })
      );
      return false;
    } else if (newUser?.role?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "role",
          value: "Required !",
        })
      );
      return false;
    } else if (!newUser?.assignedDevices?.length && userInfo.role === "Admin") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "assignedDevices",
          value: "Required !",
        })
      );
      return false;
    } else if (
      !newUser?.sendNotification &&
      !newUser?.sendEmail &&
      !newUser?.sendSMS
    ) {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "sendAlert",
          value: "Required !",
        })
      );
    } else {
      dispatch(ResetNewUserManagementFormError());
      return true;
    }
  };

  const OnChangeUserManagementFormValueValidation = ({
    id,
    value,
  }: {
    id: "name" | "email" | "password" | "phone" | "role";
    value: string;
  }) => {
    if (id === "name" && value?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({ key: "name", value: "Required !" })
      );
      return false;
    } else if (id === "email" && value?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({ key: "email", value: "Required !" })
      );
      return false;
    } else if (id === "email" && !EMAIL_REGEX.test(value)) {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "email",
          value: "Please enter a valid email.",
        })
      );
      return false;
    } else if (id === "password" && value?.trim() === "" && !newUser?.id) {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "password",
          value: "Required !",
        })
      );
      return false;
    } else if (
      id === "password" &&
      !PASSWORD_REGEX.test(value) &&
      !newUser?.id
    ) {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "password",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else if (id === "phone" && value?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "phone",
          value: "Required !",
        })
      );
      return false;
    } else if (id === "role" && value?.trim() === "") {
      dispatch(ResetNewUserManagementFormError());
      dispatch(
        UpdateNewUserManagementFormError({
          key: "role",
          value: "Required !",
        })
      );
      return false;
    } else {
      dispatch(ResetNewUserManagementFormError());
      return true;
    }
  };
  return {
    UserManagementFormValidation,
    OnChangeUserManagementFormValueValidation,
  };
};
export default useUserManagementValidation;
