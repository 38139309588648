import axios from "axios";
import { getAuthHeader } from "../constants/constant";

export const GetAllLogsDataService = (
  page: number,
  type: string,
  deviceId: string
) => {
  const url = `${process.env.REACT_APP_URL}v1/AlldeviceLogs?page=${page}&type=${type}&deviceId=${deviceId}`;
  return axios.get(url, getAuthHeader());
};
export const GetLogDetailDataService = (logId: string, type: string) => {
  const url = `${process.env.REACT_APP_URL}v1/log/${logId}?type=${type}`;
  return axios.get(url, getAuthHeader());
};
