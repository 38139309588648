import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { sensor } from "../../interface/reducer/sensor";
import { fromSetPoint } from "../../constants/conversion";
import { celsiusToFahrenheit } from "../../constants/temprature";
import { useTimezone } from "../../hooks/common/useTimezone";
import moment from 'moment-timezone';

function mapLevelToMarkerText(level: string) {
  if (level === "H") {
    return "Alert (High)";
  } else if (level === "L") {
    return "Alert (Low)";
  } else if (level === "N") {
    return "Normal";
  } else {
    return "Alert";
  }
}
function mapAlertY(
  selectedSensor: sensor,
  y: any,
  temperaturePreference: string
) {
  if (selectedSensor?.type === "01" && temperaturePreference === "F") {
    return celsiusToFahrenheit(y);
  }
  return y;
}

function mapLevelToBackgroundColor(level: any) {
  if (level === "N") {
    return "#01FF00";
  } else {
    return "#FF4560";
  }
}

function mapLevelToColor(level: any) {
  if (level === "N") {
    return "black";
  } else {
    return "white";
  }
}

export default function SensorGraphLine(props: any) {
  const { sensor, deviceId } = props;

  const { temperaturePreference } = useSelector((store: any) => store.sensor);

  const { status, data, error } = useTimezone(deviceId);

  const points = props?.alerts?.map(([x, y, level]) => ({
    x,
    y: mapAlertY(sensor, y, temperaturePreference),
    marker: {
      size: 6,
      fillColor: "#fff",
      strokeColor: "#2698FF",
      radius: 2,
    },
    label: {
      borderColor: mapLevelToBackgroundColor(level),
      offsetY: 0,
      style: {
        color: mapLevelToColor(level),
        background: mapLevelToBackgroundColor(level),
      },
      text: mapLevelToMarkerText(level),
    },
  }));

  const annotations = {
    yaxis: [
      shouldShowSetPoints(sensor) && {
        y: fromSetPoint(
          sensor,
          sensor?.high_trip_point ?? "9999",
          temperaturePreference
        ),
        borderColor: "rgb(200, 40, 200)",
        fillColor: "rgb(0, 0, 0)",
        label: {
          text: "High Trip Point",
        },
      },
      shouldShowSetPoints(sensor) && {
        y: fromSetPoint(
          sensor,
          sensor?.low_trip_point ?? "0000",
          temperaturePreference
        ),
        borderColor: "rgb(200, 40, 200)",
        fillColor: "rgb(0, 0, 0)",
        label: {
          text: "Low Trip Point",
        },
      },
    ].filter(Boolean),
    points,
  };

  const config: any = {
    series: [
      {
        name: props?.yAxisLabel,
        data: props?.data?.map(([t, v]) => [
          t,
          typeof v === "number" ? v?.toFixed(2) : (+v)?.toFixed(2),
        ]),
      },
    ],
    options: {
      annotations,
      chart: {
        type: "area",
        stacked: false,
        height: 200,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          tools: {
            download: false,
            selection: false,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: false,
          },
          show: true,
          autoSelected: "zoom",
        },
        events: {
          beforeZoom: function (ctx: any) {
            // we need to clear the range as we only need it on the iniital load.
            ctx.w.config.xaxis.range = undefined;
          },
        },
      },
      colors: ["rgb(0, 154, 191)"],
      dataLabels: {
        enabled: false,
      },
      markers: {
        //size: 0,
      },
      title: {
        text: props?.title,
        align: "left",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        showForNullSeries: false,
        title: {
          text: props?.yAxisLabel,
        },
      },
      xaxis: {
        title: {
          text: "Date/Time",
        },
        type: "datetime",
        labels: {
            datetimeUTC: false,
            formatter: function(value: string) {
                const stamp = moment(value).tz(data.timezone).format("HH:00");
                return stamp; 
            }
        }
      },
      tooltip: {
        shared: false,
        y: {},
        x: { 
            format: "MM/dd/yyyy HH:mm:ss",
            formatter: function(value: string) {
                const stamp = moment(value).tz(data.timezone).format("MM/DD/yyyy HH:mm:ss");
                return stamp;
            }
        },
      },
      stroke: {
        curve: "smooth",
        width: props.strokeWidth ? 1 : 3, // You can adjust this value to control the line width
      },
    },
  };
  return (
    <>
      {status === 'pending' ? (
          'Loading...'
      ) : status === 'error' ? (
          <span>Error: {error.message}</span>
      ) : (
        <ReactApexChart
        options={config.options}
        series={config.series}
        type="area"
        width="100%"
        height="auto"
        />
      )}
    </>
  );
}
function shouldShowSetPoints(sensor: sensor) {
  return (
    !(
      sensor?.high_trip_point === "0000" && sensor?.low_trip_point === "0000"
    ) && sensor?.type !== "03"
  );
}
