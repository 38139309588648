import React, { useContext, useEffect } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

import { useSelector } from "react-redux";
import "../../../styles/Global.css";
import useDevicesServiceHandler from "../../../hooks/serviceHandler/devices";
import activeLocation from "../../../assets/images/icons/activeLocation.svg";
import inactiveLocation from "../../../assets/images/icons/inactiveLocation.svg";
import { ThemeContext } from "../../../App";

export default function DeviceByIdMapView({ latitude, longitude }) {
  const { GetDevicesListServiceHandler } = useDevicesServiceHandler();
  const { selectedDevice } = useSelector((state: any) => state.device);
  const isLoaded = useContext(ThemeContext);
  useEffect(() => {
    GetDevicesListServiceHandler();
  }, []);

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMap
        zoom={14}
        mapContainerClassName="map-container"
        center={{
          lat: latitude,
          lng: longitude,
        }}
      >
        <MarkerF
          position={{ lat: latitude, lng: longitude }}
          icon={{
            url: selectedDevice.active ? activeLocation : inactiveLocation,
            anchor: new google.maps.Point(17, 46),
            scaledSize: new google.maps.Size(30, 30),
          }}
        />
      </GoogleMap>
    </div>
  );
}
