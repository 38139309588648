import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import component from "../constants/urls";
import Label from "../components/common/formInput/Label";
import searchIcon from "../assets/images/icons/searchIcon.svg";
import filterIcon from "../assets/images/icons/filterIcon.svg";
import LogsTable from "../components/common/table/LogsTable";
import useDeviceLogs from "../hooks/serviceHandler/logs";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { logs } from "../interface/reducer/logs";
import { device } from "../interface/reducer/device";
import ReactPaginate from "react-paginate";

const logsFilter = [
  {
    id: "1",
    key: "all",
    value: "all",
  },
  {
    id: "2",
    key: "today",
    value: "Today",
  },
  {
    id: "3",
    key: "yesterday",
    value: "Yesterday",
  },
  {
    id: "4",
    key: "this month",
    value: "This Month",
  },
  {
    id: "5",
    key: "this year",
    value: "This Year",
  },
  {
    id: "6",
    key: "custom",
    value: "Custom Date",
  },
];

export default function Logs() {
  const navigate = useNavigate();
  const { GetAllLogsDataServiceHandler } = useDeviceLogs();

  const { devicesList } = useSelector((store: any) => store.device);
  const { allLogsList, logIsLoder } = useSelector((store: any) => store.log);
  const [logType, setLogType] = useState("all");
  const [logData, setLogData] = useState([]);
  const [deviceNameState, setDeviceNameState] = useState("");

  const getFilterLogData = () => {
    let newList: any = [];
    if (logType === "status") {
      newList = allLogsList.filter(
        (item: logs) => item.logType === "postStatus"
      );
    } else if (logType === "alert") {
      newList = allLogsList.filter((item: logs) => item.logType === "alert");
    } else {
      newList = [...allLogsList];
    }
    setLogData([...newList]);
  };

  const getFilteredLogDataByDeviceName = () => {
    let listData: any = [];
    if (deviceNameState === "all") {
      listData = allLogsList?.filter((item: logs) => item);
    } else if (deviceNameState) {
      listData = allLogsList?.filter(
        (item: logs) => item?.deviceId === deviceNameState
      );
    } else {
      listData = [...allLogsList];
    }
    setLogData([...listData]);
  };

  useEffect(() => {
    setLogData([...allLogsList]);
  }, [allLogsList]);

  useEffect(() => {
    getFilterLogData();
  }, [logType]);

  useEffect(() => {
    getFilteredLogDataByDeviceName();
  }, [deviceNameState]);

  return (
    <div className="screenContainerPadding">
      <p className="breadcrum">
        <span onClick={() => navigate(component.dashboard.url)}>Dashboard</span>{" "}
        / <span>History</span>
      </p>
      <div className="mb-5">
        <LogsTable />
      </div>
    </div>
  );
}
