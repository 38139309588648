import { logs } from "../../interface/reducer/logs";

const initalstate = {
  allLogsList: [],
  selectedLogDetail: {},
  logIsLoder: false,
  logCount: 0,
};

interface updateAllLogsList {
  type: "UPDATE_LOGS_LIST";
  payload: Array<logs>;
}
interface updateSelectedLogData {
  type: "UPDATE_SELECTED_LOG_DATA";
  payload: logs;
}
interface updateLogLoader {
  type: "UPDATE_LOG_LOADER";
  payload: boolean;
}
interface updateLogCount {
  type: "UPDATE_LOG_COUNT";
  paylaod: number;
}
type action =
  | updateAllLogsList
  | updateSelectedLogData
  | updateLogLoader
  | updateLogCount;

const LogsReducer = (state = initalstate, action: action) => {
  switch (action.type) {
    case "UPDATE_LOGS_LIST":
      return {
        ...state,
        allLogsList: [...action.payload],
      };
    case "UPDATE_SELECTED_LOG_DATA":
      return {
        ...state,
        selectedLogDetail: {
          ...action.payload,
        },
      };
    case "UPDATE_LOG_LOADER":
      return {
        ...state,
        logIsLoder: action.payload,
      };
    case "UPDATE_LOG_COUNT": {
      return {
        ...state,
        logCount: action.paylaod,
      };
    }
    default:
      return state;
  }
};
export default LogsReducer;

export const UpdateLogsList = (allLogsList: Array<logs>): updateAllLogsList => {
  return {
    type: "UPDATE_LOGS_LIST",
    payload: allLogsList,
  };
};
export const UpdateSelectedLogData = (
  selectedLogDetail: logs
): updateSelectedLogData => {
  return {
    type: "UPDATE_SELECTED_LOG_DATA",
    payload: selectedLogDetail,
  };
};
export const UpdateLogLoader = (logIsLoder: boolean): updateLogLoader => {
  return {
    type: "UPDATE_LOG_LOADER",
    payload: logIsLoder,
  };
};
export const UpdateLogCount = (logCount: number): updateLogCount => {
  return {
    type: "UPDATE_LOG_COUNT",
    paylaod: logCount,
  };
};
