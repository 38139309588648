import { Switch, TextField } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";

const OutputLineWrapper = styled.div`
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 24px 10px 20px 5px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const OutputLineLabel = styled.div`
  font-size: 10px;
  position: absolute;
  left: 15px;
  top: 8px;
  color: rgb(0 0 0 / 35%);
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const SwitchLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NameInput = styled.input`
  border: 0;
  display: inline-block;
  font-size: 1rem;
  min-width: 0;
  outline: none;
  padding: 4px 0;
  width: calc(100% - 20px);
  border-bottom: 1px solid #949494;

  &:focus {
    border-bottom: 2px solid #009abf;
  }
`;

const OutputLineNameWrapper = styled.div`
  flex: 1;
`;

function OutputLineName({ ...props }) {
  return (
    <OutputLineNameWrapper>
      <NameInput {...props} />
    </OutputLineNameWrapper>
  );
}

function OutputLines({
  enabled,
  line,
  name,
  frequency = 0,
  onNameChange,
  onEnabledChange,
  onFrequencyChange,
}) {
  return (
    <>
      <div className="output_line_container">
        <div className="flex flex-col md:flex-row gap-5 justify-between">
          <div className="flex flex-col items-start w-full">
            <p className="output_text uppercase">Output Line {line}</p>
            <div className="w-full flex items-center gap-4">
              <Switch checked={enabled} onChange={onEnabledChange} />
              <OutputLineName value={name} onChange={onNameChange} />
            </div>
          </div>
          <div>
            <p className="output_text">Pulsed Output (seconds)</p>
            <TextField
              className="output_lines_input"
              value={frequency}
              onChange={(e) => onFrequencyChange(+e.target.value)}
              type="number"
              InputProps={{ inputProps: { max: 999, min: 0 } }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default function OutputLineDeviceSetting({
  onEnabledChange,
  onNameChange,
  onFrequencyChange,
  outputLineNames,
  outputLinesEnabled,
  frequencies = [],
}) {
  return (
    <div className="registerFormContainer mb-5">
      <form action="">
        <p className="formHeading">Output Lines</p>
        <div className="grid gap-5">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((line) => (
            <OutputLines
              key={line}
              line={line}
              name={outputLineNames[line - 1]}
              frequency={frequencies[line - 1]}
              onFrequencyChange={(value: any) =>
                onFrequencyChange?.(line, value)
              }
              enabled={outputLinesEnabled[line - 1]}
              onNameChange={(e: any) => onNameChange(line, e.target.value)}
              onEnabledChange={(e: any) =>
                onEnabledChange(line, e.target.checked)
              }
            />
          ))}
        </div>
      </form>
    </div>
  );
}
