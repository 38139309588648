import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { enableRefresh, disableRefresh }from "../../redux/reducer/autoRefresh"

const getSeconds = () => Math.round(new Date().getTime() / 1000);

export default function AutoRefresh({ time, onRefresh = () => {} }: any) {
    // Get the second this component was mounted.
    const [lastRefresh, setLastRefresh] = useState(getSeconds());

    const pauseRefresh = () => {
        if (refreshEnabled) {
            dispatch(disableRefresh());
        } else {
            dispatch(enableRefresh());
            setLastRefresh(getSeconds());
            setTimeLeft(60);
        }
    };

    let [timeLeft, setTimeLeft] = useState(time);

    
    const dispatch = useDispatch();
    const { refreshEnabled } = useSelector((state: any) => state.autoRefresh);


    useEffect(() => {}, [time]);

    // Every second update the time until refresh.
    useEffect(() => {
        if (!refreshEnabled) {
            return;
        }
        const interval = setInterval(() => {
            const now = getSeconds();
            const diff = now - lastRefresh;

            if (diff >= time + 1) {
                onRefresh();
                setLastRefresh(getSeconds());
                setTimeLeft(time);
            } else {
                setTimeLeft(time - diff);
            }
        }, 500);
        return () => clearInterval(interval);
    }, [onRefresh, setLastRefresh, setTimeLeft, time, lastRefresh, refreshEnabled]);

    var buttonStyle = "w-max h-max border-red-500 text-red-500 " +
        "border border-solid text-sm font-normal leading-3 flex p-2.5 " +
        "rounded justify-center align-center bg-white";

    var countStyle = "font-normal text-m text-blue-600 text-end align-middle h-max p-2.5";

    return (
        <div className="grid grid-flow-col gap-2 justify-items-center items-center mb-5">
            <button className={buttonStyle} onClick={ pauseRefresh }>
                { refreshEnabled ? "Pause Refresh" : "Resume Refresh" }
            </button>
            
            { refreshEnabled ?
                <p className={countStyle}>
                    Refreshing in <b>{timeLeft}</b>s
                </p> : null
            }
        </div>
    );
}

