import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Label from "../common/formInput/Label";

const Wrapper = styled.div`
  margin-top: 2rem;
`;

export default function SensorOutputLineTriggerSettings({
  value = -1,
  onChange,
  outputLineNames = [],
}) {
  return (
    <>
      <div>
        <Label label=" Trigger Output Line On Alert" required={false} />
        <Select
          fullWidth
          value={value}
          onChange={onChange}
          className="sensorSettingInput"
        >
          <MenuItem value="-1">Disabled</MenuItem>
          <MenuItem value="1">
            {outputLineNames[0] ?? "Output Line #1"}
          </MenuItem>
          <MenuItem value="2">
            {outputLineNames[1] ?? "Output Line #2"}
          </MenuItem>
          <MenuItem value="3">
            {outputLineNames[2] ?? "Output Line #3"}
          </MenuItem>
          <MenuItem value="4">
            {outputLineNames[3] ?? "Output Line #4"}
          </MenuItem>
          <MenuItem value="5">
            {outputLineNames[4] ?? "Output Line #5"}
          </MenuItem>
          <MenuItem value="6">
            {outputLineNames[5] ?? "Output Line #6"}
          </MenuItem>
          <MenuItem value="7">
            {outputLineNames[6] ?? "Output Line #7"}
          </MenuItem>
          <MenuItem value="8">
            {outputLineNames[7] ?? "Output Line #8"}
          </MenuItem>
        </Select>
      </div>
      {/* <Wrapper>
        <FormControl fullWidth>
          <InputLabel id="output_line_trigger">
            Trigger Output Line On Alert
          </InputLabel>
          <Select
            labelId="output_line_trigger"
            fullWidth
            value={value}
            onChange={onChange}
          >
            <MenuItem value="-1">Disabled</MenuItem>
            <MenuItem value="1">
              {outputLineNames[0] ?? "Output Line #1"}
            </MenuItem>
            <MenuItem value="2">
              {outputLineNames[1] ?? "Output Line #2"}
            </MenuItem>
            <MenuItem value="3">
              {outputLineNames[2] ?? "Output Line #3"}
            </MenuItem>
            <MenuItem value="4">
              {outputLineNames[3] ?? "Output Line #4"}
            </MenuItem>
            <MenuItem value="5">
              {outputLineNames[4] ?? "Output Line #5"}
            </MenuItem>
            <MenuItem value="6">
              {outputLineNames[5] ?? "Output Line #6"}
            </MenuItem>
            <MenuItem value="7">
              {outputLineNames[6] ?? "Output Line #7"}
            </MenuItem>
            <MenuItem value="8">
              {outputLineNames[7] ?? "Output Line #8"}
            </MenuItem>
          </Select>
        </FormControl>
      </Wrapper> */}
    </>
  );
}
