import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateModalOpenStatus } from "../../../redux/reducer/commonReducer";
import { sensor } from "../../../interface/reducer/sensor";
import useSensorServiceHandler from "../../../hooks/serviceHandler/sensor";
import { ClipLoader } from "react-spinners";

export default function DeleteModal() {
  const dispatch = useDispatch();

  const { DeleteSensorServiceHandler } = useSensorServiceHandler();

  const { selectedSensor } = useSelector((store: any) => store.sensor);
  const { isLoader } = useSelector((store: any) => store.common);

  const deleteSensor = async (data: sensor) => {
    await DeleteSensorServiceHandler(data?.device_id, data?.type, data?.esn);
  };

  const handleCancelButton = () => {
    dispatch(UpdateModalOpenStatus(false));
  };
  return (
    <>
      <div className="">
        <p className="deleteMessage">Warning: Permanent Delete! Confirm?</p>
        {isLoader ? (
          <div className="w-full flex items-center justify-center">
            <ClipLoader
              color={"#2047ab"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="flex items-center justify-center gap-5">
            <button
              className="primaryButtonFilled"
              onClick={() => deleteSensor(selectedSensor)}
            >
              Sure
            </button>
            <button className="borderedButton" onClick={handleCancelButton}>
              Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );
}
