import {
  action,
  forgetPasswordInputData,
  loginInputData,
  registerInputData,
  resetForgetPasswordFormValue,
  resetForgetPasswordValue,
  resetLoginFormError,
  resetPasswordInputData,
  resetPasswordValue,
  resetRegisterFormValue,
  updateForgetPasswordFormError,
  updateForgetPasswordInputs,
  updateLoginFormError,
  updateLoginInputs,
  updateRegisterFormError,
  updateRegisterInputs,
  updateResetPasswordFormError,
  updateResetPasswordInputs,
} from "../../interface/reducer/auth";

const initialState = {
  login: {
    email: "",
    password: "",
  },
  register: {
    email: "",
    password: "",
    name: "",
    confirmPassword: "",
    phone: "",
    deviceId: "",
  },
  forgetPassword: {
    email: "",
  },
  resetPassword: {
    email: "",
    newPassword: "",
    confirmPassword: "",
  },
  loginFormError: {
    email: "",
    password: "",
  },
  registerFormError: {
    email: "",
    password: "",
    name: "",
    confirmPassword: "",
    phone: "",
    deviceId: "",
  },
  forgetPasswordFormError: {
    email: "",
  },
  resetPasswordFormError: {
    email: "",
    newPassword: "",
    confirmPassword: "",
  },
};

export interface updateRememberMeStatus {
  type: "UPDATE_REMEMBER_Me_STATUS";
  payload: boolean;
}

const AuthReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case "UPDATE_LOGIN_INPUTS": {
      return {
        ...state,
        login: {
          ...state.login,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "UPDATE_REGISTER_INPUTS": {
      return {
        ...state,
        register: {
          ...state.register,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "UPDATE_FORGET_PASSWORD_INPUTS": {
      return {
        ...state,
        forgetPassword: {
          ...state.forgetPassword,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "UPDATE_RESET_PASSWORD_INPUTS": {
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "UPDATE_LOGIN_FORM_ERROR": {
      return {
        ...state,
        loginFormError: {
          ...state.loginFormError,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "UPDATE_REGISTER_FORM_ERROR": {
      return {
        ...state,
        registerFormError: {
          ...state.registerFormError,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "UPDATE_FORGET_PASSWORD_FORM_ERROR": {
      return {
        ...state,
        forgetPasswordFormError: {
          ...state.forgetPasswordFormError,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "UPDATE_RESET_PASSWORD_FORM_ERROR": {
      return {
        ...state,
        resetPasswordFormError: {
          ...state.resetPasswordFormError,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "RESET_LOGIN_VALUE": {
      return {
        ...state,
        login: {
          email: "",
          password: "",
        },
      };
    }

    case "RESET_REGISTER_VALUE": {
      return {
        ...state,
        register: {
          email: "",
          password: "",
          name: "",
          confirmPassword: "",
        },
      };
    }

    case "RESET_FORGET_PASSWORD_VALUE": {
      return {
        ...state,
        forgetPassword: {
          email: "",
        },
      };
    }
    case "RESET_PASSWORD_VALUE": {
      return {
        ...state,
        resetPassword: {
          email: "",
          newPassword: "",
          confirmPassword: "",
        },
      };
    }

    case "RESET_LOGIN_FORM_ERROR": {
      return {
        ...state,
        loginFormError: {
          email: "",
          password: "",
        },
      };
    }

    case "RESET_REGISTER_FORM_ERROR": {
      return {
        ...state,
        registerFormError: {
          email: "",
          password: "",
          name: "",
          confirmPassword: "",
        },
      };
    }

    case "RESET_FORGET_PASSWORD_FORM_ERROR": {
      return {
        ...state,
        forgetPasswordFormError: {
          email: "",
        },
      };
    }

    case "RESET_PASSWORD_FORM_ERROR": {
      return {
        ...state,
        resetPasswordFormError: {
          email: "",
          newPassword: "",
          confirmPassword: "",
        },
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;

export const UpdateLoginInputs = (data: loginInputData): updateLoginInputs => {
  return {
    type: "UPDATE_LOGIN_INPUTS",
    payload: data,
  };
};

export const UpdateRegisterInputs = (
  data: registerInputData
): updateRegisterInputs => {
  return {
    type: "UPDATE_REGISTER_INPUTS",
    payload: data,
  };
};

export const UpdateForgetPasswordInputs = (
  data: forgetPasswordInputData
): updateForgetPasswordInputs => {
  return {
    type: "UPDATE_FORGET_PASSWORD_INPUTS",
    payload: data,
  };
};
export const UpdateResetPasswordInputs = (
  data: resetPasswordInputData
): updateResetPasswordInputs => {
  return {
    type: "UPDATE_RESET_PASSWORD_INPUTS",
    payload: data,
  };
};

export const UpdateLoginFormError = (
  data: loginInputData
): updateLoginFormError => {
  return {
    type: "UPDATE_LOGIN_FORM_ERROR",
    payload: data,
  };
};

export const UpdateForgetPasswordFormError = (
  data: forgetPasswordInputData
): updateForgetPasswordFormError => {
  return {
    type: "UPDATE_FORGET_PASSWORD_FORM_ERROR",
    payload: data,
  };
};
export const UpdateResetPasswordFormError = (
  data: resetPasswordInputData
): updateResetPasswordFormError => {
  return {
    type: "UPDATE_RESET_PASSWORD_FORM_ERROR",
    payload: data,
  };
};
export const ResetLoginValue = () => {
  return {
    type: "RESET_LOGIN_VALUE",
  };
};

export const ResetRegisterValue = () => {
  return {
    type: "RESET_REGISTER_VALUE",
  };
};

export const ResetForgetPasswordInputs = (): resetForgetPasswordValue => {
  return {
    type: "RESET_FORGET_PASSWORD_VALUE",
  };
};
export const ResetPasswordInputs = (): resetPasswordValue => {
  return {
    type: "RESET_PASSWORD_VALUE",
  };
};
export const UpdateRegisterFormError = (
  data: registerInputData
): updateRegisterFormError => {
  return {
    type: "UPDATE_REGISTER_FORM_ERROR",
    payload: data,
  };
};

export const ResetLoginFormError = (): resetLoginFormError => {
  return {
    type: "RESET_LOGIN_FORM_ERROR",
  };
};

export const ResetRegisterFormError = (): resetRegisterFormValue => {
  return {
    type: "RESET_REGISTER_FORM_ERROR",
  };
};

export const ResetForgetPassowrdFormError =
  (): resetForgetPasswordFormValue => {
    return {
      type: "RESET_FORGET_PASSWORD_FORM_ERROR",
    };
  };
