import axios from "axios";
import { getAuthHeader } from "../constants/constant";

export const GetHistoryAlertListService = async (
  {userId, currentPage, search}: {userId: string,
  currentPage: number,
  search: string}
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/deviceSensorsRaw/${userId}?page=${currentPage}&search=${search}`;
  console.log('url---->',url);
  return axios.get(url, getAuthHeader());
};

export const GetHistoryAlertDetailByIdService = (historyId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/getHistoryInfoId/${historyId}`;

  
  return axios.get(url);
};

export const GetHistoryList = (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}web/v1/device/${deviceId}/sensors`;
  return axios.get(url);
};
export const GetSensorHistoryService = (
  deviceId: string,
  type: string,
  esn: string
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/device/${deviceId}/sensors/${type}/${esn}/timeseries`;
  return axios.get(url, getAuthHeader());
};
