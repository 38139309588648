const initialState = {
  permissionList: {},
  assignedDeviceList: {},
};

interface updatePermissionsList {
  type: "UPDATE_PERMISSIONS_LIST";
  payload: any;
}
interface updateAssignedDeviceList {
  type: "UPDATE_ASSIGNED_DEVICE_LIST";
  payload: any;
}

type action = updatePermissionsList | updateAssignedDeviceList;
const PermissionReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case "UPDATE_PERMISSIONS_LIST": {
      return {
        ...state,
        permissionList: { ...action.payload },
      };
    }
    case "UPDATE_ASSIGNED_DEVICE_LIST": {
      return {
        ...state,
        assignedDeviceList: { ...action.payload },
      };
    }
    default:
      return state;
  }
};
export default PermissionReducer;

export const UpdatePermissionsList = (
  permissionList: any
): updatePermissionsList => {
  return {
    type: "UPDATE_PERMISSIONS_LIST",
    payload: permissionList,
  };
};

export const UpdateAssignedDeviceList = (
  assignedDeviceList: any
): updateAssignedDeviceList => {
  return {
    type: "UPDATE_ASSIGNED_DEVICE_LIST",
    payload: assignedDeviceList,
  };
};
