import React from "react";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import Label from "../common/formInput/Label";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetNewDeviceInfo,
  ResetUpdateNewDeviceFormError,
  UpdateNewDeviceInfo,
  newDeviceData,
} from "../../redux/reducer/devices";
import useRegisterFormValidation from "../../hooks/validations/Register";
import useNewDeviceRegisterFormValidation from "../../hooks/validations/RegisterDevice";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";

export default function RegisterDevice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store: any) => store.user);
  const {
    newDevice,
    newDeviceError,
    addDeviceSuccessMessage,
    addDeviceErrorMessage,
  } = useSelector((store: any) => store.device);
  const { OnChangeTextValidation, Validation } =
    useNewDeviceRegisterFormValidation();
  const { RegisterNewDeviceServiceHandler, AddDeviceServiceHandler } =
    useDevicesServiceHandler();

  const handleRoute = (url: string) => {
    navigate(url);
    dispatch(ResetNewDeviceInfo());
    dispatch(ResetUpdateNewDeviceFormError());
  };

  const onChangeHandler = (e: any) => {
    const { name: key, value } = e.target;
    dispatch(UpdateNewDeviceInfo({ key, value }));
    OnChangeTextValidation({ key, value });
  };

  const registerBtnPressHandler = async (e: any) => {
    e.preventDefault();
    const isValid = Validation();
    if (isValid) {
      AddDeviceServiceHandler();
    }
  };

  return (
    <div className="screenContainerPadding">
      <p className="breadcrum">
        <span onClick={() => handleRoute(component.dashboard.url)}>
          Dashboard
        </span>{" "}
        / <span>Register Device</span>
      </p>
      <div className="registerFormContainer">
        <form action="">
          <p className="formHeading">Add New Device</p>
          {(addDeviceSuccessMessage || addDeviceErrorMessage) && (
            <div className="mb-4">
              {addDeviceSuccessMessage ? (
                <SuccessMessage msg={addDeviceSuccessMessage} />
              ) : addDeviceErrorMessage ? (
                <ErrorMessage msg={addDeviceErrorMessage} />
              ) : (
                ""
              )}
            </div>
          )}
          <div className="grid gap-5">
            <div className="flex flex-col">
              <Label label="Device ID" required={true} />
              <input
                className="registerFormInput"
                type="text"
                name="deviceId"
                id="deviceId"
                placeholder="Enter the ID of the device you are registering."
                defaultValue={newDevice?.deviceId}
                disabled
              />
              {newDeviceError?.deviceId && (
                <p className="errorMessage">{newDeviceError?.deviceId}</p>
              )}
            </div>
            <div className="flex flex-col">
              <Label label="Set Subscription Plan" required={false} />
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <Label label="Monthly" required={true} />
                  <input
                    className="registerFormInput"
                    type="text"
                    name="subscriptionMonthly"
                    id="subscriptionMonthly"
                    value={newDevice?.subscriptionMonthly}
                    onChange={onChangeHandler}
                    placeholder="Enter monthly Charges"
                  />
                  {newDeviceError?.subscriptionMonthly && (
                    <p className="errorMessage">
                      {newDeviceError?.subscriptionMonthly}
                    </p>
                  )}
                </div>
                <div>
                  <Label label="Yearly" required={true} />
                  <input
                    className="registerFormInput"
                    type="text"
                    name="subscriptionYearly"
                    id="subscriptionYearly"
                    value={newDevice?.subscriptionYearly}
                    onChange={onChangeHandler}
                    placeholder="Enter yearly Charges"
                  />
                  {newDeviceError?.subscriptionYearly && (
                    <p className="errorMessage">
                      {newDeviceError?.subscriptionYearly}
                    </p>
                  )}
                </div>
              </div>
              {newDeviceError?.subscriptionId && (
                <p className="errorMessage">{newDeviceError?.subscriptionId}</p>
              )}
            </div>
            <div className="flex gap-5 justify-end items-center">
              <button
                onClick={registerBtnPressHandler}
                className="primaryButtonFilled"
              >
                Add Device
              </button>
              <button
                className="borderedButton"
                onClick={() => handleRoute(component.inventoryDeviceList.url)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
