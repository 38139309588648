import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { MdBatteryAlert, MdBattery0Bar, MdBattery3Bar } from "react-icons/md";
import { LuSettings } from "react-icons/lu";
import { BsTrash, BsDatabase } from "react-icons/bs";
import { FaRegEyeSlash } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { sensor } from "../../interface/reducer/sensor";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/sensorCard.css";
import {
    UpdateDrawerExpand,
    UpdateDrawerName,
    UpdateModalName,
    UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import useSensorServiceHandler from "../../hooks/serviceHandler/sensor";
import { UpdateSensorStatusStateService } from "../../service/sensors";
import { ClipLoader } from "react-spinners";
import { translateLastValue } from "../../constants/conversion";
import swal from "sweetalert";
import { IconButton, Tooltip } from "@mui/material";
import SensorCardGraphData from "./SensorCardGraphData";
import { UpdateSelectedSensor } from "../../redux/reducer/sensors";
import { useTimezone } from "../../hooks/common/useTimezone";
import moment from "moment-timezone";
import {disableRefresh} from "../../redux/reducer/autoRefresh";

export default function SensorCard(props: { data: sensor }) {
    const { data } = props;
    const dispatch = useDispatch();

    const {
        GetDeviceSensorDetailDataByIdServiceHandler,
        GetDeviceSensorListServiceHandler,
    } = useSensorServiceHandler();

    const { userInfo } = useSelector((store: any) => store.user);
    const { selectedSensor, temperaturePreference, sensorCurrentPage } =
        useSelector((store: any) => store.sensor);
    const { selectedDevice } = useSelector((store: any) => store.device);

    const [offset, setOffset] = React.useState(data?.manual_offset);

    const sensorDetailData = async (data: sensor, drawerName: string, e: any) => {
        e.preventDefault();
        dispatch(disableRefresh());
        dispatch(UpdateDrawerExpand(true));
        dispatch(UpdateSelectedSensor({ ...data }));
        dispatch(UpdateDrawerName(drawerName));
        // setSensorData({ ...data });
    };

    const sensorHistoryData = (drawerName: string, data, e: any) => {
        e.stopPropagation();
        dispatch(disableRefresh());
        dispatch(UpdateSelectedSensor({ ...data }));
        dispatch(UpdateDrawerName(drawerName));
        dispatch(UpdateDrawerExpand(true));
    };

    const { status, data: timezoneResp, error } = useTimezone(data?.device_id);

    const handleSensorDetailModal = async (
        modalName: string,
        data: sensor,
        e: any
    ) => {
        e.stopPropagation();
        await GetDeviceSensorDetailDataByIdServiceHandler(
            data?.device_id,
            data?.type,
            data?.esn
        );
        dispatch(UpdateModalName(modalName));
        dispatch(UpdateModalOpenStatus(true));
    };

    const sensorHide = async (data: sensor, e: any) => {
        e.stopPropagation();
        try {
            const willDelete = await swal({
                title: "Are you sure?",
                text: `Are you sure that you want to ${data?.status ? "hide" : "unhide"
                    }  ${data?.name} sensor`,
                icon: "warning",
                dangerMode: true,
            });
            if (willDelete) {
                swal(
                    data?.name,
                    `This Sensor has been ${data?.status ? "hidden" : "unhide"}!`,
                    "success"
                );
                const sensor = {
                    status: !data?.status,
                };
                await UpdateSensorStatusStateService(
                    data?.device_id,
                    data?.type,
                    data?.esn,
                    sensor
                );
                await GetDeviceSensorListServiceHandler(
                    data?.device_id,
                    sensorCurrentPage
                );
            }
        } catch (error: any) {
            swal("Oops!", `${error?.response?.data?.error?.message}`, "error");
        }
    };

    console.log("Bak: last updated " + data?.last_updated + " " + data?.type);

    const SensorOutputLineDataShow = (props: any) => {
        const { enable, pulse, outputlineName } = props;

        return (
            <div
                className="flex items-center justify-between"
                style={{ opacity: enable ? "1" : "0.3" }}
            >
                <div className="flex items-center gap-1">
                    <GoDotFill color="#2047ab" />
                    <p className="output_text_sensor_card">
                        {outputlineName ? outputlineName : "Output Line"}
                    </p>
                </div>
                <div>
                    <p className="output_text_sensor_card">pulse value - {pulse}</p>
                </div>
            </div>
        );
    };

    return (
        <>
            <div
                onClick={(e: any) => sensorDetailData(data, "sensorDetail", e)}
                className={
                    data.alerting
                        ? "deviceCard flex flex-col gap-4 cursor-pointer alertCardBorder relative"
                        : !data.armed
                            ? "deviceCard flex flex-col gap-4 cursor-pointer"
                            : "deviceCard flex flex-col gap-4 cursor-pointer activeCardBorder"
                }
            >
                <div className="flex justify-end items-start">
                    <div className="flex">
                        {(userInfo.role === "Super Admin" || userInfo.role === "Admin") &&
                            (data?.status ? (
                                <Tooltip title="Show Sensor">
                                    <IconButton onClick={(e: any) => sensorHide(data, e)}>
                                        <AiOutlineEye color="#131A2B" fontSize={20} />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Hide Sensor">
                                    <IconButton onClick={(e: any) => sensorHide(data, e)}>
                                        <FaRegEyeSlash color="#131A2B" fontSize={20} />
                                    </IconButton>
                                </Tooltip>
                            ))}
                        {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
                            <Tooltip title="Sensor Setting">
                                <IconButton
                                    onClick={(e: any) =>
                                        handleSensorDetailModal("sensorSetting", data, e)
                                    }
                                >
                                    <LuSettings color="#131A2B" fontSize={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {data.type !== "31" && (
                            <Tooltip title="Sensor History">
                                <IconButton
                                    onClick={(e: any) =>
                                        sensorHistoryData("sensorHistoryDrawer", data, e)
                                    }
                                >
                                    <BsDatabase color="#131A2B" fontSize={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
                            <Tooltip title="Delete Sensor">
                                <IconButton
                                    onClick={(e: any) =>
                                        handleSensorDetailModal("deleteSensor", data, e)
                                    }
                                >
                                    <BsTrash color="#131A2B" fontSize={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {data?.battery === null ? (
                            <Tooltip title="Battery">
                                <IconButton>
                                    <MdBattery0Bar color="#767676" fontSize={20} />
                                </IconButton>
                            </Tooltip>
                        ) : data?.battery === "0" ? (
                            <Tooltip title="Battery">
                                <IconButton>
                                    <MdBatteryAlert color="#767676" fontSize={20} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Battery">
                                <IconButton>
                                    <MdBattery3Bar color="#767676" fontSize={20} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className="flex gap-4 items-center">
                    <div className="sensorIconContainer">
                        <img
                            src={data?.image_url}
                            alt={data?.name}
                            className="sensorIcon"
                        />
                    </div>
                    <div>
                        <p className="sensorName">{data.name}</p>
                        <p className="cordinates">{`${data?.type} / ${data?.esn}`}</p>
                    </div>
                </div>
                <div className="cardItems">
                    {status === "pending" ? (
                        <p className="time" >Loading...</p>
                    ) : status === "error" ? (
                        <p className="time" >Error: ${error.message}</p>
                    ) : timezoneResp === null ? (
                        <p className="time">Could not get timezone</p>
                    ) : (
                        <p className="time">
                        Updated At - {
                            moment(data?.last_updated).tz(timezoneResp.timezone).format("YYYY-MM-DD H:mm:ss a")
                            //data?.last_updated
                        }
                        </p>
                    )}
                    {data.type !== "31" && (
                        <p className="value">
                            Current Value :{" "}
                            {translateLastValue(
                                offset,
                                data?.last_value,
                                data?.units,
                                temperaturePreference
                            )}
                            <span>{getDecorator(data?.units)}</span>
                            <span>
                                {data?.last_value != null && (
                                    <>
                                        {translateUnits(
                                            selectedSensor?.unit ?? data?.units,
                                            temperaturePreference
                                        )}
                                    </>
                                )}
                            </span>
                        </p>
                    )}
                </div>

                {data?.graph ? (
                    shouldShowData(data) && (
                        <SensorCardGraphData
                            deviceId={selectedDevice?.id}
                            sensorType={data?.type}
                            sensorEsn={data?.esn}
                            sensor={data}
                            offset={offset}
                            timeSeries={data?.graph}
                            graphOnCardHeading={true}
                            gpsLocation={data?.gps}
                        />
                    )
                ) : (
                    <div className="w-full flex items-center justify-center">
                        <ClipLoader
                            color={"#2047ab"}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}

                {shouldShowOutputLines(data) && (
                    <>
                        {selectedDevice && (
                            <div>
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_1}
                                    pulse={selectedDevice?.output_line_frequency_1}
                                    outputlineName={selectedDevice?.output_line_name_1}
                                />
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_2}
                                    pulse={selectedDevice?.output_line_frequency_2}
                                    outputlineName={selectedDevice?.output_line_name_2}
                                />
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_3}
                                    pulse={selectedDevice?.output_line_frequency_3}
                                    outputlineName={selectedDevice?.output_line_name_3}
                                />
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_4}
                                    pulse={selectedDevice?.output_line_frequency_4}
                                    outputlineName={selectedDevice?.output_line_name_4}
                                />
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_5}
                                    pulse={selectedDevice?.output_line_frequency_5}
                                    outputlineName={selectedDevice?.output_line_name_5}
                                />
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_6}
                                    pulse={selectedDevice?.output_line_frequency_6}
                                    outputlineName={selectedDevice?.output_line_name_6}
                                />
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_7}
                                    pulse={selectedDevice?.output_line_frequency_7}
                                    outputlineName={selectedDevice?.output_line_name_7}
                                />
                                <SensorOutputLineDataShow
                                    enable={selectedDevice?.output_line_enabled_8}
                                    pulse={selectedDevice?.output_line_frequency_8}
                                    outputlineName={selectedDevice?.output_line_name_8}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
function shouldShowOutputLines(sensor: sensor) {
    return sensor.type === "31";
}
function shouldShowData(data: sensor) {
    return data?.type !== "31";
}

function translateUnits(units: string, temperaturePreference: string) {
    if (units === "C" && temperaturePreference === "F") {
        return "F";
    }
    return units;
}

function getDecorator(units: string) {
    switch (units) {
        case "C":
        case "F":
            return "°";
        default:
            return "";
    }
}
