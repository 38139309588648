
import {useQuery, useQueryClient, UseQueryResult} from '@tanstack/react-query' ;
import { GetTimezone, GetTimezoneResponse } from '../../service/deviceTimezone';


export function useTimezone(deviceId: string): UseQueryResult<GetTimezoneResponse, Error> {
    return useQuery({
        queryKey: ["timezone"],
        queryFn: async (): Promise<GetTimezoneResponse> =>{ return GetTimezone(deviceId); }
    });
}
