import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import component from "../../../constants/urls";
import { UpdateModalOpenStatus } from "../../../redux/reducer/commonReducer";
import Label from "../formInput/Label";
import { UpdateNewDeviceInfo } from "../../../redux/reducer/devices";
import useDevicesServiceHandler from "../../../hooks/serviceHandler/devices";
import { NUMBER_ONLY_REGEX } from "../../../constants/constant";

export default function RegisterDeviceInputModal() {
  const [deviceId, setDeviceId] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { newDevice, selectedDevice } = useSelector(
    (store: any) => store.device
  );

  const onChangeText = (e: any) => {
    const { value } = e.target;
    setDeviceId(value);
    onTxtChangeValidation(value);
  };

  const onTxtChangeValidation = (value: string) => {
    if (!value.trim()) {
      setError("Required !");
    } else if (!NUMBER_ONLY_REGEX.test(value)) {
      setError("Device id should be integer only !");
    } else if (value?.length !== 20) {
      setError("Invalid Device Id!");
    } else {
      setError("");
    }
  };

  const handleRoute = (url: string, e: any) => {
    e.preventDefault();
    if (!deviceId.length) {
      setError("Required !");
    } else {
      if (deviceId.length === 20) {
        setError("");
        dispatch(UpdateNewDeviceInfo({ key: "deviceId", value: deviceId }));
        navigate(url);
        dispatch(UpdateModalOpenStatus(false));
      } else {
        setError("Invalid Device Id!");
      }
    }
  };

  return (
    <>
      <div className="">
        <form action="" className="formModalMinHeight">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col">
              <Label label="Device ID" required={true} />
              <input
                className="registerFormInput"
                type="text"
                placeholder="Enter the ID of the device you are registering."
                value={deviceId}
                onChange={onChangeText}
              />
              {error && <p className="errorMessage">{error}</p>}
            </div>
            {/* {userInfo.role === "Super Admin" && (
              <>
                <p className="text-center">or</p>
                <div className="flex flex-col">
                  <input
                    type="file"
                    hidden
                    id="uploadCsvFile"
                    name="file"
                    // accept=".csv"
                    onChange={handleDeviceUpload}
                  />
                  <div className="relative">
                    <input
                      type="text"
                      className="registerFormInput"
                      placeholder="Upload CSV File"
                      value={fileName}
                      disabled
                    />
                    <label
                      htmlFor="uploadCsvFile"
                      className="cursor-pointer uploadCsvButton"
                    >
                      Upload CSV File
                    </label>
                    <label
                      // htmlFor="uploadCsvFile"
                      className="cursor-pointer uploadCsvCanelButton"
                      onClick={cancelUpload}
                    >
                      Cancel
                    </label>
                  </div>

                  {fileTypeError && (
                    <p className="errorMessage">{fileTypeError}</p>
                  )}
                </div>
              </>
            )} */}
          </div>
          <div className="flex justify-end items-center mt-5">
            <button
              className="primaryButtonFilled"
              onClick={(event: any) => {
                handleRoute(component.registerDevice.url, event);
              }}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
