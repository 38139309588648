import "./header.css";
import "../sidebar/sidebar.css";
import { RxDashboard } from "react-icons/rx";
import {
  MdOutlineDevices,
  MdOutlineHistory,
  MdOutlineInventory,
} from "react-icons/md";
import { FiBriefcase, FiUsers } from "react-icons/fi";
import { FaUserPlus } from "react-icons/fa";
import bellIcon from "../../../assets/images/icons/bellIcon.svg";
import { PiTreeStructure } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { UpdateIsLoginState } from "../../../redux/reducer/user";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { LiaBarsSolid } from "react-icons/lia";
import { useLocation, useNavigate } from "react-router-dom";
import component from "../../../constants/urls";
import { UpdateSidebarExpandStatus } from "../../../redux/reducer/commonReducer";
import { VscSignOut } from "react-icons/vsc";
import { BiSolidUser, BiHelpCircle } from "react-icons/bi";
import { RiAccountCircleFill } from "react-icons/ri";
import logo from "../../../assets/images/cabin-tech-logo.svg";

export default function HeaderNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { pathname } = location;
  const [userExpand, setUserExpand] = useState(false);
  const { sidebarExpand } = useSelector((state: any) => state.common);
  const { userInfo } = useSelector((state: any) => state.user);
  const { notificationUnreadCount } = useSelector(
    (store: any) => store.notification
  );

  const openSidebar = (status: boolean) => {
    dispatch(UpdateSidebarExpandStatus(status));
  };

  const handleRoute = (url: string) => {
    navigate(url);
  };
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleLogout = () => {
    dispatch(UpdateIsLoginState(false));
    navigate(component.home.url);
  };
  

  return (
    <>
      <div className="navbar_container">
        <div className="navbar">
          <div className="navbar-left">
            <div className="flex">
              {!sidebarExpand ? (
                <LiaBarsSolid
                  color="#495057"
                  fontSize={20}
                  fontWeight={700}
                  onClick={() => openSidebar(true)}
                />
              ) : (
                <RxCross2
                  color="#495057"
                  fontSize={20}
                  onClick={() => openSidebar(false)}
                />
              )}
            </div>
            <div
              className="sidebarLogo"
              onClick={() => handleRoute(component.dashboard.url)}
            >
              <img src={logo} alt="logo" className="w-full h-full" />
            </div>
          </div>
          <div className="navbar-right">
            <div className="flex items-center gap-5"></div>

            <div className="flex items-center gap-3">
              <p className="welcomeText">
                {`Welcome ${userInfo?.name}!`} <span>({userInfo?.role})</span>
              </p>
              <div
                className="bellIconContainer"
                onClick={() => handleRoute(component.notification.url)}
              >
                <img src={bellIcon} alt="bell" className="bellIcon" />
                <div className="notification_count">
                  <p className="count">{notificationUnreadCount}</p>
                </div>
              </div>
              <div className="userImageContainer">
                {userInfo?.name && (
                  <div
                    className="userAvtar"
                    onClick={() => handleRoute(component.account.url)}
                  >
                    {userInfo?.name[0].substring(0, 1).toUpperCase()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {sidebarExpand && (
          <div
            className="sidebar_expand_container"
            onClick={() => openSidebar(false)}
          >
            <div className="sidebar-expand">
              <ul className="sidebar_ul">
                <li>
                  <div
                    className="sidebar_li"
                    onClick={() => handleRoute(component.dashboard.url)}
                  >
                    <RxDashboard
                      fontSize={18}
                      color={
                        pathname === component.dashboard.url
                          ? "#2047AB"
                          : "#919191"
                      }
                      className="sidebarIcons"
                    />
                    <p
                      className={
                        pathname === component.dashboard.url
                          ? "sidebar_item sidebar_item_active"
                          : "sidebar_item"
                      }
                    >
                      Dashboard
                    </p>
                  </div>
                </li>
                {userInfo.role === "Super Admin" && (
                  <li>
                    <div
                      className="sidebar_li"
                      onClick={() =>
                        handleRoute(component.inventoryDeviceList.url)
                      }
                    >
                      <MdOutlineInventory
                        fontSize={18}
                        color={
                          pathname === component.inventoryDeviceList.url
                            ? "#2047AB"
                            : "#919191"
                        }
                        className="sidebarIcons"
                      />
                      <p
                        className={
                          pathname === component.inventoryDeviceList.url
                            ? "sidebar_item sidebar_item_active"
                            : "sidebar_item"
                        }
                      >
                        Device Inventory
                      </p>
                    </div>
                  </li>
                )}
                <li>
                  <div
                    className="sidebar_li"
                    onClick={() => handleRoute(component.devices.url)}
                  >
                    <MdOutlineDevices
                      fontSize={18}
                      color={
                        pathname === component.devices.url
                          ? "#2047AB"
                          : "#919191"
                      }
                      className="sidebarIcons"
                    />
                    <p
                      className={
                        pathname === component.devices.url
                          ? "sidebar_item sidebar_item_active"
                          : "sidebar_item"
                      }
                    >
                      Active Devices
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="sidebar_li"
                    onClick={() => handleRoute(component.history.url)}
                  >
                    <MdOutlineHistory
                      fontSize={18}
                      color={
                        pathname === component.history.url
                          ? "#2047AB"
                          : "#919191"
                      }
                      className="sidebarIcons"
                    />
                    <p
                      className={
                        pathname === component.history.url
                          ? "sidebar_item sidebar_item_active"
                          : "sidebar_item"
                      }
                    >
                      History
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="sidebar_li"
                    onClick={() => handleRoute(component.manageDevices.url)}
                  >
                    <PiTreeStructure
                      fontSize={18}
                      color={
                        pathname === component.manageDevices.url
                          ? "#2047AB"
                          : "#919191"
                      }
                      className="sidebarIcons"
                    />
                    <p
                      className={
                        pathname === component.manageDevices.url
                          ? "sidebar_item sidebar_item_active"
                          : "sidebar_item"
                      }
                    >
                      Manage Devices
                    </p>
                  </div>
                </li>
                {/* <li>
            <div
              className="sidebar_li"
              onClick={() => handleRoute(component.dashboard.url)}
            >
              <BiSolidUser
                fontSize={18}
                color={
                  pathname === component.userListing.url ||
                  pathname === component.roleList.url
                    ? "#2047AB"
                    : "#919191"
                }
                className="sidebarIcons"
              />
              <p
                className={
                  pathname === component.userListing.url ||
                  pathname === component.roleList.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                User Management
              </p>
            </div>
          </li> */}
                {(userInfo.role === "Super Admin") ? (
                  <li>
                    <div
                      className="sidebar_li"
                      onClick={() => setUserExpand(!userExpand)}
                    >
                      <BiSolidUser
                        color={
                          pathname === component.userListing.url ||
                          pathname === component.roleList.url
                            ? "#2047AB"
                            : "#919191"
                        }
                        className="sidebarIcons"
                      />
                      {sidebarExpand && (
                        <p
                          className={
                            pathname === component.userListing.url ||
                            pathname === component.roleList.url
                              ? "sidebar_item sidebar_item_active"
                              : "sidebar_item"
                          }
                        >
                          User Management
                        </p>
                      )}
                      {userExpand && (
                        <RiArrowDropUpLine
                          fontSize={30}
                          color={
                            pathname === component.userListing.url
                              ? "#2047AB"
                              : "#919191"
                          }
                        />
                      )}
                      {!userExpand && (
                        <RiArrowDropDownLine
                          fontSize={30}
                          color={
                            pathname === component.userListing.url
                              ? "#2047AB"
                              : "#919191"
                          }
                        />
                      )}
                    </div>
                    {userExpand && (
                      <ul className="sidebar_ul_item">
                        {userInfo.role === "Super Admin" && (
                          <li
                            className="items"
                            onClick={() => handleRoute(component.roleList.url)}
                          >
                            <FaUserPlus
                              fontSize={14}
                              color={
                                pathname === component.roleList.url
                                  ? "#2047AB"
                                  : "#919191"
                              }
                            />
                            <p
                              className={
                                pathname === component.roleList.url
                                  ? "activeClass"
                                  : "inactiveClass"
                              }
                            >
                              {" "}
                              Roles
                            </p>
                          </li>
                        )}
                        <li
                          className="items"
                          onClick={() => handleRoute(component.userListing.url)}
                        >
                          <FiUsers
                            fontSize={14}
                            color={
                              pathname === component.userListing.url
                                ? "#2047AB"
                                : "#919191"
                            }
                          />
                          <p
                            className={
                              pathname === component.userListing.url
                                ? "activeClass"
                                : "inactiveClass"
                            }
                          >
                            {" "}
                            Users
                          </p>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : null}

                {userInfo.role === "Super Admin" && (
                  <li>
                    <div
                      className="sidebar_li"
                      onClick={() => handleRoute(component.firmware.url)}
                    >
                      <FiBriefcase
                        fontSize={18}
                        color={
                          pathname === component.firmware.url
                            ? "#2047AB"
                            : "#919191"
                        }
                        className="sidebarIcons"
                      />
                      <p
                        className={
                          pathname === component.firmware.url
                            ? "sidebar_item sidebar_item_active"
                            : "sidebar_item"
                        }
                      >
                        Firmware
                      </p>
                    </div>
                  </li>
                )}
                <li>
                  <div
                    className="sidebar_li"
                    onClick={() => handleRoute(component.account.url)}
                  >
                    <RiAccountCircleFill
                      fontSize={18}
                      color={
                        pathname === component.account.url
                          ? "#2047AB"
                          : "#919191"
                      }
                    />
                    <p
                      className={
                        pathname === component.account.url
                          ? "sidebar_item sidebar_item_active"
                          : "sidebar_item"
                      }
                    >
                      Account
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="sidebar_li"
                    onClick={() =>
                      openInNewTab(
                        "https://www.tracertechnologysystems.com/support/cabin-tech"
                      )
                    }
                  >
                    <BiHelpCircle
                      fontSize={18}
                      color={
                        pathname === component.faq.url ? "#2047AB" : "#919191"
                      }
                      className="sidebarIcons"
                    />
                    <p
                      className={
                        pathname === component.faq.url
                          ? "sidebar_item sidebar_item_active"
                          : "sidebar_item"
                      }
                    >
                      Help / FAQs
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="sidebar_li"
                    onClick={() => {
                      handleRoute(component.logout.url);
                      handleLogout();
                    }}
                  >
                    <VscSignOut
                      fontSize={18}
                      color={
                        pathname === component.logout.url
                          ? "#2047AB"
                          : "#919191"
                      }
                    />
                    <p
                      className={
                        pathname === component.logout.url
                          ? "sidebar_item sidebar_item_active"
                          : "sidebar_item"
                      }
                    >
                      Logout
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
