import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import SensorCurrentValueSection from "../../sensor/SensorCurrentValueSection";
import SensorDetailHeaderSection from "../../sensor/SensorDetailHeaderSection";
import { sensor } from "../../../interface/reducer/sensor";
import SensorSetting from "../../cards/SensorSetting";
import { GetSensorGraphData } from "../../../service/sensors";
import { ClipLoader } from "react-spinners";
import { UpdateSelectedSensor } from "../../../redux/reducer/sensors";
import OutputLineDeviceSetting from "../../cards/OutputLineDeviceSetting";
import useDebouncedEffect from "../../../hooks/sensorSetting/debounceEffect";
import useDevicesServiceHandler from "../../../hooks/serviceHandler/devices";
import { toast } from "react-toastify";
import { UpdateDeviceSettingService } from "../../../service/devices";
import SensorCardGraphData from "../../cards/SensorCardGraphData";
import useSensorServiceHandler from "../../../hooks/serviceHandler/sensor";
import { useTimezone } from "../../../hooks/common/useTimezone";

export default function SensorDetail() {
  const dispatch = useDispatch();
  const { GetDeviceInfoByIdServiceHandler } = useDevicesServiceHandler();
  const { GetDeviceSensorDetailDataByIdServiceHandler } =
    useSensorServiceHandler();
  const { selectedSensor } = useSelector((store: any) => store.sensor);
  const { userInfo } = useSelector((store: any) => store.user);
  const { selectedDevice } = useSelector((store: any) => store.device);
  const [offset, setOffset] = React.useState(selectedSensor?.manual_offset);
  const [timeseriesData, setTimeseriesData] = React.useState<any>();

  const [outputLinesEnabled, setOutputLinesEnabled] = React.useState([
    selectedDevice.output_line_enabled_1,
    selectedDevice.output_line_enabled_2,
    selectedDevice.output_line_enabled_3,
    selectedDevice.output_line_enabled_4,
    selectedDevice.output_line_enabled_5,
    selectedDevice.output_line_enabled_6,
    selectedDevice.output_line_enabled_7,
    selectedDevice.output_line_enabled_8,
  ]);

  const [outputLineNames, setOutputLineNames] = React.useState([
    selectedDevice.output_line_name_1,
    selectedDevice.output_line_name_2,
    selectedDevice.output_line_name_3,
    selectedDevice.output_line_name_4,
    selectedDevice.output_line_name_5,
    selectedDevice.output_line_name_6,
    selectedDevice.output_line_name_7,
    selectedDevice.output_line_name_8,
  ]);

  const [outputLineFrequencies, setOutputLineFrequencies] = React.useState([
    selectedDevice.output_line_frequency_1,
    selectedDevice.output_line_frequency_2,
    selectedDevice.output_line_frequency_3,
    selectedDevice.output_line_frequency_4,
    selectedDevice.output_line_frequency_5,
    selectedDevice.output_line_frequency_6,
    selectedDevice.output_line_frequency_7,
    selectedDevice.output_line_frequency_8,
  ]);

  const handleOutputLineEnabledChange = React.useCallback(
    (line: any, enabled: any) => {
      outputLinesEnabled[line - 1] = enabled;
      setOutputLinesEnabled([...outputLinesEnabled]);
    },
    [outputLinesEnabled, setOutputLinesEnabled]
  );

  const handleOutputLineNameChange = React.useCallback(
    (line: any, name: any) => {
      outputLineNames[line - 1] = name;
      setOutputLineNames([...outputLineNames]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOutputLineNames, ...outputLineNames]
  );

  const handleOutputLineFrequencyChange = React.useCallback(
    (line: any, value: any) => {
      outputLineFrequencies[line - 1] = Math.max(0, Math.min(value, 999));
      setOutputLineFrequencies([...outputLineFrequencies]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOutputLineFrequencies, ...outputLineFrequencies]
  );

  useDebouncedEffect(
    () => {
      const obj = {
        name: selectedDevice.name,
        armed: selectedDevice.armed,
        ct_disable_audible: selectedDevice?.ct_disable_audible,
        ct_disable_flashing_buttons:
          selectedDevice?.ct_disable_flashing_buttons,
        ct_disable_status_indicator:
          selectedDevice?.ct_disable_status_indicator,
        ct_disable_camera: selectedDevice?.ct_disable_camera,
        ct_disable_motion_armed: selectedDevice?.ct_disable_motion_armed,
      };
      for (let i = 1; i < 9; i++) {
        const lineName = `output_line_name_${i}`;
        const lineEnabled = `output_line_enabled_${i}`;
        const frequency = `output_line_frequency_${i}`;
        obj[lineName] = outputLineNames[i - 1];
        obj[lineEnabled] = outputLinesEnabled[i - 1];
        obj[frequency] = outputLineFrequencies[i - 1];
      }
      UpdateDeviceSettingService(selectedDevice?.id, obj)
        .then(() => {
          if (selectedSensor?.type === "31") {
            toast.success("Output lines updated successfully");
          }
          GetDeviceInfoByIdServiceHandler(selectedDevice?.id);
        })
        .catch((e: any) => {
          console.error(e);
          alert("An error occurred. Please try again.");
        });
    },
    500,
    [...outputLineNames, ...outputLinesEnabled, ...outputLineFrequencies]
  );

  useEffect(() => {
    GetSensorGraphData(
      selectedDevice?.id,
      selectedSensor?.type,
      selectedSensor?.esn
    ).then((res) => {
      const { result } = res?.data;
      if (result) {
        if (
          !timeseriesData ||
          timeseriesData?.last_updated !== result?.last_updated
        ) {
          setTimeseriesData(result);
        }
      }
    });
  }, [
    selectedDevice?.device_id,
    selectedSensor?.type,
    selectedSensor?.esn,
    GetSensorGraphData,
    selectedSensor,
    timeseriesData,
  ]);

  useEffect(() => {
    GetDeviceSensorDetailDataByIdServiceHandler(
      selectedDevice?.id,
      selectedSensor?.type,
      selectedSensor?.esn
    );
  }, [selectedDevice?.id, selectedSensor?.type, selectedSensor?.esn]);

  const { status, data: timezoneResp } = useTimezone(selectedDevice?.id);

  return (
    <>
      <div className="flex flex-col gap-10 p-8 drawerWidth">
        <SensorDetailHeaderSection sensor={selectedSensor} />

        {shouldShowCurrentValue(selectedSensor) && status !== 'pending' && (
          <SensorCurrentValueSection
            sensor={selectedSensor}
            lastValue={selectedSensor?.last_value}
            units={selectedSensor?.units}
            time={
                moment(selectedSensor?.last_updated).tz(timezoneResp.timezone).format("YYYY-MM-DD HH:mm:ss")
            }
            offset={offset}
          />
        )}
        {timeseriesData && (
          <>
            {shouldShowData(selectedSensor) && (
              <SensorCardGraphData
                deviceId={selectedDevice?.id}
                sensorType={selectedSensor?.type}
                sensorEsn={selectedSensor?.esn}
                sensor={selectedSensor}
                offset={offset}
                timeSeries={timeseriesData}
                graphOnCardHeading={false}
                gpsLocation={selectedSensor?.gps}
              />
            )}
          </>
        )}

        {/* {timeseriesData ? (
          <>
            {shouldShowData(selectedSensor) && (
              <SensorCardGraphData
                deviceId={selectedDevice?.id}
                sensorType={selectedSensor?.type}
                sensorEsn={selectedSensor?.esn}
                sensor={selectedSensor}
                offset={offset}
                timeSeries={timeseriesData}
                graphOnCardHeading={false}
                gpsLocation={selectedSensor?.gps}
              />
            )}
          </>
        ) : (
          <div className="w-full flex items-center justify-center">
            <ClipLoader
              color={"#2047ab"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )} */}

        {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
          <SensorSetting
            sensor={selectedSensor}
            onSensorChange={(sensor: sensor) =>
              dispatch(UpdateSelectedSensor(sensor))
            }
            offset={offset}
            onOffsetChange={(offset: any) => setOffset(offset)}
            supportsOutputLines={selectedDevice?.supports_output_lines}
            outputLineNames={[
              selectedDevice?.output_line_name_1,
              selectedDevice?.output_line_name_2,
              selectedDevice?.output_line_name_3,
              selectedDevice?.output_line_name_4,
              selectedDevice?.output_line_name_5,
              selectedDevice?.output_line_name_6,
              selectedDevice?.output_line_name_7,
              selectedDevice?.output_line_name_8,
            ]}
          />
        )}

        {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
          <>
            {shouldShowOutputLines(selectedSensor) && (
              <OutputLineDeviceSetting
                outputLinesEnabled={outputLinesEnabled}
                outputLineNames={outputLineNames}
                onEnabledChange={handleOutputLineEnabledChange}
                onNameChange={handleOutputLineNameChange}
                onFrequencyChange={handleOutputLineFrequencyChange}
                frequencies={outputLineFrequencies}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

function shouldShowCurrentValue(sensor: sensor) {
  return sensor.type !== "31";
}

function shouldShowData(sensor: sensor) {
  return sensor.type !== "31";
}

function shouldShowOutputLines(sensor: sensor) {
  return sensor.type === "31";
}
