import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import useSensorServiceHandler from "../../hooks/serviceHandler/sensor";
import { sensor } from "../../interface/reducer/sensor";
import SensorCard from "../cards/SensorCard";
import DrawerComponent from "../common/drawer/DrawerComponent";
import SensorDetail from "../common/drawer/SensorDetail";
import ModalComponent from "../common/modal/Modal";
import DeleteModal from "../common/modal/DeleteModal";
import SensorSettingModal from "../common/modal/SensorSettingModal";
import SensorLocationModal from "../common/modal/SensorLocationModal";
import DeviceByIdMapView from "../common/map/DeviceByIdMapView";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import { Link } from "react-router-dom";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import DeleteDeviceModal from "../common/modal/DeleteDeviceModal";
import DeviceInfoSettingCard from "../device/DeviceInfoSettingCard";
import SendMessage from "../device/SendMessage";
import DeleteSensorDataModal from "../common/modal/DeleteSensorDataModal";
import styled from "@emotion/styled";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageBreadcrumbs from "../common/PageBreadcrumbs";
import useDebouncedEffect from "../../hooks/sensorSetting/debounceEffect";
import { UpdateDeviceSettingService } from "../../service/devices";
import { ClipLoader } from "react-spinners";
import SensorHistory from "../common/drawer/SensorHistory";
import {
  UpdateSensorCurrentPage,
  UpdateSensorList,
} from "../../redux/reducer/sensors";
import Pagination from "react-js-pagination";

const PageSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-top: 1rem;

  h1 {
    flex: 1;
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: flex-end;
`;

export default function SensorListing() {
  const sensorsPerPage = 12;
  const dispatch = useDispatch();
  const { UpdateDeviceGpsServiceHandler, GetDeviceInfoByIdServiceHandler } =
    useDevicesServiceHandler();
  const {
    GetDeviceSensorListServiceHandler,
    GetDeviceSensorDetailDataByIdServiceHandler,
    SensorHistoryServiceHandler,
  } = useSensorServiceHandler();
  const { selectedDevice } = useSelector((state: any) => state.device);
  const { sensorList, selectedSensor, sensorCurrentPage, allSensorCount } =
    useSelector((store: any) => store.sensor);

  const { drawerExpand, modalIsOpen, modalName, isLoader, drawerName } =
    useSelector((store: any) => store.common);
  const { userInfo } = useSelector((store: any) => store.user);

  const [useManualLocation, setUseManualLocation] = useState(
    selectedDevice?.manual_gps
  );
  const [location, setLocation] = useState(selectedDevice?.gps ?? "");
  const [showHiddenSensors, setShowHiddenSensors] = useState(true);
  const [name, setName] = useState(selectedDevice.name);
  const [armed, setArmed] = useState(selectedDevice.armed);

  const [audible, setAudible] = useState(selectedDevice.ct_disable_audible);
  const [flashingButtonsEnabled, setFlashingButtonsEnabled] = useState(
    selectedDevice.ct_disable_flashing_buttons
  );
  const [statusIndicatorEnabled, setStatusIndicatorEnabled] = useState(
    selectedDevice.ct_disable_status_indicator
  );
  const [cameraEnabled, setCameraEnabled] = useState(
    selectedDevice.ct_disable_camera
  );
  const [motionEnabled, setMotionEnabled] = useState(
    selectedDevice.ct_disable_motion_armed
  );
  const [showNotFound, setShowNotFound] = useState(false);

  const [latitude, longitude] =
    location.length > 0
      ? location
          ?.split(" ")
          ?.map((c: any) => c?.trim())
          ?.map((c: any) => Number(c))
      : [0, 0];

  const handleDeleteDevice = () => {
    dispatch(UpdateModalName("deleteDeviceModal"));
    dispatch(UpdateModalOpenStatus(true));
  };

  const handleUseManualLocationChange = useCallback(
    (e: any) => {
      setUseManualLocation(e.target.checked);
    },
    [setUseManualLocation]
  );

  const handleUseCurrentLocationClick = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (e) => {
        setLocation(`${e.coords.latitude} ${e.coords.longitude}`);
      },
      (e) => {
        console.error(e);
        alert("Sorry, an error occurred getting your current position.");
      }
    );
  }, []);

  const handleSaveClick = async (e: any) => {
    e.preventDefault();
    await UpdateDeviceGpsServiceHandler(selectedDevice?.id, {
      ...selectedDevice,
      gps: useManualLocation ? location : "",
    });
  };

  const fetchSensor = async () => {
    await GetDeviceInfoByIdServiceHandler(selectedDevice?.id);
    await GetDeviceSensorListServiceHandler(
      selectedDevice?.id,
      sensorCurrentPage
    );
    if (drawerExpand) {
      await GetDeviceSensorDetailDataByIdServiceHandler(
        selectedDevice?.id,
        selectedSensor?.type,
        selectedSensor?.esn
      );
      await SensorHistoryServiceHandler(
        selectedDevice?.id,
        selectedSensor?.type,
        selectedSensor?.esn
      );
    }
  };

  useEffect(() => {
    fetchSensor();
  }, []);

  useDebouncedEffect(
    () => {
      const obj = {
        name,
        armed,
        ct_disable_audible: audible,
        ct_disable_flashing_buttons: flashingButtonsEnabled,
        ct_disable_status_indicator: statusIndicatorEnabled,
        ct_disable_camera: cameraEnabled,
        ct_disable_motion_armed: motionEnabled,
      };
      for (let i = 1; i < 9; i++) {
        const lineName = `output_line_name_${i}`;
        const lineEnabled = `output_line_enabled_${i}`;
        obj[lineName] = selectedDevice[lineName];
        obj[lineEnabled] = selectedDevice[lineEnabled];
      }
      if (!drawerExpand) {
        UpdateDeviceSettingService(selectedDevice?.id, obj)
          .then(() => {
            toast.success("Device updated successfully");
            GetDeviceInfoByIdServiceHandler(selectedDevice?.id);
          })
          .catch((e) => {
            console.error(e);
            toast.error("An error occurred. Please try again.");
          });
      }
    },
    500,
    [
      name,
      armed,
      audible,
      flashingButtonsEnabled,
      statusIndicatorEnabled,
      cameraEnabled,
      motionEnabled,
    ]
  );

  const handlePageChange = async (pageNumber: number) => {
    dispatch(UpdateSensorCurrentPage(pageNumber));
    await GetDeviceSensorListServiceHandler(selectedDevice?.id, pageNumber);
  };

  useEffect(() => {
    return () => {
      dispatch(UpdateSensorList([]));
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowNotFound(true);
    }, 3000);
  }, []);

  return (
    <>
      {drawerExpand &&
        (drawerName === "sensorHistoryDrawer" ? (
          <DrawerComponent direction={"right"}>
            <SensorHistory />
          </DrawerComponent>
        ) : drawerName === "sensorDetail" ? (
          <DrawerComponent direction={"right"}>
            <SensorDetail />
          </DrawerComponent>
        ) : (
          ""
        ))}
      {modalIsOpen &&
        (modalName === "deleteSensor" ? (
          <ModalComponent heading="Delete Sensor">
            <DeleteModal />
          </ModalComponent>
        ) : modalName === "deleteSensorData" ? (
          <ModalComponent heading="Delete Sensor Data">
            <DeleteSensorDataModal />
          </ModalComponent>
        ) : modalName === "sensorSetting" ? (
          <ModalComponent heading="Sensor Setting">
            <SensorSettingModal />
          </ModalComponent>
        ) : modalName === "sensorLocation" ? (
          <ModalComponent heading="Sensor Location">
            <SensorLocationModal />
          </ModalComponent>
        ) : modalName === "deleteDeviceModal" ? (
          <ModalComponent
            heading={`delete ${
              selectedDevice?.name ? selectedDevice?.name : "Unnamed Device"
            } device`}
          >
            <DeleteDeviceModal />
          </ModalComponent>
        ) : (
          ""
        ))}
      <div className="screenContainerPadding dashboardContainer">
        <div>
          <PageBreadcrumbs time={60} autoRefresh onRefresh={fetchSensor}>
            <Link to="/">Dashboard</Link>
          </PageBreadcrumbs>

          <div className="flex justify-between items-center mb-5">
            <div className="flex flex-col gap-2">
              <p className="pageTitle">
                {selectedDevice.name ? selectedDevice.name : "Unnamed Device"}
              </p>
              <p className="pageSubtitle">{selectedDevice.model_name}</p>
            </div>
            {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
              <div>
                <button className="dangerButton" onClick={handleDeleteDevice}>
                  Delete Device
                </button>
              </div>
            )}
          </div>
          <div className="allDeviceCardContainer cardBoxShadow">
            <div className="flex justify-between items-center mb-4">
              <p className="deviceCardHeading">Sensors</p>
              {(userInfo.role === "Super Admin" ||
                userInfo.role === "Admin") && (
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showHiddenSensors}
                        onChange={(e) => setShowHiddenSensors(e.target.checked)}
                      />
                    }
                    label="Show Hidden Sensors"
                  />
                </div>
              )}
            </div>
              <div className="mb-5">
                <Pagination
                  activePage={sensorCurrentPage}
                  itemsCountPerPage={sensorsPerPage}
                  totalItemsCount={allSensorCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
           {isLoader ? (
              <div className="w-full flex items-center justify-center">
                <ClipLoader
                  color={"#2047ab"}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : sensorList.length ? (
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 pb-5">
                {!showHiddenSensors
                  ? sensorList &&
                    sensorList
                      .filter((item: any) => item.status)
                      ?.map((item: sensor, index: number) => {
                        return <SensorCard data={item} key={index} />;
                      })
                  : sensorList &&
                    sensorList?.map((item: sensor, index: number) => {
                      return <SensorCard data={item} key={index} />;
                    })}
              </div>
            ) : (
              showNotFound && (
                <p className="sensorName text-center mt-6">No Sensor Found</p>
              )
            )}

            {/* {!isLoader && sensorList?.length !== 0 && (
              <div className="mb-5">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pagination.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            )} */}

            {/* {isLoader ? (
              <div className="w-full flex items-center justify-center">
                <ClipLoader
                  color={"#2047ab"}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : sensorList?.length ? (
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 pb-5">
                {!showHiddenSensors
                  ? pagination?.currentData &&
                    pagination?.currentData
                      .filter((item: any) => item.status)
                      ?.map((item: sensor, index: number) => {
                        return <SensorCard data={item} key={index} />;
                      })
                  : pagination?.currentData &&
                    pagination?.currentData?.map(
                      (item: sensor, index: number) => {
                        return <SensorCard data={item} key={index} />;
                      }
                    )}
              </div>
            ) : (
              showNotFound && (
                <p className="sensorName text-center mt-6">No Sensor Found</p>
              )
            )} */}
          </div>
        </div>
        <div
          className={
            userInfo.role === "Super Admin"
              ? "grid grid-cols-1 lg:grid-cols-2 gap-5"
              : "grid grid-cols-1 lg:grid-cols-1 gap-5"
          }
        >
          <div className="allDeviceCardContainer cardBoxShadow">
            <PageSectionHeader>
              <p className="deviceCardHeading">Location</p>
              {(userInfo.role === "Super Admin" ||
                userInfo.role === "Admin") && (
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useManualLocation}
                        onChange={handleUseManualLocationChange}
                        name="audible"
                        color="primary"
                      />
                    }
                    label="Use Manual Location"
                  />
                </div>
              )}
            </PageSectionHeader>
            {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
              <div className="flex justify-between items-center">
                <div className="w-full">
                  <input
                    type="text"
                    name="location"
                    id="location"
                    placeholder="GPS Coordinates"
                    value={location}
                    className="inputFieldMsg"
                    onChange={(e) => setLocation(e.target.value)}
                    disabled={!useManualLocation}
                    style={{
                      opacity: !useManualLocation && "0.4",
                    }}
                  />
                </div>
                <div className="flex">
                  {useManualLocation && (
                    <>
                      <ButtonWrapper>
                        <Button
                          variant="outlined"
                          className="borderedButton"
                          onClick={handleUseCurrentLocationClick}
                        >
                          Use Current Location
                        </Button>
                      </ButtonWrapper>
                    </>
                  )}
                  <ButtonWrapper>
                    <Button
                      variant="contained"
                      className="primaryButtonFilled"
                      disableElevation
                      onClick={handleSaveClick}
                    >
                      Save
                    </Button>
                    <ToastContainer
                      position="top-right"
                      autoClose={1000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </ButtonWrapper>
                </div>
              </div>
            )}
            <div style={{ height: "300px", width: "100%", marginTop: "20px" }}>
              <DeviceByIdMapView latitude={latitude} longitude={longitude} />
            </div>
          </div>
          {userInfo.role === "Super Admin" && <SendMessage />}
        </div>
        {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
          <DeviceInfoSettingCard
            armed={armed}
            name={name}
            deviceId={selectedDevice?.id}
            onNameChange={(n: any) => setName(n)}
            onArmedChange={(a: any) => setArmed(a)}
            onAudibleChange={(_: any, v: any) => setAudible(v)}
            onFlashingButtonsEnabledChange={(_: any, v: any) =>
              setFlashingButtonsEnabled(v)
            }
            onStatusIndicatorEnabledChange={(_: any, v: any) =>
              setStatusIndicatorEnabled(v)
            }
            onCameraEnabledChange={(_: any, v: any) => setCameraEnabled(v)}
            onMotionEnabledChange={(_: any, v: any) => setMotionEnabled(v)}
            audible={audible}
            flashingButtonsEnabled={flashingButtonsEnabled}
            statusIndicatorEnabled={statusIndicatorEnabled}
            cameraEnabled={cameraEnabled}
            motionEnabled={motionEnabled}
            updatedAt={selectedDevice.updated_at}
            lastCheckIn={selectedDevice.last_check_in}
          />
        )}
      </div>
    </>
  );
}
