interface enableRefresh {
    type: "ENABLE_REFRESH";
}

interface disableRefresh {
    type: "DISABLE_REFRESH"
}

type action =
  |  enableRefresh
  |  disableRefresh;

interface state {
    refreshEnabled: boolean,
}
const initialState = {
    refreshEnabled: true, 
};

const RefreshReducer = (state: state = initialState, action: action) => {
    switch (action.type) {
        case "DISABLE_REFRESH": 
            return {
                ...state,
                refreshEnabled: false, 
            };
        case "ENABLE_REFRESH":
            return {
                ...state,
                refreshEnabled: true,
            };
        default: return state;
    }
};

export default RefreshReducer;

export const disableRefresh = (): disableRefresh => {
    return {
        type: "DISABLE_REFRESH",
    }
}

export const enableRefresh = (): enableRefresh => {
    return {
        type: "ENABLE_REFRESH",
    }
}

