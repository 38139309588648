import axios from "axios";
import { getAuthHeader } from "../constants/constant";

export const CreateCustomerIdService = async (data: any) => {
  const url = `${process.env.REACT_APP_URL}v1/subscription`;
  return axios.post(url, data);
};
export const GetAllPriceListService = async (deviceId: string) => {
  const url = `${process.env.REACT_APP_URL}getPriceId/${deviceId}`;
  return axios.get(url, getAuthHeader());
};

export const CreateDeviceSubscriptionService = async (
  subscriptionParams: any
) => {
  const url = `${process.env.REACT_APP_URL}v1/subscription`;
  return axios.post(url, subscriptionParams);
};
export const GetPaidStatusService = async (data: any) => {
  const url = `${process.env.REACT_APP_URL}v1/paid`;
  return axios.patch(url, data);
};
