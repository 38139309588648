import React from "react";
import { translateLastValue } from "../../constants/conversion";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTemperaturePreference } from "../../redux/reducer/sensors";
import { useTimezone } from "../../hooks/common/useTimezone";
import moment from "moment-timezone";

export default function SensorCurrentValueSection({
  lastValue,
  sensor,
  time,
  units,
  offset = 0,
}) {
  const dispatch = useDispatch();
  const { temperaturePreference } = useSelector((store: any) => store.sensor);
  const { data: timezone } = useTimezone(sensor.device_id);
  return (
    <>
      <div>
        {sensor?.type === "01" && (
          <div className="button_group">
            <button
              className={
                temperaturePreference === "F" ? "btn_active" : "btn_inactive"
              }
              onClick={() => dispatch(UpdateTemperaturePreference("F"))}
            >
              F&deg;
            </button>
            <button
              className={
                temperaturePreference === "C" ? "btn_active" : "btn_inactive"
              }
              onClick={() => dispatch(UpdateTemperaturePreference("C"))}
            >
              C&deg;
            </button>
          </div>
        )}
      </div>
      <div>
        <div className="flex justify-between items-center">
          <p className="sensorHeading">Current Value</p>
          <div>
            <p className="sensorDetailValue mb-2">
              {translateLastValue(
                offset,
                lastValue,
                units,
                temperaturePreference
              )}
              <span>{getDecorator(units)}</span>
              <span>
                {lastValue != null && (
                  <>
                    {translateUnits(
                      sensor.unit ?? units,
                      temperaturePreference
                    )}
                  </>
                )}
              </span>
            </p>

            <p className="sensorCordinate">
              Updated At { moment(sensor?.last_updated).tz(timezone.timezone).format("DD/MM/yyyy HH:mm:ss")}
              {/* {moment(selectedSensor?.updatedAt).format("YYYY-MM-DD hh:mm:ss")} */}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
function translateUnits(units: string, temperaturePreference: string) {
  if (units === "C" && temperaturePreference === "F") {
    return "F";
  }
  return units;
}

function getDecorator(units: string) {
  switch (units) {
    case "C":
    case "F":
      return "°";
    default:
      return "";
  }
}
