import { Button } from "@mui/material";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import '../../assets/css/firmware.css'

const CopyToClipboardUrl = ({ imgUrl }) => {
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);

  return (
    <div className="clipBoardContainer">
      <input
        style={{
          width: "90%",
          height: 31,
          fontSize: 15,
          borderWidth: 2,
          borderColor: "blue",
          borderRadius: 5,
        }}
        value={imgUrl}
        onChange={({ target: { value } }) => {
          setValue(value);
          setCopied(false);
        }}
      />

      <CopyToClipboard text={imgUrl} onCopy={() => setCopied(true)}>
        <Button variant="outlined" size="small">
          Copy
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default CopyToClipboardUrl;
