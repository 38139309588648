import component from "../constants/urls";
import Label from "../components/common/formInput/Label";
import "../assets/css/auth.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/cabin-tech-logo.svg";

export default function ResetPassword() {
  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      navigate(component.login.url);
    }, 3000);
  };

  return (
    <div className="authContainer">
      <div className="logo mb-4">
        <img src={logo} alt="logo" />
      </div>
      <div className="loginContainer">
        <p className="login-heading">Reset Password</p>
        <form action="" className="w-full flex flex-col gap-5">
          <div className="">
            <Label label="Email" required={true} />
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Enter Email"
            />
          </div>
          <div className="">
            <Label label="Password" required={true} />
            <input
              type="text"
              name="password"
              id="password"
              placeholder="Enter Password"
            />
          </div>
          <div className="">
            <div className="flex justify-between items-center">
              <Label label="Re-Enter Password" required={true} />{" "}
            </div>
            <input
              type="text"
              name="password"
              id="password"
              placeholder="Re-Enter Password"
            />
          </div>
          <div>
            <input
              type="submit"
              value="Submit"
              className="mt-4 submitButton"
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
