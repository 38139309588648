import { useDispatch, useSelector } from "react-redux";
import {
  EMAIL_REGEX,
  NAME_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
} from "../../constants/constant";
import {
  ResetRegisterFormError,
  UpdateRegisterFormError,
} from "../../redux/reducer/auth";

const useRegisterFormValidation = () => {
  const dispatch = useDispatch();
  const { register } = useSelector((store: any) => store.auth);

  const RegisterFormValidation = () => {
    if (!register?.name?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(UpdateRegisterFormError({ key: "name", value: "Required !" }));
      return false;
    } else if (!NAME_REGEX.test(register?.name)) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "name",
          value: "Please enter a valid name.",
        })
      );
      return false;
    } else if (!register?.email?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(UpdateRegisterFormError({ key: "email", value: "Required !" }));
      return false;
    } else if (!EMAIL_REGEX.test(register?.email)) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "email",
          value: "Please enter a valid email.",
        })
      );
      return false;
    } else if (!register?.phone?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(UpdateRegisterFormError({ key: "phone", value: "Required !" }));
      return false;
    } else if (!register?.password?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({ key: "password", value: "Required !" })
      );
      return false;
    } else if (!PASSWORD_REGEX.test(register?.password)) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "password",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else if (!register?.deviceId?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({ key: "deviceId", value: "Required !" })
      );
      return false;
    } else if (register?.deviceId?.trim().length !== 20) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "deviceId",
          value: "Enter valid device id !",
        })
      );
      return false;
    } else {
      dispatch(ResetRegisterFormError());
      return true;
    }
  };

  const OnChangeRegistrationFormValueValidation = ({
    key,
    value,
  }: {
    key:
      | "name"
      | "email"
      | "password"
      | "confirmPassword"
      | "phone"
      | "deviceId";
    value: string;
  }) => {
    if (key === "name" && !value?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(UpdateRegisterFormError({ key: "name", value: "Required !" }));
      return false;
    } else if (key === "name" && !NAME_REGEX.test(value)) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "name",
          value: "Please enter a valid name.",
        })
      );
      return false;
    } else if (key === "email" && !value?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(UpdateRegisterFormError({ key: "email", value: "Required !" }));
      return false;
    } else if (key === "email" && !EMAIL_REGEX.test(value)) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "email",
          value: "Please enter a valid email.",
        })
      );
      return false;
    } else if (key === "password" && !value?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({ key: "password", value: "Required !" })
      );
      return false;
    } else if (key === "password" && !PASSWORD_REGEX.test(value)) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "password",
          value:
            "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character",
        })
      );
      return false;
    } else if (key === "phone" && !value?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(UpdateRegisterFormError({ key: "phone", value: "Required !" }));
      return false;
    } else if (key === "deviceId" && !value?.trim()) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({ key: "deviceId", value: "Required !" })
      );
      return false;
    } else if (key === "deviceId" && value?.length !== 20) {
      dispatch(ResetRegisterFormError());
      dispatch(
        UpdateRegisterFormError({
          key: "deviceId",
          value: "Enter valid device id !",
        })
      );
      return false;
    } else {
      dispatch(ResetRegisterFormError());
      return true;
    }
  };

  return {
    RegisterFormValidation,
    OnChangeRegistrationFormValueValidation,
  };
};

export default useRegisterFormValidation;
